import Axios from 'axios';
import Serve from '../app/serve';

async function getProcessosGerente(filtro, page, tipo_processos) {
  const usuario_id = localStorage.getItem('usuario_id');
  const token = localStorage.getItem('token');
  const app = Serve();
  let data;

  const dataEntregues = {    
    usuario_id: !filtro.gerente_entregue ? usuario_id : filtro.gerente_entregue.id,
    page: page,
    status_id: filtro.status,
    responsavel_id: filtro.responsavel,
    filtro: filtro.limpar_filtro_entregue,
    loja_id: filtro.loja_entregue,    
    logradouro: filtro.endereco_entregue ? filtro.endereco_entregue.endereco : "",
    tipo_processos,
  };

  const dataRascunho = {
    usuario_id: !filtro.gerente_rascunho ? usuario_id : filtro.gerente_rascunho.id,
    page: page,
    filtro: filtro.limpar_filtro_rascunho,
    loja_id: filtro.loja_rascunho,
    mes_cadastro: filtro.mes_cadastro_rascunho,            
    logradouro: filtro.endereco_rascunho ? filtro.endereco_rascunho.endereco : "",
    tipo_processos,
  };

  await Axios.post(app + 'retorno_processos_painel_gerentes', tipo_processos === 'entregues' ? dataEntregues : dataRascunho, {
    headers: {
        Authorization: `Bearer ${token}`,
    }
  }).then(res => {
    if (!res || !res.data) {
      data = false;
    }
    if (res?.data?.status && (res.data.status === 498 || res.data.status === 401)) {
      data = false
    } else {
      data = res.data;    
    }
  })

  return data;
};

export default getProcessosGerente;