import Axios from 'axios';
import Serve from '../app/serve';

async function getGerenteList() {
  const token = localStorage.getItem('token');
  const app = Serve();
  let data;

  await Axios.post(app + 'listar_gerentes', {
    'token': token
  }, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
  })
    .then(res => {
      if (res !== undefined) {
        if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
          localStorage.clear();
        } else {
          const listaGerentes = res.data.data;
          data = listaGerentes.map(gerente => { return { "id": gerente.id, "name": gerente.name } });
        }
      }
    })

  return data;
};

export default getGerenteList;