import React, { useState, useContext, useEffect } from 'react';
import { Dialog, Select, Label } from 'evergreen-ui';
import Axios from 'axios'
import Serve from '../../../../app/serve';
import NegociacaoContext from '../../../../context/NegociacaoContext';
import { useHistory } from 'react-router-dom';

export default function DialogResponsavel({ refreshTable }) {
    const history = useHistory();
    const app = Serve();
    const [token] = useState(localStorage.getItem('token'));

    const {
        userId,
        loading,
        setLoading,
        pageNumber,
        processSelect,
        dadosProcesso,
        processoId,
        listaResponsaveis,
        isShowChangeResponsavel, 
        setIsShowChangeResponsavel,
    } = useContext(NegociacaoContext);
    const [responsavel, setResponsavel] = useState("")

    useEffect(() => {
      setResponsavel(processSelect?.responsaveis?.data?.[0]?.id ?? "");
    },[processSelect]);

    const processoModal = dadosProcesso?.logradouro + ', ' + dadosProcesso?.numero + (dadosProcesso?.unidade || '') + ' - ' + dadosProcesso?.bairro;

    // console.log("responsavel ", responsavel);
    // console.log("processSelect ", processSelect);
    // console.log("processoId ", processoId);

    const handleConfirm = async (close) => {
        setLoading(true);

        await Axios.post(app + 'alterar_responsavel', {
            processo_id: processoId,
            id_responsavel: responsavel,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }).then(res => {
            if (res.data.status && res.data.status === (401 || 498)) {
                localStorage.clear();
                history.push('/');
            } else {
              close();
              refreshTable(pageNumber);
            }
        }).catch(function (error) {
            console.log(error);
        }).then(() => {
            // setLoading(false);
        })
    };

    const handleClose = () => {
      setIsShowChangeResponsavel(false);
    };

    return (
        <Dialog
            isShown={isShowChangeResponsavel}
            //title={}
            onCloseComplete={() => handleClose()}
            confirmLabel="Trocar"
            cancelLabel="Voltar"            
            onConfirm={close =>  handleConfirm(close)}
            isConfirmLoading={loading}
            isCancelLoading={loading}
        >
            <h2 className="title-dialog">Trocar responsável do pós-venda pelo processo</h2>
            <div className="dialog-content dialog-content-rua">{processoModal}</div>
            <div className="dialog-content dialog-content-info">Selecione o novo responsável:</div>

            <div className="form-dialog">
                <Label htmlFor="textarea-2" className="label-dialog" marginBottom={10} display="block">
                    Responsável:
                </Label>
                <Select
                    width={240}
                    value={responsavel}
                    onChange={(e) => setResponsavel(e.target.value)}
                    disabled={loading}
                    className={"select-status inputGreenSelect"}
                >
                    {listaResponsaveis.map(option =>
                        <option key={option.id} value={option.id}>{option.name}</option>
                    )}
                </Select>
            </div>
        </Dialog>
    )
}