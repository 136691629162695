import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button, CornerDialog, toaster } from 'evergreen-ui';

//Components
import Axios from 'axios';
import Serve from '../../../../app/serve';
import { useHistory } from 'react-router-dom';

import VendasContext from '../../../../context/VendasContext'

import GreenRocket from '../../../../images/rockets/green_rocket.svg';
import GrayRocket from '../../../../images/rockets/grey_rocket.svg';
import YellowRocket from '../../../../images/rockets/yellow_rocket.svg';
import RedRocket from '../../../../images/rockets/red_rocket.svg';
import WhatsAppIcon from '../../../../images/rockets/whatsapp.svg';

export default function NotificationDialog() {

    const app = Serve();
    const history = useHistory();
    const [token] = useState(localStorage.getItem('token'));
    const [gerente_id] = useState(localStorage.getItem('usuario_id'));
    const modeDev = process.env.REACT_APP_DEV === 'true';

    const foguetesTitleRef = useRef('');
    const foguetesSub1Ref = useRef('');
    const foguetesSub2Ref = useRef('');
    const mensagemFogueteRef = useRef('');

    const {
        dadosVenda, setDadosVenda, setItemAdicionado, setLoading,
        setItemEnviado,
        isShowCornerDialog, setIsShowCornerDialog,
        modelCornerDialog, setModelCornerDialog,
        isShowFormBoletimDeVenda, setIsShowFormBoletimDeVenda,
        posicaoBoletimDeVenda, setPosicaoBoletimDeVenda,
        setValidateFieldValorVenda,
        setValidateBoletimVendaDocumentos,
        setValidateBoletimVendaDocumentosVendedor,
        setValidateProcuradorVendedor,
        setValidateTelefoneProcuradorVendedor,
        setCheckedProcuradorVendedor,
        setValidateBoletimVendaDocumentosComprador,
        setValidateProcuradorComprador,
        setValidateTelefoneProcuradorComprador,
        setCheckedProcuradorComprador,
        setValidateBoletimVendaDocumentosComissao,
        setVendedorProcesso, setCompradorProcesso,
        setIsShowEntregarVenda,
        regressivaEntregarVenda, setRegressivaEntregarVenda,
        setIsShowFormMinuta,
        setPosicaoMinuta,
        setCountMenu,
        holidays,
        setSelectTabs,
        setIsShownSide,
        itemEnviado,
        setDadosReciboEnviado
    } = useContext(VendasContext);

    const today = new Date();
    const isInutilDay = (
        today.getDay() === 0 ||
        today.getDay() === 5 ||
        today.getDay() === 6 ||
        holidays.some((holiday) =>
            holiday.date.getDate() === today.getDate() &&
            holiday.date.getMonth() === today.getMonth()
        )
    );
    // const isInutilDay = true

    const clearStatesVenda = (e) => {
        //Boletim de Vendas
        setValidateFieldValorVenda(false);
        setValidateBoletimVendaDocumentos(false);
        setValidateBoletimVendaDocumentosVendedor(false);
        setValidateProcuradorVendedor(true);
        setValidateTelefoneProcuradorVendedor(true);
        setCheckedProcuradorVendedor(false);
        setValidateBoletimVendaDocumentosComprador(false)
        setValidateProcuradorComprador(true)
        setValidateTelefoneProcuradorComprador(true);
        setCheckedProcuradorComprador(false);
    }

    const closeNotificationDialog = (e) => {
        setIsShowCornerDialog(false);
        setModelCornerDialog();
        clearStatesVenda()
    }

    const handleCloseVendaEntregue = (e) => {
        setIsShowCornerDialog(false);
        setRegressivaEntregarVenda(5);
        //Se estiver no tempo precisa editar o estatus de Entragar Venda
    }

    const handleCanclerEnvio = (e, venda) => {
        if (regressivaEntregarVenda !== 0) {
            setLoading(false);
            Axios.post(app + 'deletar_status_processo', {
                "usuario_id": gerente_id,
                "processo_id": dadosVenda.processo_id
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    recarregarVenda(dadosVenda.processo_id);
                }

            }).catch(function (error) {
                console.log(error);
            })

            setModelCornerDialog(5);
        }

        else {
            setIsShowCornerDialog(false);
        }
        //setModelCornerDialog(5);
    }

    function recarregarVenda(idProcessoNovo) {
        Axios.post(app + 'retorno_processos_criados_gerente_minuta', {
            "usuario_id": gerente_id,
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {
            if (res.data.status && res.data.status === (401 || 498)) {
                localStorage.clear();
                history.push('/');
            } else {
                const processos = res.data.adicionados;
                const processosEnviados = res.data.enviados;
                setItemAdicionado(processos);
                setItemEnviado(processosEnviados);
                processos.forEach(element => {
                    if (Number(idProcessoNovo) === element.processo_id) {
                        setDadosVenda(element);
                        setLoading(true);
                    } else {
                        
                    }
                });
            }

        })
    }

    const formBoletimDeVendas = (e, dadosVenda) => {
        clearStatesVenda();

        //Imóvel
        dadosVenda.informacao.valor_venda !== null ? setValidateFieldValorVenda(true) : setValidateFieldValorVenda(false);
        dadosVenda.documentos.data.length !== 0 ? setValidateBoletimVendaDocumentos(true) : setValidateBoletimVendaDocumentos(false);

        //Vendedor
        //validateProcuradorVendedor, setValidateProcuradorVendedor,
        //validateTelefoneProcuradorVendedor, setValidateTelefoneProcuradorVendedor,
        //checkedProcuradorVendedor, setCheckedProcuradorVendedor,
        dadosVenda.vendedores.procurador !== null ? setValidateProcuradorVendedor(true) : setValidateProcuradorVendedor(false);
        dadosVenda.vendedores.procurador !== null ? setValidateTelefoneProcuradorVendedor(true) : setValidateTelefoneProcuradorVendedor(false);
        dadosVenda.vendedores.data[0].documentos.length === 0 ? setValidateBoletimVendaDocumentosVendedor(false) : setValidateBoletimVendaDocumentosVendedor(true);
        //validateProcuradorVendedor === true ? setCheckedProcuradorVendedor(true) : setCheckedProcuradorVendedor(false);

        //Comprador
        dadosVenda.compradores.procurador !== null ? setValidateProcuradorComprador(true) : setValidateProcuradorComprador(false);
        dadosVenda.compradores.procurador !== null ? setValidateTelefoneProcuradorComprador(true) : setValidateTelefoneProcuradorComprador(false);
        dadosVenda.compradores.data[0].documentos.length === 0 ? setValidateBoletimVendaDocumentosComprador(false) : setValidateBoletimVendaDocumentosComprador(true);
        //validateProcuradorComprador === true ? setCheckedProcuradorComprador(true) : setCheckedProcuradorComprador(false);

        //Comissão
        /*checkedPrimeiraParte, setCheckedPrimeiraParte,
        checkedSegundaParte, setCheckedSegundaParte,
        comissaoTotal, setComissaoTotal,
        validateRadiusTotal, setValidateRadiusTotal,
        validateRadiusLiquida, setValidateRadiusLiquida,
        validateComissaoTotal, setValidateComissaoTotal,
        validateComissaoLiquida, setValidateComissaoLiquida,*/
        dadosVenda.documentos.data !== [] ? setValidateBoletimVendaDocumentosComissao(true) : setValidateBoletimVendaDocumentosComissao(false);

        setDadosVenda(dadosVenda);
        setVendedorProcesso(dadosVenda.vendedores);
        setCompradorProcesso(dadosVenda.compradores);
        setIsShowFormBoletimDeVenda(true);
        setCountMenu(1);
        setPosicaoBoletimDeVenda(1);
        setIsShowCornerDialog(false);
        setModelCornerDialog();
    }

    const enviarBackoffice = (e, dadosVenda) => {
        setDadosVenda(dadosVenda);
        setIsShowEntregarVenda(true);
        //setIsShowFormBoletimDeVenda(true);
        //setIsShowCornerDialog(false);
        //setModelCornerDialog();
    }

    const formGerarVenda = (e) => {
        setIsShowCornerDialog(false);
        setIsShowFormMinuta(true);
        setPosicaoMinuta(3);
        setCountMenu(4);
    }

    const detalhesVendaEnviada = () => {
        setIsShowCornerDialog(false);
        setSelectTabs(1);
        setDadosVenda(itemEnviado[0]);
        setDadosReciboEnviado(itemEnviado[0]);
        setIsShownSide(true);
    };

    function mensagemQtdFoguetes(e){
        if(dadosVenda.quantidade_foguete?.qts_foguete === 5){
            foguetesTitleRef.current = "Parabéns! Você entregou uma venda 5 foguetes!"
            //foguetesSub1Ref.current = "Sua venda foi entregue para a Equipe de Pós-Venda. Agora é só acompanhar pelo celular ou pela plataforma, sem se preocupar."
            //foguetesSub2Ref.current = "Que tal compartilhar sua venda 5 estrelas no Whatsapp? A Equipe DNA Imóveis quer ver sua pontuação!"
        }
        
        else if(dadosVenda.quantidade_foguete?.qts_foguete === 4){
            foguetesTitleRef.current = "Por pouco...Você entregou uma venda 4 foguetes!"
            //foguetesSub1Ref.current = "Sua venda foi entregue para a Equipe de Pós-Venda. Agora é só acompanhar pelo celular ou pela plataforma, sem se preocupar."
            //foguetesSub2Ref.current = "Que tal compartilhar sua venda 4 estrelas no Whatsapp? A Equipe DNA Imóveis quer ver sua pontuação!"
        }

        else if(dadosVenda.quantidade_foguete?.qts_foguete === 3){
            foguetesTitleRef.current = "Poderia ser melhor! Você entregou uma venda 3 foguetes!"
            //foguetesSub1Ref.current = "Sua venda foi entregue para a Equipe de Pós-Venda. Agora é só acompanhar pelo celular ou pela plataforma, sem se preocupar."
            //foguetesSub2Ref.current = "Que tal compartilhar sua venda 3 estrelas no Whatsapp? A Equipe DNA Imóveis quer ver sua pontuação!"
        }

        else if(dadosVenda.quantidade_foguete?.qts_foguete === 2){
            foguetesTitleRef.current = "Tente melhorar na próxima. Você entregou uma venda 2 foguetes!"
            //foguetesSub1Ref.current = "Sua venda foi entregue para a Equipe de Pós-Venda. Agora é só acompanhar pelo celular ou pela plataforma, sem se preocupar."
            //foguetesSub2Ref.current = "Que tal compartilhar sua venda 2 estrelas no Whatsapp? A Equipe DNA Imóveis quer ver sua pontuação!"
        }
        else{
            foguetesTitleRef.current = "";
            foguetesSub1Ref.current = "";
            foguetesSub2Ref.current = "";
        }
    }
    mensagemQtdFoguetes()

    const handleCompartilharFoguete = (e) => {        
        let foguetes = dadosVenda.quantidade_foguete.qts_foguete;
        let qtd_foguete = '';
        switch (foguetes) {
            case 2:
                qtd_foguete = '🚀🚀'
                break;

            case 3:
                qtd_foguete = '🚀🚀🚀'
                break;

            case 4:
                qtd_foguete = '🚀🚀🚀🚀'
                break;

            case 5:
                qtd_foguete = '🚀🚀🚀🚀🚀'
                break;
        
            default:
                break;
        }

        const loja = dadosVenda.loja.nome;
        let gritoGuerra = ''
        switch (loja) {
            case 'Tijuca':
                gritoGuerra = 'Terra Fertil'
                break;

            case 'Posto 6':
                gritoGuerra = 'La Casa de Vendas'
                break;

            case 'Copacabana':
                gritoGuerra = 'Fazemos Juntos'
                break;

            case 'Botafogo':
                gritoGuerra = 'Rumo ao Topo'
                break;

            case 'Ipanema':
                gritoGuerra = 'Lá Fábrica de Vendas'
                break;

            case 'Leblon':
                gritoGuerra = ''
                break;
        
            default:
                gritoGuerra = ''
                break;
        }

        const enderecoImovel = dadosVenda.logradouro + ", " + dadosVenda.numero + "" + (dadosVenda.unidade !== null ? (', ' + dadosVenda.unidade) : '') + (dadosVenda.complemento !== null ? (', ' + dadosVenda.complemento) : '') + ", " + dadosVenda.bairro + ", " + dadosVenda.cidade + " - " + dadosVenda.uf;
        const corretores = dadosVenda.comissao.corretores_vendedores_comissao.map(corretores => (' ' + corretores.name));
        const opcionistas = dadosVenda.comissao.corretores_opicionistas_comissao.map(opcionistas => (' ' + opcionistas.name));

        //Segue a venda [🚀🚀🚀🚀🚀 FOGUETES] do imóvel localizado no endereço [endereço], no valor de [valor da venda]. A venda foi realizada por [nome do corretor], sendo opção de [opcionista] e fechada por quem vos fala. [inserir grito de guerra da loja].🥂
        let mensagem = "Segue a venda " + qtd_foguete + " do imóvel localizado no endereço " + enderecoImovel + ", no valor de " + dadosVenda.informacao.valor_venda + ". A venda foi realizada por" + (corretores.length === 1 ? ' ' + corretores + ',' : corretores + ',')  + " sendo opção de" + (opcionistas.lenght === 1 ? ' ' + opcionistas + ',' : opcionistas) + " e fechada por quem vos fala. " + gritoGuerra + ".🥂";

        toaster.success('Mensagem copiada para à área de transferência.');
        //return navigator.clipboard.writeText(mensagem);

        // Cria um elemento input temporário
        const input = document.createElement('input');
        input.setAttribute('value', mensagem);
        document.body.appendChild(input);
        
        // Seleciona o conteúdo do input
        input.select();
        
        // Copia o conteúdo selecionado para a área de transferência
        document.execCommand('copy');
        
        // Remove o input temporário
        document.body.removeChild(input);
    }

    return (
        <>
            {
                //Mensagem Primeira Etapa de Venda
                // Se for diferente de Revisão sem comprador, exibe o dialog do fluxo de cadastro completo
                (modelCornerDialog === 1 && dadosVenda.status_processo !== 24) &&

                <CornerDialog
                    title="Download de Rascunho concluído!"
                    isShown={isShowCornerDialog}
                    onCloseComplete={(e) => closeNotificationDialog(e)}
                    confirmLabel="Ok"
                    hasCancel={false}
                    width={512}

                >
                    <div style={{ fontWeight: '400', fontSize: '14px' }}>
                        Você realizou o download do Rascunho do Recibo de Sinal. Após coletar as assinaturas, realize o Upload e envie para a Equipe de Pós-Venda
                    </div>
                </CornerDialog >
            }

            {
                //Mensagem Segunda Etapa de Venda
                modelCornerDialog === 2 &&
                <CornerDialog
                    title="Upload do Recibo de Sinal concluído!"
                    isShown={isShowCornerDialog}
                    onCloseComplete={() => setIsShowCornerDialog(false)}
                    confirmLabel="Preencher Boletim de Vendas"
                    cancelLabel="Fazer depois"
                    onConfirm={(e) => formBoletimDeVendas(e, dadosVenda)}
                    width={512}

                >
                    Agora que temos o Recibo de Sinal assinado, podemos seguir
                    para a última etapa com o Boletim de Vendas.
                </CornerDialog >
            }

            {
                modelCornerDialog === 3 &&
                <CornerDialog
                    title="Você fez o download do rascunho :)"
                    isShown={isShowCornerDialog}
                    onCloseComplete={(e) => closeNotificationDialog(e)}
                    onConfirm={(e) => formGerarVenda(e)}
                    confirmLabel="Preencher agora"
                    cancelLabel="Preencher depois"
                    hasCancel={true}
                    width={512}

                >
                    <div style={{ fontWeight: '400', fontSize: '14px' }}>Com o rascunho do Recibo de Sinal em mãos, você pode
                        agilizar a coleta de assinaturas das partes envolvidas.</div>

                    <div style={{ fontWeight: '600', marginTop: '15px', fontSize: '14px' }}>
                        Não esqueça que a venda só será concluída após o preenchimento
                        dos dados do comprador na plataforma.
                    </div>
                </CornerDialog >
            }

            {
                //Mensagem Quarta Etapa de Venda
                modelCornerDialog === 4 
                ?
                    dadosVenda.status_processo === 22
                    ?
                    //Confirmação venda reenviada
                    <CornerDialog
                        title="Venda reenviada para o pós-venda!"
                        isShown={isShowCornerDialog}
                        onCloseComplete={(e) => handleCloseVendaEntregue(e)}
                        onConfirm={() => detalhesVendaEnviada()}
                        confirmLabel="Ver detalhes"
                        onCancel={(e) => handleCanclerEnvio(e, dadosVenda)}
                        cancelLabel={regressivaEntregarVenda !== 0 ? "Cancelar envio (" + regressivaEntregarVenda + ")" : "Fechar"}
                        width={512}

                    >
                        Você enviou a venda com as correções solicitadas pela Equipe de pós-venda. Agora é só aguardar!
                    </CornerDialog >

                :
                    dadosVenda.pre_venda !== null
                    ?
                    //Confirmação de entrega foguetes
                    <CornerDialog
                        title={
                            dadosVenda.quantidade_foguete?.qts_foguete === 2 ?
                            <>
                                <img src={RedRocket} alt="" />
                                <img src={RedRocket} alt="" />
                                <img src={GrayRocket} alt="" />
                                <img src={GrayRocket} alt="" />
                                <img src={GrayRocket} alt="" />
                            </>

                            : dadosVenda.quantidade_foguete?.qts_foguete === 3 ?
                            <>
                                <img src={YellowRocket} alt="" />
                                <img src={YellowRocket} alt="" />
                                <img src={YellowRocket} alt="" />
                                <img src={GrayRocket} alt="" />
                                <img src={GrayRocket} alt="" />
                            </>
        
                            : dadosVenda.quantidade_foguete?.qts_foguete === 4 ?
                            <>
                                <img src={GreenRocket} alt="" />
                                <img src={GreenRocket} alt="" />
                                <img src={GreenRocket} alt="" />
                                <img src={GreenRocket} alt="" />
                                <img src={GrayRocket} alt="" />
                            </>
                    
                            : dadosVenda.quantidade_foguete?.qts_foguete === 5 ?
                            <>
                                <img src={GreenRocket} alt="" />
                                <img src={GreenRocket} alt="" />
                                <img src={GreenRocket} alt="" />
                                <img src={GreenRocket} alt="" />
                                <img src={GreenRocket} alt="" />
                            </>

                            :
                            ''
                        }
                        isShown={isShowCornerDialog}
                        onCloseComplete={(e) => handleCloseVendaEntregue(e)}
                        //onConfirm={() => detalhesVendaEnviada()}
                        //confirmLabel="Ver detalhes"
                        //onCancel={(e) => handleCanclerEnvio(e, dadosVenda)}
                        //cancelLabel={regressivaEntregarVenda !== 0 ? "Cancelar envio (" + regressivaEntregarVenda + ")" : "Fechar"}
                        hasFooter={false}
                        width={512}

                    >
                        <div className='fw400'>
                            <h4 style={{fontWeight: 600, fontSize: '18px', color: '#101840', lineHeight: '25px', marginBottom: '15px', width: dadosVenda.quantidade_foguete?.qts_foguete >= 2 || dadosVenda.quantidade_foguete?.qts_foguete < 4 ? '260px' : '250px'}}>
                                {foguetesTitleRef.current}
                            </h4>

                            {isInutilDay
                                ?
                                <div style={{ fontWeight: '400', fontSize: '14px', marginBottom: '25px' }}>
                                    Atenção: sua venda foi entregue, mas não esqueça que a Equipe de Pós-Venda começará as tratativas somente no próximo dia útil.
                                </div>

                                :
                                <div style={{ fontWeight: '400', fontSize: '14px', marginBottom: '25px' }}>
                                    Sua venda foi entregue para a Equipe de Pós-Venda. Agora é só acompanhar pelo celular ou pela plataforma, sem se preocupar.
                                </div>
                            }

                            <div style={{ fontWeight: '400', fontSize: '14px' }}>
                                Que tal compartilhar sua venda {dadosVenda.quantidade_foguete?.qts_foguete} foguetes no Whatsapp? A Equipe DNA Imóveis quer ver sua pontuação!
                            </div>

                            <div className="footer-dialog">
                                <div>
                                    {
                                        regressivaEntregarVenda !== 0
                                        ?
                                        <Button onClick={(e) => handleCanclerEnvio(e, dadosVenda)} appearance="minimal">{regressivaEntregarVenda !== 0 ? "Cancelar envio (" + regressivaEntregarVenda + ")" : "Fechar"}</Button>
                                        :
                                        '' 
                                    }
                                </div>

                                <div>
                                    <Button 
                                        disabled={regressivaEntregarVenda > 0 ? true : false} 
                                        onClick={(e) => handleCompartilharFoguete(e)}
                                    >
                                        <img src={WhatsAppIcon} alt="" style={{marginRight: '10px'}}/> 
                                        Compartlhar
                                    </Button>
                                    <Button onClick={(e) => detalhesVendaEnviada(e)} appearance="primary">Ver detalhes</Button>
                                </div>
                            </div>
                        </div>
                    </CornerDialog>

                    :
                    //Confirmação sem pré-cadastro
                    <CornerDialog
                        title="Parabéns, sua venda foi entregue! :)"
                        isShown={isShowCornerDialog}
                        onCloseComplete={(e) => handleCloseVendaEntregue(e)}
                        onConfirm={() => detalhesVendaEnviada()}
                        confirmLabel="Ver detalhes"
                        onCancel={(e) => handleCanclerEnvio(e, dadosVenda)}
                        cancelLabel={regressivaEntregarVenda !== 0 ? "Cancelar envio (" + regressivaEntregarVenda + ")" : "Fechar"}
                        width={512}

                    >
                        {
                        
                            isInutilDay
                                ?
                                <div className='fw400'>
                                    Atenção: sua venda foi entregue, mas não esqueça que a Equipe de Pós-Venda começará as tratativas somente no próximo dia útil.
                                </div>

                                :
                                <div className='fw400'>
                                    O recibo de sinal foi enviado para a Equipe de Pós-Venda. Agora é só acompanhar pelo celular, sem se preocupar.
                                </div>

                        }

                    </CornerDialog >
                : 
                ''
            }

            {
                //Mensagem cancelamento do Envio para o Backoffice
                modelCornerDialog === 5 &&
                <CornerDialog
                    title="Envio cancelado!"
                    isShown={isShowCornerDialog}
                    onCloseComplete={(e) => (e)}
                    confirmLabel="Voltar"
                    width={512}
                    hasCancel={false}
                >
                    Tudo certo, cancelamos o envio da sua venda. Não esqueça de revisar e enviar depois!
                </CornerDialog >
            }

            {
                //Mensagem Terceira Etapa de Venda
                modelCornerDialog === 6 &&
                <CornerDialog
                    title={dadosVenda.status_processo === 22 ? "Você corrigiu a venda devolvida." : "Parabéns pela venda cadastrada!"}
                    isShown={isShowCornerDialog}
                    onCloseComplete={() => setIsShowCornerDialog(false)}
                    confirmLabel={dadosVenda.status_processo === 22 ? "Reenviar venda" : "Enviar agora para Equipe de Pós-Venda"}
                    cancelLabel="Enviar depois"
                    onConfirm={(e) => enviarBackoffice(e, dadosVenda)}
                    width={512}

                >
                    {
                        dadosVenda.status_processo === 22
                            ? "Não perca tempo e reenvie a venda com as correções solicitadas para a Equipe de Pós-venda."
                            : "Obrigada por cadastrar sua venda na plataforma! Se estiver tudo ok, envie agora o processo para a Equipe de Pós-Venda"
                    }
                </CornerDialog >
            }
        </>
    )
}