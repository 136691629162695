import { Dialog, Button, ArrowLeftIcon } from 'evergreen-ui';
import { useHistory, Link } from 'react-router-dom';
import Image from '../../../../images/linkInvalid.svg';
import { useEffect } from 'react';

export default function EmailEnviado() {  
  useEffect(() => {
    const classTeste = 'redefinirSenha css-xeqldi ub-pst_fixed ub-top_0px ub-lft_0px ub-rgt_0px ub-btm_0px ub-z-idx_20 ub-dspl_flex ub-algn-itms_flex-start ub-just-cnt_center ub-box-szg_border-box'
    const element = document.getElementsByClassName('css-xeqldi')
    if (element[0]) document.getElementsByClassName('css-xeqldi')[0].className = classTeste;
  });
  const history = useHistory();

  return (
    <Dialog
      isShown={true}
      width={420}
      height={450}
      title={
        <div className='textCenter'>
          <img src={Image} alt="link invalido" />
          <h3 className='fw600 f24 mt24 lw32'>Cadê o link que estava aqui?</h3>
        </div>
      }      
      hasFooter={false}
      hasClose={false}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
    >
      <div className='dialog'>
        <div className='center'>
          <p className='fw400 lw20'>
          Parece que você usou um link inválido para 
          redefinir sua senha. Vamos tentar de novo?
          </p>
        </div>
        <Button 
          onClick={() => history.push('/login-help')}
          width="100%" 
          marginTop={25} 
          marginBottom={25}
          appearance="primary"
        >
          Obter novo link de redefinir senha
        </Button>
        <div className='rodape'>          
        <Link to="/" className='DecorationNone colorNeutral'><ArrowLeftIcon size={10} /> Voltar para Entrar</Link>
        </div>
      </div>
    </Dialog>
  )
};