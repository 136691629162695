import React, {useState, useEffect, useContext} from 'react';
import VendasContext from '../../../../../../../../../context/VendasContext';
import {Pane, Radio, Badge, Button, ArrowRightIcon} from 'evergreen-ui';

export default function Processos() {

    const {
        modalEscritura, setModalEscritura,
        showListaEscritura, setShowListaEscritura,
        listaEscritura, setListaEscritura,
        selectProcessoEscritura, setSelectProcessoEscritura,
        loading
    } = useContext(VendasContext)

    const [radioProcesso, setRadioProcesso] = useState(true);
    const [radioSelect, setRadioSelect] = useState();

    const borderSelect = {
        borderColor: '#36F',
    }

    const borderDefault = {
        borderColor: '#D8DAE5',
    }

    const handleProcesso = (e, index) => {
        setSelectProcessoEscritura(listaEscritura[index]);
        setRadioSelect(index);
        setRadioProcesso(false)
    }

    const handleEnviar = (e) => {
        e.preventDefault();
        setShowListaEscritura(false);
    }

    return (
        <Pane aria-label="Radio Group Label 16" role="group" style={{paddingTop: '25px'}}>
            <p className="dialogSubTitle" style={{marginBottom: '45px'}}>            
            Queremos seu feedback sobre como foi o processo de Escritura pra entender se houveram mudanças importantes na venda.
            </p>

            <p className="dialogSubTitle" style={{fontWeight: '600', marginBottom: '20px'}}>Selecione a Escritura para feedback: <Badge display="inline-flex" margin={8} color="blue">{listaEscritura.length}</Badge> </p>

            <div className="row" style={{marginTop: '0'}}>

                {
                    listaEscritura.map((escritura, index) => <div className="coll" style={radioSelect === index ? borderSelect : borderDefault}>
                        <Radio 
                            className="radio" 
                            size={16} 
                            name="group2" 
                            value={index}
                            onChange={(e) => handleProcesso(e, index)} 
                            label={escritura.data_escritura + ' - ' + escritura.logradouro} 
                        />
                    </div>)
                }
            </div>

            <div className='dialog-footer' style={{justifyContent: 'end'}}>
                <div className='p20-end'>
                    <Button 
                        appearance="primary" 
                        intent={'default'}
                        onClick={(e) => handleEnviar(e)}
                        disabled={!!radioProcesso ? true : false}
                    >
                        Dar feedback de Escritura <ArrowRightIcon size={12} color={'#fff'} marginLeft={5}/>
                    </Button>
                </div>
            </div>
        </Pane>
    )
}
