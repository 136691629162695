import React, { useState, useEffect, useContext } from "react";
import {
  Pane,
  Button,
  Menu,
  LogOutIcon,
  PersonIcon,
  NotificationsIcon,
  Popover,
  Position,
  toaster,
  Image,
  CrossIcon,
  TrashIcon,
  IconButton,
  StatusIndicator,
  Pulsar,
  Badge,
  OfficeIcon,
  CalendarIcon,
  SwapHorizontalIcon,
  Pill,
  DollarIcon
} from "evergreen-ui";
import { useHistory } from "react-router-dom";

import Axios from "axios";
import Serve from "../../app/serve";
import logoDNA from "../../images/logoDNA_88x45.png";
import getProcessById from "../../apis/getProcessById";
import updateStatusVisualizarApoio from "../../apis/updateStatusVisualizarApoio";


function ReturnItem(props) {
  const { sideFunction, handleClickMensagem, handleDelete, num } = props;
  const [perfil_login] = useState(localStorage.getItem('perfil_login'));

  const returnColor = () => {
    if (num.mensagem.includes("hoje")) return {ico: '#D14343', pill: '#FDF4F4'}
    else if (num.mensagem.includes("amanha")) return {ico: '#FFB020', pill: '#FFFAF1'}
    else return {ico: '#52BD95', pill: '#F5FBF8'}
  };

  const returnIcon = () => {
    if (num.mensagem.includes("nova venda")) return <OfficeIcon color={returnColor().ico} />
    else if (num.mensagem.includes("vai terminar")) return <CalendarIcon color={returnColor().ico} />
    else if (num.mensagem.includes("compartilhou")) return <SwapHorizontalIcon color={returnColor().ico} />
    else if (perfil_login === 'Financeiro') return <DollarIcon color={returnColor().ico} />
    else return ""
  };

  return (
    <Menu.Item
      onClick={() => [
        sideFunction(num.processo_id, num.comissao_id, num.progress_status_progresses_id),
        handleClickMensagem(num.id),
      ]}
      className="pb10 pt10 childSpanMr0 childSpanMl0"
      height="auto"
    >
      <div className="alignRight gap20">
        <Pill height={32} padding={8} color={returnColor().pill}>
          <Pane width={16}>
            {returnIcon()}
            </Pane>          
        </Pill>
        <div>
          {num.mensagem}
          <div className="fontBlue"> {num.data_humana} </div>
        </div>
        <div className="flex">
          {num.visualizacao_sininho === 0 && (
            <StatusIndicator color="#3366FF" height={32} />
          )}
          {num.visualizacao_sininho === 1 && (
            <StatusIndicator height={32} />
          )}

          <IconButton
            icon={<TrashIcon color={"gray600"} />}
            appearance="minimal"
            onClick={() => handleDelete(num)}
          />
        </div>
      </div>
    </Menu.Item>
  )
}

export default function Navbar({ Context }) {
  const app = Serve();
  const [token] = useState(localStorage.getItem("token"));
  const history = useHistory();
  const [mensagem, setMensagem] = useState([]);
  const {
    setLoading,
    setProcessoId,
    processoId,

    //SIDESHEET
    setIsShownSide,
    setProcessSelect,
    processSelect,

    setSelectedIndex,

    tablePaged, setTablePaged,

    // APOIO
    listaProcessos, setListaProcessos,
    setIdProcess, setSelectProcess,

    // FINANCEIRO
    user,
  } = useContext(Context) ?? {};

  const sideFunction = async (id, comissaoId, progressStatus) => {
    setLoading(true);
    if (!processoId || id !== processSelect.id) {

      const dataProcess = await getProcessById(id);

      if (perfilLogin === 'Backoffice') {
        setProcessoId(id);
        setProcessSelect(dataProcess);

        atualizarStatusVisualizacaoGerente(progressStatus);

        const posicao = tablePaged.findIndex(id => id.progress_status_progresses_id = progressStatus);
        tablePaged[posicao].status_visualizacao_atual = 1;
        setTablePaged([...tablePaged]);
      }

      else if (perfilLogin === 'Apoio') {
        setIdProcess(id);
        setSelectProcess(dataProcess)

        await updateStatusVisualizarApoio(comissaoId);

        const posicao = listaProcessos.findIndex(id => id.comissao_id = comissaoId);
        if (listaProcessos[posicao].status_visualizacao_apoio === 0) {
          await updateStatusVisualizarApoio(comissaoId);
          listaProcessos[posicao].status_visualizacao_apoio = 1;
          setListaProcessos([...listaProcessos]);
        }
      }

      else if (perfilLogin === 'Financeiro') {
        setProcessoId(id);
        setProcessSelect(dataProcess);
      }

      setSelectedIndex(0);
    }
    setIsShownSide(true);
    setLoading(false);
  };

  const atualizarStatusVisualizacaoGerente = async (progressStatus) => {
    Axios.post(app + 'atualizar_status_visualizacao_gerente', {
      'id': progressStatus
    }, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(res => {
        if (res.data.status && res.data.status === (401 || 498)) {
          localStorage.clear();
          history.push('/');
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  };

  const limparLocalStorage = () => {
    let arrayStorage = [
      localStorage.removeItem("token"),
      localStorage.removeItem("usuario_id"),
      localStorage.removeItem("nome_usuario"),
      localStorage.removeItem("usuario_email"),
      localStorage.removeItem("perfil_login"),
      localStorage.removeItem("loja_id"),
      localStorage.removeItem("usuario_id"),
      localStorage.removeItem("filtro_responsavel"),
      localStorage.removeItem("filtro_status"),
      localStorage.removeItem("filtro_gerente_rascunho"),
      localStorage.removeItem("filtro_gerente_entregue"),
      localStorage.removeItem("filtro_loja_rascunho"),
      localStorage.removeItem("filtro_loja_entregue"),
      localStorage.removeItem("filtro_mes_cadastro_rascunho"),
      //localStorage.removeItem('filtro_mes_cadastro_entregue'),
      localStorage.removeItem("filtro_limpar_filtro_rascunho"),
      localStorage.removeItem("filtro_limpar_filtro_entregue"),
      localStorage.removeItem("page_number"),
      localStorage.removeItem("ordenacao"),
    ];
    return arrayStorage;
  };

  //Botão logout
  async function handlelogout() {
    await Axios.post(
      app + "logout",
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    ).then((res) => {
      if (res.status) {
        limparLocalStorage();
        history.push("/");
      } else {
        toaster.danger("Erro na hora de  deslogar, tente mais tarde!!!", {
          duration: 5,
        });
      }
    });
  }

  const retornaSininho = async () => {
    console.log("USER :", user);

    await Axios.post(app + "alertas_posvenda", {
      perfil_id: user ? user.perfil_id : "",
      usuario_sininho_id: localStorage.getItem("usuario_id")
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    ).then((res) => {
      setMensagem(res.data);
    });
  };

  useEffect(() => {
    retornaSininho();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickMensagem = async (id) => {
    console.log("id mensagem: " + id);

    await Axios.post(
      app + "nova_notificacao", {
      notificacao_id: id,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    ).then((res) => {
      console.log(res);
      retornaSininho();
    });
  };

  async function removeNotificacao(notificacao_id) {
    const token = localStorage.getItem("token");
    const app = Serve();
    let data;

    await Axios.post(
      app + "ocultar_notificacao", {
      notificacao_id,
    }, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      if (res.data.status && res.data.status === (401 || 498)) {
        localStorage.clear();
      } else {
        data = res.data;
      }
    }).catch(function (error) {
      console.log(error);
    });

    return data;
  }

  const handleDelete = async (num) => {
    if (num.id) {
      const result = await removeNotificacao(num.id);
      retornaSininho();
    }
  };

  const notificacaoLength = (type) => {
    return mensagem[type]?.data?.length > 0
      ? mensagem[type]?.data?.length
      : false;
  };

  const userName = localStorage.getItem("nome_usuario");
  const perfilLogin = localStorage.getItem("perfil_login");

  return (
    <Pane className="navbar" elevation={2}>
      <Image
        src={logoDNA}
        alt="Boas-vindas!"
        cursor="pointer"
        onClick={() => window.location.reload()}
      />
      <div>
        {perfilLogin === "Backoffice" || perfilLogin === "Apoio" || perfilLogin === "Financeiro" ? (
          <Popover
            position={Position.BOTTOM_RIGHT}
            id="popover"
            content={({ close }) => (
              <Pane width={392}>
                <Menu>
                  <Menu.Group>
                    <div className="flex-container-header">
                      <p className="nameMenu">Notificações</p>
                      <div className="exit-button" onClick={close}>
                        <CrossIcon color={"gray600"} />
                      </div>
                    </div>
                  </Menu.Group>
                  <Menu.Divider />
                  <div className={mensagem.contagem_novas_notificacoes > 0 ? "scroll" : ""}>
                    <Menu.Group>
                      <div className="noti-title">
                        Recentes
                        {notificacaoLength("notificacoes_de_hoje") && (
                          <Badge display="inline-flex" margin={8} color="blue">
                            {notificacaoLength("notificacoes_de_hoje")}
                          </Badge >
                        )}
                      </div>
                      {!notificacaoLength("notificacoes_de_hoje") ? (
                        <Pane
                          marginTop={8}
                          marginBottom={8}
                          paddingLeft={28}
                          fontWeight={400}
                          height="auto"
                        >
                          Sem notificações
                        </Pane>
                      ) : (
                        notificacaoLength("notificacoes_de_hoje") &&
                        mensagem.notificacoes_de_hoje.data.map((num) => (
                          <ReturnItem key={num.id} sideFunction={sideFunction} handleClickMensagem={handleClickMensagem} handleDelete={handleDelete} num={num} />
                        ))
                      )}

                    </Menu.Group>
                    <Menu.Group>
                      <div className="noti-title">
                        Anteriores
                        {notificacaoLength("notificacoes_anteriores") && (
                          <Badge display="inline-flex" margin={8} color="blue">
                            {notificacaoLength("notificacoes_anteriores")}
                          </Badge >
                        )}
                      </div>
                      {!notificacaoLength("notificacoes_anteriores") ? (
                        <Pane
                          marginTop={8}
                          marginBottom={20}
                          paddingLeft={28}
                          fontWeight={400}
                          height="auto"
                        >
                          Sem notificações
                          <Menu.Item width={0} padding={0} height={0}></Menu.Item>
                        </Pane>
                      ) : (
                        notificacaoLength("notificacoes_anteriores") &&
                        mensagem.notificacoes_anteriores.data.map((num) => (
                          <ReturnItem key={num.id} sideFunction={sideFunction} handleClickMensagem={handleClickMensagem} handleDelete={handleDelete} num={num} />
                        ))
                      )}
                    </Menu.Group>
                  </div>
                </Menu>
              </Pane>
            )}
          >
            <Button className="btn-perfil btn-mensagem" style={{ paddingRigth: (mensagem.contagem_novas_notificacoes > 0 ? '0px' : 'inherit'), borderColor: '#D8DAE5', marginRight: '10px' }}>
              <NotificationsIcon color="#8F95B2" />
              {
                mensagem.contagem_novas_notificacoes > 0 &&
                <div className="pulsar">
                  <Pulsar size={5} />
                </div>
              }

            </Button>
          </Popover>
        )
          :
          ''
        }
        <Popover
          position={Position.BOTTOM_LEFT}
          content={
            <Menu>
              <Menu.Group>
                <div class="flex-container-names">
                  <p className="nameMenu"> {userName} </p>
                  <p className="namePosition"> {perfilLogin} </p>
                </div>
              </Menu.Group>

              <Menu.Divider />
              <Menu.Group>
                <Menu.Item
                  className="btn-logout"
                  icon={LogOutIcon}
                  intent="danger"
                  onClick={(e) => handlelogout()}
                >
                  Sair
                </Menu.Item>
              </Menu.Group>
            </Menu>
          }
        >
          <Button className="btn-perfil" style={{ borderColor: '#D8DAE5' }}>
            {" "}
            <PersonIcon color="#8F95B2" />{" "}
          </Button>
        </Popover>
      </div>
    </Pane>
  );
}
