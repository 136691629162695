import React, { useContext } from 'react';
import { Pane, Button, PlusIcon } from 'evergreen-ui';

//Context
import VendasContext from '../../../../context/VendasContext';

//Components
import PreCadastro from './components/Minuta/PreCadastro';
import Imovel from './components/Minuta/Imovel';
import FormPessoa from './components/Minuta/FormPessoa';
// import Comprador from './components/Minuta/Comprador_pf_pj';
// import Vendedor from './components/Minuta/Vendedor_pf_pj';
import Revisao from './components/Minuta/Revisao';
import PularComprador from './components/Minuta/PularComprador';
import '../../../../index.css';

export default function FormVenda() {

    const {
        exibirGerarVenda,
        setCountMenu,
        posicaoMinuta, setPosicaoMinuta,
        setIsShowFormMinuta,
        setDadosVenda,
        setCheckedMinutaAVista,
        setCheckedMinutaFgts,
        setCheckedMinutaFinanciamento,
        setCheckedMinutaParcelamento,
        setCheckedMinutaConsorcio,
        setMinutaVendaLaudemio,
        setMinutaVendaEscritura,

        //PRÉ cadastro
        setIsShowPreCadastro,

        //Vendedor
        setCheckedMinutaVendedorUniaoEstavel,
        setMinutaVendedorEstadoCivil,
        setMinutaVendedorRegimeCasamento,
        setMinutaVendedorUniaoEstavel,

        //Comprador
        setCheckedMinutaCompradorUniaoEstavel,
        setMinutaCompradorEstadoCivil,
        setMinutaCompradorRegimeCasamento,
        setMinutaCompradorUniaoEstavel,
    } = useContext(VendasContext);

    const dialogTipoVenda = async (dadosVenda) => {
        // const venda = await getProcessById(id);
        setDadosVenda(dadosVenda);
        setPosicaoMinuta(1);
        setIsShowFormMinuta(true);
        setCountMenu(1);

        // Limpa todos os dados e estados para um novo Gerar Venda
        setCheckedMinutaAVista(false);
        setCheckedMinutaFgts(false);
        setCheckedMinutaFinanciamento(false);
        setCheckedMinutaParcelamento(false);
        setCheckedMinutaConsorcio(false);
        setMinutaVendaLaudemio([]);
        setMinutaVendaEscritura('');        

        //Vendedor
        setCheckedMinutaVendedorUniaoEstavel('');
        setMinutaVendedorEstadoCivil('0');
        setMinutaVendedorRegimeCasamento('0');
        setMinutaVendedorUniaoEstavel('')

        //Comprador
        setCheckedMinutaCompradorUniaoEstavel('');
        setMinutaCompradorEstadoCivil('0');
        setMinutaCompradorRegimeCasamento('0');
        setMinutaCompradorUniaoEstavel('')     
    };

    return (
        <Pane>
            {
                localStorage.getItem('perfil_login') !== 'Diretor Comercial' && exibirGerarVenda === 0
                ?
                <Button marginY={8} marginRight={12} appearance="primary" onClick={(e) => setIsShowPreCadastro(true)}>
                    <PlusIcon
                        color="white"
                        style={{ width: 11, height: 11, marginRight: 10 }} /> Gerar Venda
                </Button>

                :''
            }

            {/*{posicaoMinuta === 1 && <FormaPagamento/>}*/}
            <PreCadastro openMinuta={dialogTipoVenda} />
            {posicaoMinuta === 1 && <Imovel />}
            {/* {posicaoMinuta === 2 && <Vendedor />}
            {posicaoMinuta === 3 && <Comprador />} */}
            {posicaoMinuta === 2 && <FormPessoa type={"vendedores"} />}
            {posicaoMinuta === 3 && <FormPessoa type={"compradores"} />}
            {posicaoMinuta === 4 && <Revisao />}
            {posicaoMinuta === 5 && <PularComprador />}
        </Pane>
    )
}