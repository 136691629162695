import React, { useContext, useEffect } from 'react';
import { Dialog, Button, ArrowLeftIcon } from 'evergreen-ui';
import Image from '../../../../images/emailEnviado.svg';
import LogonContext from '../../../../context/LogonContext';

export default function EmailEnviado() {
  useEffect(() => {
    const classTeste = 'redefinirSenha css-xeqldi ub-pst_fixed ub-top_0px ub-lft_0px ub-rgt_0px ub-btm_0px ub-z-idx_20 ub-dspl_flex ub-algn-itms_flex-start ub-just-cnt_center ub-box-szg_border-box'
    const element = document.getElementsByClassName('css-xeqldi')
    if (element[0]) document.getElementsByClassName('css-xeqldi')[0].className = classTeste;
  },[]);
  const {
    isShowEmailEnviado,
    setIsShowEmailEnviado,
    setIsShowEnviarEmail,
  } = useContext(LogonContext);

  return (
    <Dialog
      isShown={isShowEmailEnviado}
      width={410}
      height={390}
      title={
        <div className='textCenter'>
          <img src={Image} alt="email enviado" />
          <h3 className='mt33 fw600 f24 mb5'>Veja sua caixa de entrada!</h3>
        </div>
      }
      onCloseComplete={() => setIsShowEmailEnviado(false)}
      hasFooter={false}
      hasClose={false}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
    >
      <div className='dialog'>
        <div className='center'>
          <p className='fw400'>
            Você deve ter recebido o e-mail para redefinir
            sua senha. <b>Não esqueça de clicar no link!</b>
          </p>
        </div>
        <Button
          onClick={() => [setIsShowEnviarEmail(true), setIsShowEmailEnviado(false)]}
          width="100%"
          marginTop={25}
          marginBottom={25}
        >
          Reenviar e-mail de redefinir senha
        </Button>
        <div className='rodape'>
          <a href='./' className='DecorationNone colorNeutral'><ArrowLeftIcon size={10} /> Voltar para Entrar</a>
        </div>
      </div>
    </Dialog>
  )
};