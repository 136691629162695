import Axios from 'axios';
import Serve from '../app/serve';

async function updateSalvarChecklist(data) {
  const token = localStorage.getItem('token');
  const app = Serve();
  let result;
  
  await Axios.post(app + 'salvar_checklist', data, {
    headers: { Authorization: `Bearer ${token}` }
  })
    .then(res => {
        result = res;
        console.log(res);
    })
    .catch(function (error) {
      console.log(error);
    })

  return result;
};

export default updateSalvarChecklist;