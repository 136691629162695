import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Lottie from 'react-lottie';
import animationData from '../../../images/Animations/loading.json';
import changePassAnimation from '../../../images/Animations/changePassword.json';
import LinkInvalido from '../RedefinirSenha/Dialogs/LinkInvalido'
import Serve from '../../../app/serve_login';
import Axios from 'axios';
import {
  Button,
  Pane,
  ShieldIcon,
  TextInputField,
  EyeOpenIcon,
  Spinner,
  EyeOffIcon,
  ArrowRightIcon,
  WarningSignIcon,
  SmallTickIcon,
} from 'evergreen-ui';

const ResetLogin = () => {
  const [loading, setLoading] = useState(false);
  const [loadingAnimation, setLoadingAnimation] = useState(false);
  const history = useHistory();
  const url = useParams();
  const app = Serve();
  const token = url.token;
  const [tokenValido, setTokenValido] = useState(false);
  const [email, setEmail] = useState(false);

  useEffect(() => {
    setLoading(true)
    Axios.post(app + 'verificar_link', {
      token,
    }).then(res => {
      console.log(res);
      if (res.data.mensagem === "Token Invalido") {
        setTokenValido(false)
      } else {
        setTokenValido(true)
        setEmail(res.data.mensagem[0].email)
      }
    }).catch((err) => {
      console.log(err);
    }).then(() => {
      setLoading(false);
    })
  }, [app, token])

  console.log(email);
  const navegador = navigator.userAgent;
  const navegadorEdg = navegador.indexOf("Edg") > -1;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const changePassOptions = {
    loop: false,
    autoplay: true,
    animationData: changePassAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const [showPass, setShowPass] = useState({
    newPass: false,
    confirmation: false,
  });

  const [security, setSecurity] = useState({
    number: 'inicial',
    uppercase: 'inicial',
    amount: 'inicial',
  });

  const [countSecurity, setCountSecurity] = useState(0);

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  console.log(errors);

  const inputGreen = {
    borderColor: '#429777',
    background: '#F5FBF8'
  };

  const inputError = {
    borderColor: '#FF0000',
    background: '#fff'
  };

  async function handleEnviarEmail(e) {
    setLoading(true)
    await Axios.post(app + 'redefinirsenha', {
      token,
      email,
      "password": e.password,
    }).then(res => {
      if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
        console.log(res);
      } else {
        console.log(res);
        setLoadingAnimation(true)
        setTimeout(() => {
          handleLogin(e)
        }, 4000)
      }
    }).catch(err => {
      console.log(err);
    }).then(() => {
      setLoading(false);
    })
  };

  async function handleLogin(e) {
    const password = e.password;
    try {
      const response = await Axios.post(app + 'login', { email, password });
      console.log(response);
      const nome_perfil_login = response.data.perfil_logins.data[0].nome;
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('usuario_id', response.data.user.id);
      localStorage.setItem('nome_usuario', response.data.user.name);
      localStorage.setItem('usuario_email', response.data.user.email);
      localStorage.setItem('perfil_login', nome_perfil_login);
      setLoading(false);
      if (nome_perfil_login === "Backoffice") {
        localStorage.removeItem('filtro_status');
        localStorage.removeItem('filtro_gerente');
        localStorage.removeItem('page_number');
        localStorage.removeItem('filtro_endereco');
        localStorage.setItem('filtro_responsavel', response.data.user.id);
        history.push('/negociacoes');
      } else if (nome_perfil_login === "Gerente") {
        history.push('/vendas');
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
      history.push('/');
    }
  };

  const handlePass = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === 'password') {
      security.amount = value.length >= 8;
      security.number = value.match(/[0-9]/)?.length > 0;
      security.uppercase = value.match(/(?=.*[A-Z])/)?.length > 0;
      setCountSecurity(security.amount + security.number + security.uppercase);
      setSecurity({ ...security });
    }
  };

  const styleSecurity = (type) => {
    if (type === 'container') {
      if (countSecurity === 3) return '#F5FBF8';
      else if (countSecurity === 2) return '#FFFAF1';
      else return '#FDF4F4'
    } else {
      if (countSecurity === 3) return '#52BD95';
      else if (countSecurity === 2) return '#FFB020';
      else return '#D14343'
    }
  };

  function color (element) {
    if(element === 'inicial') return {color: '#8F95B2'}
    if(!element) return {color: 'red'}
    if(element) return {color: 'green'}
  };

  function icon (element) {
    if(element === 'inicial') return <SmallTickIcon color='white' />
    if(!element) return <WarningSignIcon size={13} color='red' />
    if(element) return <SmallTickIcon color='green' />
  };

  return (
    <Pane>
      {!tokenValido && loading
        ? <div className="logon-container reset"><Lottie marginTop={10} options={defaultOptions} height={80} width={200} /></div>
        : tokenValido
          ?
          <div className="logon-container reset">
            {loadingAnimation ? <Lottie options={changePassOptions} height={300} width={300} /> :
              <section class="form">
                <div className='title'>
                  <h3>Vamos redefinir sua senha?</h3>
                </div>
                <form className='dialog' onSubmit={handleSubmit((e) => handleEnviarEmail(e))}>
                  <div className='resetText'>
                    <p style={{ marginBottom: '15px' }}>Uma senha forte deve conter:</p>

                    <div className='securityFields' style={color(security.number)}>
                      { icon(security.number)}
                      &emsp;Ao menos 1 número
                    </div>

                    <div className='securityFields' style={color(security.uppercase)}>
                      {icon(security.uppercase)}
                      &emsp;Ao menos 1 letra maíuscula
                    </div>

                    <div className='securityFields' style={color(security.amount)}>
                      {icon(security.amount)}
                      &emsp;Mais de 8 caracteres
                    </div>
                  </div>
                  <div className='containerPassword'>
                    <div className='shieldIcon' style={{ backgroundColor: styleSecurity('container') }}>
                      <ShieldIcon color={styleSecurity()} />
                    </div>


                    <div className='password flex'>
                      <TextInputField
                        label="Nova senha*"
                        disabled={loading}
                        marginTop={25}
                        marginBottom={0}
                        placeholder="L86minhasenha"
                        type={showPass.newPass ? 'text' : 'password'}
                        style={
                          errors.password ? inputError :
                            watch('password') ? inputGreen : {}}
                        {...register("password", {
                          required: 'Nova senha obrigatória',
                          pattern: {
                            value: /(?=.*[0-9])(?=.*[A-Z])(?=.{8,})/,
                            message: 'Sua nova senha está fraca. Siga as recomendações.'
                          },
                          onChange: (e) => handlePass(e),
                        })}
                      />

                      {!navegadorEdg ?
                        showPass.newPass
                          ? <EyeOffIcon className='ml-28 mt59' onClick={() => setShowPass({ ...showPass, newPass: false })} />
                          : <EyeOpenIcon className='ml-28 mt59' onClick={() => setShowPass({ ...showPass, newPass: true })} />
                        : ""
                      }
                    </div>
                  </div>
                  <div className='securityFields AlignRight mb-25'>
                    <div className='securityFields'>
                      <div className='line' style={{ backgroundColor: styleSecurity() }}></div>
                      <div className='line' style={{ backgroundColor: countSecurity >= 2 ? styleSecurity() : '#D8DAE5' }}></div>
                      <div className='line' style={{ backgroundColor: countSecurity === 3 ? styleSecurity() : '#D8DAE5' }}></div>
                    </div>
                    <span style={{ color: styleSecurity() }}>{countSecurity === 3 ? 'FORTE' : countSecurity === 2 ? 'MÉDIO' : 'FRACO'}</span>
                  </div>
                  <div className='containerPassword'>
                    <div className='password flex'>                      
                      <TextInputField
                        label="Digite novamente*"
                        disabled={loading}
                        placeholder="L86minhasenha"
                        type={showPass.confirmation ? 'text' : 'password'}
                        style={
                          errors.passwordConfirmation ? inputError :
                            watch('passwordConfirmation') ? inputGreen : {}
                        }
                        {...register("passwordConfirmation", {
                          required: "Confirmação de nova senha obrigatória.",
                          validate: {
                            valoresIguais: value => value === watch('password') || 'As senhas precisam ser iguais.',
                          }
                        })}
                        validationMessage={errors.passwordConfirmation?.message}
                      />
                      {!navegadorEdg ?
                        showPass.confirmation
                          ? <EyeOffIcon className='ml-28 mt34' onClick={() => setShowPass({ ...showPass, confirmation: false })} />
                          : <EyeOpenIcon className='ml-28 mt34' onClick={() => setShowPass({ ...showPass, confirmation: true })} />
                        : ""
                      }
                    </div>
                  </div>
                  <Button width="100%" marginBottom={25} appearance="primary" disabled={loading}>
                    {loading && <Spinner size={24} style={{ marginRight: 20 }} />}
                    Redefinir senha e Entrar <ArrowRightIcon color='white' marginLeft={20} size={10} />
                  </Button>
                  <div className='center'>
                    {loading && <Lottie options={defaultOptions} height={40} width={100} />}
                    <spam style={{ color: '#D14343', fontWeight: '400' }}>{errors.password?.message || errors.passwordConfirmation?.message}</spam>
                  </div>
                </form>
              </section>
            }
          </div>
          : <LinkInvalido />
      }
    </Pane>
  );
}

export default ResetLogin;