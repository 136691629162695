import React, { useState, useContext } from 'react';
import { CornerDialog, InfoSignIcon, toaster } from 'evergreen-ui';
import Context from '../../../../context/NucleoContext';
import Axios from 'axios'
import Serve from '../../../../app/serve';
import { useHistory } from 'react-router-dom';

export default function SharePosvenda({ refreshTable }) {
    const history = useHistory();
    const app = Serve();
    const [token] = useState(localStorage.getItem('token'));

    const { 
        setLoading,
        isShowSharePosVenda, 
        setIsShowSharePosVenda, 
        setIsShownSide, contador,
        idStatusSolicitacaoCancelar, 
        setIdStatusSolicitacaoCancelar
    } = useContext(Context);

    const handleCancel = () => {
        console.log(idStatusSolicitacaoCancelar);
        Axios.post(app + 'cancelar_status_solicitacao', {
            "idStatusSolicitacaoCancelar": idStatusSolicitacaoCancelar,
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {
            console.log(res);
            if (res.data.status && res.data.status === (401 || 498)) {
                localStorage.clear();
                history.push('/');
            } else {
                setIdStatusSolicitacaoCancelar('');
                toaster.notify('O envio de atualização de status foi cancelado.');
                refreshTable();
                setIsShowSharePosVenda(false);
            }
        }).catch(function (error) {
            console.log(error);
            setLoading(false);
        })
    };

    const handleDetalhes = () => {        
        setIsShowSharePosVenda(false)
        setIsShownSide(true);
    };

    return (
        <CornerDialog
            title="Você atualizou o status do processo :)"
            isShown={isShowSharePosVenda}
            onCloseComplete={() => setIsShowSharePosVenda(false)}
            confirmLabel={<div className='btnAlign'><InfoSignIcon /><span>Ver Detalhes</span></div>}
            cancelLabel={"Cancelar envio de status (" + contador + ")"}
            onConfirm={() => handleDetalhes()}
            onCancel={() => handleCancel()}
            hasCancel={contador > 0}
        >
            A equipe de pós-venda será notificada e poderá dar continuidade ao processo. 
        </CornerDialog>
    )
}