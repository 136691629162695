import React, {useContext} from 'react';
import {
    Pane,
    Dialog,
    Button,
    ChevronRightIcon,
} from 'evergreen-ui';

//Context
import VendasContext from '../../../../../../context/VendasContext';

export default function PularComprador() {
    //Estados Laudemio e Formas de Pagamento estão sendo executados em VendasAdicionadas em formVenda;

    const {
        dadosVenda, setDadosVenda,
        setPosicaoMinuta,
        isShowFormMinuta, setIsShowFormMinuta,
    } = useContext(VendasContext);
    console.log(dadosVenda);

    const styleFooterButton = {
        display: 'flex',
        justifyContent: 'end',
        marginBottom: '25px'
    }

    const handleClose = (e) => {
        setIsShowFormMinuta(false);
    }

    const handlePreencherAgora = (e) => {
        setPosicaoMinuta(3)
    }

    const handleAvancar = (e) => {
        setPosicaoMinuta(4)
    }

    return (
        <Pane>
            <Dialog
                width={808}
                isShown={isShowFormMinuta}
                title=''
                onCloseComplete={(e) => handleClose(e)}
                hasFooter={false}
                preventBodyScrolling={true}
                shouldCloseOnOverlayClick={false}
                containerProps={{
                    className: 'form-dialog-props'
                }}
            >

                <div className="form-dialog">
                    <h4 style={{ marginTop: '15px' }}>Tem certeza que deseja preencher os dados<br/> do comprador depois?</h4>
                    <p className="descritption" style={{ marginTop: '20px', marginBottom: '30px', fontSize: "16px" }}>Clicando em Avançar e preencher depois, você pode realizar o download<br/> do recibo de sinal, mas será necessário preencher esses dados no futuro<br/> para concluir a venda.</p>
                    <p className="descritption" style={{ marginTop: '15px', fontWeight: '700', fontSize: "16px" }}>Se algum campo em compradores já estiver preenchido, ao clicar em<br/> Avançar e optar por pular esta etapa, você precisará preencher<br/> novamente mais tarde. </p>

                    <div style={styleFooterButton}>
                        <Button
                            appearance="primary"
                            onClick={(e) => handlePreencherAgora(e)}
                            style={{marginRight: '15px'}}
                        >
                            Preencher agora
                        </Button>
                        <Button
                            onClick={(e) => handleAvancar(e)}
                        >
                            Avançar e preencher depois 
                            <ChevronRightIcon
                                size={12}
                                style={{marginLeft: '10px'}}
                            />
                        </Button>
                    </div>
                </div>
            </Dialog>
        </Pane>
    )
}
