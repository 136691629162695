import React, { useState, useEffect, useContext } from 'react';
import {
    Pane,
    Table,
    Button,
    Badge,
    InfoSignIcon,
    Avatar,
    Spinner
} from 'evergreen-ui';
import Axios from 'axios';
import Serve from '../../../../app/serve';
import { useHistory } from 'react-router-dom';

//Context
import PosNegociacaoContext from '../../../../context/PosNegociacaoContext';

//Component
import SideSheetRecibo from '../SideSheetView';

import '../../../../index.css';

export default function RecibosEnviados() {

    const {
        itemEnviado, setItemEnviado,
        setDadosReciboEnviado,
        setIsShowSendBackOffice,
        setValidateFieldRgi,
        setValidateFieldCep,
        setValidateFieldDataAssinatura,
        setValidateFieldFormaPagamento,
        setValidateInputFile,
        setCheckedFgts,
        setCheckedAVista,
        setCheckedFinanciamento,
        setEstadoRecibo,
        setDadosRecibo,
        setIsShownFormRecibo,
        dadosRecibo,
        isShownSide, setIsShownSide,
        loading, setLoading,
        selectedIndexEnviados, setSelectedIndexEnviados,
    } = useContext(PosNegociacaoContext)

    const history = useHistory();
    const [token] = useState(localStorage.getItem('token'));
    const usuario_id = localStorage.getItem('usuario_id');
    const nome_usuario = localStorage.getItem('nome_usuario');
    //const usuario_email = localStorage.getItem('usuario_email');

    const app = Serve();

    useEffect(() => {

        const exibirImoveis = async () => {
            await Axios.post(app + 'retorno_processos_criados_gerente', {
                "usuario_id": usuario_id,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
                .then(res => {
                    //console.log("asdfad");
                    if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                        localStorage.clear();
                        history.push('/');
                    } else {
                        console.log(res);
                        //console.log("erro sei la por que");
                        const processos = res.data.enviados;
                        //console.log(processos);
                        setItemEnviado(processos);

                        setLoading(true);
                    }

                })
        }

        exibirImoveis();
    }, [app, token])

    function openModal() {
        console.log('openModal2');
        setIsShowSendBackOffice(true);
    }

    const formRecibo = async (e, recibo) => {

        console.log('Abrindo recibo');

        if (recibo !== undefined) {
            console.log(recibo);

            const arrayRecibo = {
                id: recibo.id,
                rgi: recibo.informacao.rgi,
                cep: recibo.cep,
                bairro: recibo.bairro,
                logradouro: recibo.logradouro,
                numero: recibo.numero,
                unidade: recibo.unidade,
                complemento: recibo.complemento,
                dataAssinatura: recibo.informacao.data_assinatura,
                formaPagamento: recibo.informacao.forma_pagamento_nome,
                recibo: recibo.informacao.recibo,
                observacoes: recibo.informacao.observacao,
            }
            console.log(arrayRecibo);

            if (recibo !== []) {

                console.log(recibo.informacao);

                if (recibo.informacao.rgi !== null) {
                    setValidateFieldRgi(true);
                }
                else {
                    setValidateFieldRgi(false);
                }

                //////////////////////////////////////////////////////

                if (recibo.informacao.cep !== null) {
                    setValidateFieldCep(true);
                }
                else {
                    setValidateFieldCep(false);
                }

                //////////////////////////////////////////////////////

                if (recibo.informacao.dataAssinatura !== null) {
                    setValidateFieldDataAssinatura(true);

                }
                else {
                    setValidateFieldDataAssinatura(false);
                }

                //////////////////////////////////////////////////////

                if (recibo.informacao.recibo !== null) {
                    setValidateInputFile(true);
                }
                else {
                    setValidateInputFile(false);
                }

                //////////////////////////////////////////////////////

                if (recibo.informacao.forma_pagamento === '1') {
                    setCheckedAVista(true);
                    setValidateFieldFormaPagamento(true);
                }

                if (recibo.informacao.forma_pagamento === '2') {
                    setCheckedFinanciamento(true);
                    setValidateFieldFormaPagamento(true);
                }

                if (recibo.informacao.forma_pagamento === '3') {
                    setCheckedFgts(true);
                    setValidateFieldFormaPagamento(true);
                }

                if (recibo.informacao.forma_pagamento === '2,3') {
                    setCheckedFinanciamento(true);
                    setCheckedFgts(true);
                    setValidateFieldFormaPagamento(true);
                }

                setEstadoRecibo('editar');

            }

            setDadosRecibo(arrayRecibo);
        }
        else {
            setDadosRecibo([]);
            setValidateFieldFormaPagamento(false);
            setEstadoRecibo('cadastrar');
        }

        setIsShownFormRecibo(true);
    }

    const showDocument = async (e, id_imovel) => {
        console.log(id_imovel);
        Axios.post(app + 'exibir_arquivo', {
            "id": id_imovel
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',
        })
            .then(res => {
                console.log(res);
                let type_arquivo = res.data.type;
                if (type_arquivo !== 'text/html') {
                    const file = new Blob(
                        [res.data],
                        { type: type_arquivo });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                } else {
                    console.log("não teve retorno satisfatorio");
                }
            })
        //window.open('/documento/recibo-de-sinal', '_blank');
    }

    const handleSideSheet = (e, recibo) => {
        const dados = {
            id: recibo.id,
            imovelId: recibo.informacao.imovel_id,
            processoId: recibo.processo_id,
            rgi: recibo.informacao.rgi,
            bairro: recibo.bairro,
            cep: recibo.cep,
            cidade: recibo.cidade,
            complemento: recibo.complemento,
            logradouro: recibo.logradouro,
            numero: recibo.numero,
            unidade: recibo.unidade,
            formaPagamento: recibo.informacao.forma_pagamento_nome,
            formaPagamentoId: recibo.informacao.forma_pagamento,
            dataAssinatura: recibo.informacao.data_assinatura,
            observacao: recibo.informacao.observacao,
            recibo: recibo.informacao.recibo,
            documentos: recibo.documentos.data,
            historico: recibo.historico_acoes,
        }

        setDadosReciboEnviado(dados);
        setIsShownSide(true);
    }

    const acessoTab = async (tab) => {
        console.log(typeof(tab));
        setSelectedIndexEnviados(tab);
    }

    return (
        <Pane>
            {itemEnviado.length >= 1 &&
                <React.Fragment>
                    <h2 className="subtitle subtitle-table">
                        Processos encaminhados {
                            itemEnviado.length > 1 &&
                            <Badge color="blue" marginLeft={10}>
                                {itemEnviado.length}
                            </Badge>
                        }
                    </h2>

                    <Table.Body className="table">
                        <Table.Head>
                            {/* <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0} className="header-title">
                            </Table.TextCell> */}
                            <Table.TextCell flexBasis={300} flexShrink={0} flexGrow={0} className="header-title">
                                Endereço
                            </Table.TextCell>
                            <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="header-title">
                                Data de assinatura
                            </Table.TextCell>
                            <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="header-title">
                                Forma de pagamento
                            </Table.TextCell>
                            <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="header-title">
                                Arquivo do recibo
                            </Table.TextCell>
                            <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="header-title">
                                Status
                            </Table.TextCell>
                            <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="header-title">
                                Backoffice
                            </Table.TextCell>
                            <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="header-title">
                            </Table.TextCell>
                        </Table.Head>

                        {
                            //Enquanto não carregar os dados exibe um Spinner
                            loading === false && <Spinner size={24} style={{ margin: 20 }} />
                        }

                        {
                            loading === true &&

                            <Table.Body>
                                <div>
                                    {itemEnviado.map(recibo => <Table.Row key={recibo.id}>
                                        {/*Avatar*/}
                                        {/* {<Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0} className="content">
                                            <Avatar hashValue={nome_usuario} name={nome_usuario} size={40} marginRight={16} />
                                        </Table.TextCell>} */}

                                        <Table.TextCell flexBasis={300} flexShrink={0} flexGrow={0} className="content-endereco content-click" onClick={(e) => handleSideSheet(e, recibo, acessoTab(0))}>
                                            {recibo.logradouro + ', ' + recibo.numero + (recibo.unidade !== null ? " / " + recibo.unidade : "")}
                                        </Table.TextCell>

                                        <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="content-endereco">
                                            {recibo.informacao !== null ? recibo.informacao.data_assinatura : ''}
                                        </Table.TextCell>

                                        <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="content-endereco">
                                            {
                                                recibo.informacao !== null
                                                    ?
                                                    recibo.informacao.forma_pagamento === '2,3' ? 'Financiamento, FGTS' : recibo.informacao.forma_pagamento_nome

                                                    : ''}
                                        </Table.TextCell>

                                        <Table.TextCell
                                            flexBasis={180} flexShrink={0} flexGrow={0} className="content-endereco" onClick={(e) => showDocument(e, recibo.informacao.imovel_id)}>
                                            <div className="doc-name">{recibo.informacao.recibo}</div>
                                        </Table.TextCell>

                                        <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} >
                                            <div className="content-tag"><span className="circleIcon"></span>{recibo.status_processo_nome}</div>
                                        </Table.TextCell>

                                        <Table.TextCell
                                            flexBasis={180} flexShrink={0} flexGrow={0} className="content-endereco"
                                        >
                                            {recibo.nome_responsavel}
                                        </Table.TextCell>

                                        <Table.TextCell
                                            flexBasis={300} flexShrink={0} flexGrow={0}>
                                            <Button
                                                marginY={8}
                                                marginRight={12}
                                                appearance="primary"
                                                onClick={(e) => handleSideSheet(e, recibo, acessoTab(0))}
                                            >
                                                <InfoSignIcon
                                                    color="white"
                                                    style={{ width: 11, height: 11, marginRight: 10 }} /
                                                >
                                                Ver detalhes
                                            </Button>
                                        </Table.TextCell>
                                    </Table.Row>
                                    )}
                                </div>
                            </Table.Body>
                        }
                    </Table.Body>

                    <SideSheetRecibo />
                </React.Fragment>
            }
        </Pane>
    )
}