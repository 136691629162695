import Axios from 'axios';
import Serve from '../app/serve';

async function getHolidays(ano) { 
  let data;
  const app = Serve();
  await Axios.get(app +`returnar_feriados?ano=${ano}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
  })
  .then(res => {
    if (res?.data.status && (res.data.status === 498 || res.data.status === 401)) {
      localStorage.clear();
    } else if(res) {
      data = (res.data);
      data.forEach((holiday, index) => {          
          const date = new Date();
          const mouth = Number(holiday.date[0] + holiday.date[1]);
          const day = Number(holiday.date[3] + holiday.date[4]);
          date.setDate(day);
          date.setMonth(mouth - 1);
          data[index].date = date;
        })
      }
    })
  return data;
}

export default getHolidays;