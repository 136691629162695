import React, { useEffect, useContext, useState } from 'react';
import { Dialog, Spinner, TextInputField, Button, ArrowLeftIcon, toaster } from 'evergreen-ui';
import Lottie from 'react-lottie';
import animationData from '../../../../images/Animations/loading.json';
import { useForm } from 'react-hook-form';
import LogonContext from '../../../../context/LogonContext';

export default function EnviarEmail({handleEnviarEmail}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const {
    isShowEnviarEmail,    
    setIsShowEnviarEmail,
    loading,
    errorEmail,
    setErrorEmail,
  } = useContext(LogonContext);

  const { register, handleSubmit, watch, setError, formState: { errors } } = useForm({
    defaultValues: {
      email: localStorage.getItem('email') || '',
    }
  }); 
  console.log(errors);
  console.log(errorEmail);

  useEffect(() => {
    errorEmail && setError("email")
  }, [errorEmail, setError]);

  const inputGreen = {
    borderColor: '#429777',
    background: '#F5FBF8'
  };

  const inputError = {
    borderColor: '#FF0000',
    background: '#fff'
  };

  const handleEmail = (e) => {
    setErrorEmail(false);
    localStorage.setItem('email', e.target.value)
  };

  return (
    <Dialog
      className="textCenter"
      isShown={isShowEnviarEmail}
      width={410}
      height={361}
      title={
        <div className='textCenter'>
          <span className='fw600 f24'>Vamos redefinir sua senha?</span>
        </div>
      }
      onCloseComplete={() => setIsShowEnviarEmail(false)}
      hasFooter={false}
      hasClose={false}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEscapePress={false}
    >
      <form className='dialog' onSubmit={handleSubmit((e) => handleEnviarEmail(e))}>
        <div className='center'>
          <p className='EnviarEmail'>
            Digite abaixo seu endereço de e-mail usado <br />
            para entrar na plataforma. Você vai receber um 
            link para redefinir sua senha.
          </p>
        </div>
        <TextInputField
          label={<span className='fw400'>Seu E-mail*</span>}
          disabled={loading}          
          className="fw400"
          description=""
          marginTop={25}
          placeholder="Seu e-mail"
          style={errors.email ? inputError : watch('email') ? inputGreen : {}}
          {...register("email", {
            required: 'E-mail obrigatório',
            validate: () => errorEmail === false || 'E-mail não cadastrado',
            onChange: (e) => handleEmail(e),
          })}
          validationMessage={errors.email && errors.email.message}
        />
        <Button width="100%" marginBottom={25} appearance="primary" disabled={loading}
         
      >
          {loading && <Spinner size={24} style={{ marginRight: 20 }} />}
          Enviar e-mail com link para redefinir senha
        </Button>
        <div className='rodape'>
          {loading
            ?
            <div className='center'>
              <Lottie
                options={defaultOptions}
                height={40}
                width={100}
              />
            </div>
            :
            <a href='./' className='DecorationNone colorNeutral' onClick={() => setIsShowEnviarEmail(false)}><ArrowLeftIcon size={10} /> Voltar para Entrar</a>
          }
        </div>
      </form>
    </Dialog>
  )
};