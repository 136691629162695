import React, { useState, useContext, useEffect } from 'react';
import {
    Pane,
    Table,
    Button,
    Badge,
    Spinner,
    DotIcon,
    Popover,
    Menu,
    WarningSignIcon,
    TickCircleIcon,
    ShareIcon,
    SmallTickIcon,
    LockIcon,
    Dialog,
    EditIcon,
    ErrorIcon,
    Tablist,
    Tab,
    Tooltip,
    TrashIcon,
    ResetIcon,
    Avatar,
    InfoSignIcon,
    Combobox,
    Select,
    Pagination,
    FilterIcon,
} from 'evergreen-ui';
import Axios from 'axios';
import Serve from '../../../../app/serve';
import { useHistory } from 'react-router-dom';
import DialogRemover from './DialogRemover';
import DialogRestore from './DialogRestore';
import SideSheetRecibo from '../SideSheetView';

import Lottie from 'react-lottie';
//import animationData from '../../../../images/Animations/loadingDocs.json';

//Context
import VendasContext from '../../../../context/VendasContext';

//Components
import FormVenda from '../Form/FormVenda';
//import CornerDialog from '../CornerDialog';

//import '../../../../index.css';
import '../../../../index.css';
//import Vendas from '../..';

export default function VendasAdicionadas() {

    const {
        setCountMenu,
        setPosicaoMinuta,
        setPosicaoBoletimDeVenda,
        itemAdicionado, setItemAdicionado,
        setItemEnviado,
        itemsArquivados, setItemsArquivados,
        loading, setLoading,
        dadosVenda, setDadosVenda,
        setIsShowFormMinuta,
        setCheckedMinutaAVista,
        setCheckedMinutaFgts,
        setCheckedMinutaFinanciamento,
        setCheckedMinutaParcelamento,
        setCheckedMinutaConsorcio,
        setCheckedMinutaPix,
        setMinutaVendaLaudemio,
        setValidateMinutaVendaFormaPagamento,
        setValidateMinutaVendaCep,
        setValidateMinutaVendaNumero,
        setValidateMinutaVendaEscritura,
        setMinutaVendaEscritura,
        setValidateMinutaVendaMatricula,
        setValidateMinutaVendaInscricaoMunicipal,
        setValidateMinutaVendaValorVenda,
        setValidateMinutaVendaValorSinal,
        setValidateMinutaVendaPrazoEscritura,
        setIsShowFormUploadRecibo,
        setVendedorProcesso,
        setCompradorProcesso,
        setIdProcessosModal,
        setValidateFieldValorVenda,

        //Lists filtro
        listEnderecos,

        //Remover Venda        
        setIsShowRemover,
        setProcessoIdRemove,

        //Restaurar Venda
        setIsShowRestore,

        //Vendedor
        setDadosMinutaVendedor,
        setMinutaVendedorEstadoCivil,
        setMinutaVendedorUniaoEstavel,
        setCheckedMinutaVendedorUniaoEstavel,
        setValidateMinutaVendedorCpf,
        setValidateMinutaVendedorTelefone,
        setValidateMinutaVendedorDataNascimento,
        setValidateMinutaVendedorNome,
        setValidateMinutaVendedorNomeMae,
        setValidateMinutaVendedorNacionalidade,
        setValidateMinutaVendedorCep,
        setValidateMinutaVendedorNumero,
        setValidateMinutaVendedorRg,
        setValidateMinutaVendedorRgExpedicao,
        setValidateMinutaVendedorDataExpedicao,
        setValidateMinutaVendedorEstadoCivil,
        setValidateMinutaVendedorRegimeCasamento,
        setMinutaVendedorRegimeCasamento,
        setValidateMinutaVendedorConjuge,

        //Comprador
        setDadosMinutaComprador,
        setMinutaCompradorEstadoCivil,
        setMinutaCompradorUniaoEstavel,
        setCheckedMinutaCompradorUniaoEstavel,
        setValidateMinutaCompradorCpf,
        setValidateMinutaCompradorTelefone,
        setValidateMinutaCompradorDataNascimento,
        setValidateMinutaCompradorNome,
        setValidateMinutaCompradorNomeMae,
        setValidateMinutaCompradorNacionalidade,
        setValidateMinutaCompradorCep,
        setValidateMinutaCompradorNumero,
        setValidateMinutaCompradorRg,
        setValidateMinutaCompradorRgExpedicao,
        setValidateMinutaCompradorDataExpedicao,
        setValidateMinutaCompradorEstadoCivil,
        setValidateMinutaCompradorRegimeCasamento,
        setMinutaCompradorRegimeCasamento,
        setValidateMinutaCompradorConjuge,

        //Upload Recibo de Sinal
        setValidateUploadReciboDataAssinatura,
        setValidateUploadReciboDocAssinado,

        //Boletim de Venda
        setIsShowFormBoletimDeVenda,
        isShowEntregarVenda, setIsShowEntregarVenda,
        setIsShowCornerDialog,
        setModelCornerDialog,
        setValidateBoletimVendaDocumentos,

        //Vendedor
        setValidateProcuradorVendedor,
        setValidateTelefoneProcuradorVendedor,
        setCheckedProcuradorVendedor,
        setValidateBoletimVendaDocumentosVendedor,

        //Comprador
        setValidateProcuradorComprador,
        setValidateTelefoneProcuradorComprador,
        setCheckedProcuradorComprador,
        setValidateBoletimVendaDocumentosComprador,

        //Comissão
        setLiquidaRadius,
        totalRadiusComissao, setTotalRadiusComissao,
        setCheckedPrimeiraParte,
        setCheckedSegundaParte,
        setValidateRadiusTotal,
        setValidateRadiusLiquida,
        setValidateComissaoTotal,
        setValidateComissaoLiquida,
        setValidateBoletimVendaDocumentosComissao,
        setRegressivaEntregarVenda,

        //SideSheet
        setDadosReciboEnviado,
        setIsShownSide,
        setSelectedIndexEnviados,

        listaGerente,
        filtro, quantFiltro, pageNumberAdicionados, setPageNumberAdicionados,
        totalProcessosFiltradosAdicionados, setTotalProcessosFiltradosAdicionados,
        totalPaginasAdicionados, setTotalPaginasAdicionados,
        totalItensAdicionados,
        listaLojas, defaultOptions,
    } = useContext(VendasContext)
    //Tabs
    const [selectTabs, setSelectTabs] = useState(0);

    const history = useHistory();
    const usuario_id = localStorage.getItem('usuario_id');
    const token = localStorage.getItem('token');
    const app = Serve();

    const verificaFormVenda = async (venda) => {
        // Retornar as Formas de Pagamento no Form de Vendas
        let arrayPagamento = null;
        arrayPagamento = venda.informacao.forma_pagamento !== null ? venda.informacao.forma_pagamento.split(',') : 0;

        for (let i = 0; i < arrayPagamento.length; i++) {

            if (arrayPagamento[i] === '1') {
                setCheckedMinutaAVista(true);
            }

            if (arrayPagamento[i] === '2') {
                setCheckedMinutaFinanciamento(true);
            }

            if (arrayPagamento[i] === '3') {
                setCheckedMinutaFgts(true);
            }

            if (arrayPagamento[i] === '4') {
                setCheckedMinutaConsorcio(true);
            }

            if (arrayPagamento[i] === '5') {
                setCheckedMinutaParcelamento(true);
            }

            if (arrayPagamento[i] === '6') {
                setCheckedMinutaPix(true);
            }

            if (arrayPagamento[i].length > 0) {
                setValidateMinutaVendaFormaPagamento(true);
            }
        }

        // Retorna os Laudemios do Form de Venda
        let mapa = venda.laudemios.map(laudemio => ({ "tipo_laudemio": laudemio.tipo_laudemio, "valor_laudemio": laudemio.valor_laudemio }));
        setMinutaVendaLaudemio(mapa);

        // Retorna validação
        venda.cep !== null ? setValidateMinutaVendaCep(true) : setValidateMinutaVendaCep(false);
        venda.numero !== null ? setValidateMinutaVendaNumero(true) : setValidateMinutaVendaNumero(false);
        venda.informacao.escritura !== null ? setValidateMinutaVendaEscritura(true) : setValidateMinutaVendaEscritura(false);
        venda.informacao.matricula !== null ? setValidateMinutaVendaMatricula(true) : setValidateMinutaVendaMatricula(false);
        venda.informacao.inscricaoMunicipal !== null ? setValidateMinutaVendaInscricaoMunicipal(true) : setValidateMinutaVendaInscricaoMunicipal(false);
        venda.informacao.valor_venda !== null ? setValidateMinutaVendaValorVenda(true) : setValidateMinutaVendaValorVenda(false);
        venda.informacao.valorSinal !== null ? setValidateMinutaVendaValorSinal(true) : setValidateMinutaVendaValorSinal(false);
        venda.informacao.prazo !== null ? setValidateMinutaVendaPrazoEscritura(true) : setValidateMinutaVendaPrazoEscritura(false);
        venda.informacao.forma_pagamento !== null ? setValidateMinutaVendaFormaPagamento(true) : setValidateMinutaVendaFormaPagamento(false)

        venda.informacao.escritura !== null ? setMinutaVendaEscritura(venda.informacao.escritura) : setMinutaVendaEscritura('');
    }

    const verificarFormVendedor = (venda) => {
        if (venda.vendedores.data.length !== 0) {
            setDadosMinutaVendedor(venda.vendedores.data[0])

            if (venda.vendedores.data[0].estado_civil !== null) {
                setMinutaVendedorEstadoCivil(venda.vendedores.data[0].estado_civil);
            }
            else {
                setMinutaVendedorEstadoCivil('0');
            }

            if (venda.vendedores.data[0].registro_casamento !== null) {
                setMinutaVendedorRegimeCasamento(venda.vendedores.data[0].registro_casamento);
                setValidateMinutaVendedorRegimeCasamento(true);

                if (venda.vendedores.data[0].conjuge !== null) {
                    setValidateMinutaVendedorConjuge(true);
                }
                else {
                    setValidateMinutaVendedorConjuge(false);
                }
            }
            else {
                setMinutaVendedorRegimeCasamento('0');
                setValidateMinutaVendedorRegimeCasamento(false);
            }

            if (venda.vendedores.data[0].uniao_estavel !== null) {
                setMinutaVendedorUniaoEstavel(venda.vendedores.data[0].uniao_estavel);
                setCheckedMinutaVendedorUniaoEstavel(true);
                if (venda.vendedores.data[0].conjuge !== null) {
                    setValidateMinutaVendedorConjuge(true);
                }
            }
            else {
                setMinutaVendedorUniaoEstavel('0');
                setCheckedMinutaVendedorUniaoEstavel(false);
                if (venda.vendedores.data[0].conjuge === null) {
                    setValidateMinutaVendedorConjuge(true);
                }
            }

            venda.vendedores.data[0].cpf_cnpj !== null ? setValidateMinutaVendedorCpf(true) : setValidateMinutaVendedorCpf(false);
            venda.vendedores.data[0].telefone !== null ? setValidateMinutaVendedorTelefone(true) : setValidateMinutaVendedorTelefone(false);
            venda.vendedores.data[0].data_nascimento !== null ? setValidateMinutaVendedorDataNascimento(true) : setValidateMinutaVendedorDataNascimento(false);
            venda.vendedores.data[0].name !== null ? setValidateMinutaVendedorNome(true) : setValidateMinutaVendedorNome(false);
            venda.vendedores.data[0].nome_mae !== null ? setValidateMinutaVendedorNomeMae(true) : setValidateMinutaVendedorNomeMae(false);
            venda.vendedores.data[0].nacionalidade !== null ? setValidateMinutaVendedorNacionalidade(true) : setValidateMinutaVendedorNacionalidade(true);
            venda.vendedores.data[0].cep !== null ? setValidateMinutaVendedorCep(true) : setValidateMinutaVendedorCep(false);
            venda.vendedores.data[0].numero !== null ? setValidateMinutaVendedorNumero(true) : setValidateMinutaVendedorNumero(false)
            venda.vendedores.data[0].rg !== null ? setValidateMinutaVendedorRg(true) : setValidateMinutaVendedorRg(false)
            venda.vendedores.data[0].rg_expedido !== null ? setValidateMinutaVendedorRgExpedicao(true) : setValidateMinutaVendedorRgExpedicao(false)
            venda.vendedores.data[0].data_rg_expedido !== null ? setValidateMinutaVendedorDataExpedicao(true) : setValidateMinutaVendedorDataExpedicao(false)
            venda.vendedores.data[0].estado_civil !== null ? setValidateMinutaVendedorEstadoCivil(true) : setValidateMinutaVendedorEstadoCivil(false)
            venda.vendedores.data[0].registro_casamento !== null ? setValidateMinutaVendedorRegimeCasamento(true) : setValidateMinutaVendedorRegimeCasamento(false);
            //venda.vendedores.data[0].conjuge !== null ? setValidateMinutaVendedorConjuge(true) : setValidateMinutaVendedorConjuge(false);
        }
    }

    const verificarFormComprador = (venda) => {
        if (venda.compradores.data.length !== 0) {
            setDadosMinutaComprador(venda.compradores.data[0])

            if (venda.compradores.data[0].estado_civil !== null) {
                setMinutaCompradorEstadoCivil(venda.compradores.data[0].estado_civil);
            }
            else {
                setMinutaCompradorEstadoCivil('0');
            }

            if (venda.compradores.data[0].registro_casamento !== null) {
                setMinutaCompradorRegimeCasamento(venda.compradores.data[0].registro_casamento);
                setValidateMinutaCompradorRegimeCasamento(true);

                if (venda.compradores.data[0].conjuge !== null) {
                    setValidateMinutaCompradorConjuge(true);
                }
                else {
                    setValidateMinutaCompradorConjuge(false);
                }
            }
            else {
                setMinutaCompradorRegimeCasamento('0');
                setValidateMinutaCompradorRegimeCasamento(false);
            }

            if (venda.compradores.data[0].uniao_estavel !== null) {
                setMinutaCompradorUniaoEstavel(venda.compradores.data[0].uniao_estavel);
                setCheckedMinutaCompradorUniaoEstavel(true);
                //setValidateMinutaCompradorConjuge(false)
                if (venda.compradores.data[0].conjuge !== null) {
                    setValidateMinutaCompradorConjuge(true);
                }
            }
            else {
                setMinutaCompradorUniaoEstavel('0');
                setCheckedMinutaCompradorUniaoEstavel(false);
                if (venda.compradores.data[0].conjuge === null) {
                    setValidateMinutaCompradorConjuge(true);
                }
            }

            venda.compradores.data[0].cpf_cnpj !== null ? setValidateMinutaCompradorCpf(true) : setValidateMinutaCompradorCpf(false);
            venda.compradores.data[0].telefone !== null ? setValidateMinutaCompradorTelefone(true) : setValidateMinutaCompradorTelefone(false);
            venda.compradores.data[0].data_nascimento !== null ? setValidateMinutaCompradorDataNascimento(true) : setValidateMinutaCompradorDataNascimento(false);
            venda.compradores.data[0].name !== null ? setValidateMinutaCompradorNome(true) : setValidateMinutaCompradorNome(false);
            venda.compradores.data[0].nome_mae !== null ? setValidateMinutaCompradorNomeMae(true) : setValidateMinutaCompradorNomeMae(false);
            venda.compradores.data[0].nacionalidade !== null ? setValidateMinutaCompradorNacionalidade(true) : setValidateMinutaCompradorNacionalidade(true);
            venda.compradores.data[0].cep !== null ? setValidateMinutaCompradorCep(true) : setValidateMinutaCompradorCep(false);
            venda.compradores.data[0].numero !== null ? setValidateMinutaCompradorNumero(true) : setValidateMinutaCompradorNumero(false)
            venda.compradores.data[0].rg !== null ? setValidateMinutaCompradorRg(true) : setValidateMinutaCompradorRg(false)
            venda.compradores.data[0].rg_expedido !== null ? setValidateMinutaCompradorRgExpedicao(true) : setValidateMinutaCompradorRgExpedicao(false)
            venda.compradores.data[0].data_rg_expedido !== null ? setValidateMinutaCompradorDataExpedicao(true) : setValidateMinutaCompradorDataExpedicao(false)
            venda.compradores.data[0].estado_civil !== null ? setValidateMinutaCompradorEstadoCivil(true) : setValidateMinutaCompradorEstadoCivil(false)
            venda.compradores.data[0].registro_casamento !== null ? setValidateMinutaCompradorRegimeCasamento(true) : setValidateMinutaCompradorRegimeCasamento(false);
            //venda.compradores.data[0].conjuge !== null ? setValidateMinutaCompradorConjuge(true) : setValidateMinutaCompradorConjuge(false);
        }
    }

    const clearStatesVenda = (e) => {
        //Venda 
        setCheckedMinutaAVista(false);
        setCheckedMinutaFgts(false);
        setCheckedMinutaFinanciamento(false);
        setCheckedMinutaParcelamento(false);
        setCheckedMinutaConsorcio(false);
        setMinutaVendaLaudemio([]);
        setValidateMinutaVendaFormaPagamento(false);
        setValidateMinutaVendaCep(false);
        setValidateMinutaVendaNumero(false)
        setValidateMinutaVendaEscritura(false);
        setValidateMinutaVendaMatricula(false);
        setValidateMinutaVendaInscricaoMunicipal(false);
        setValidateMinutaVendaValorVenda(false);
        setValidateMinutaVendaValorSinal(false);
        setValidateMinutaVendaPrazoEscritura(false);

        //Vendedor
        setCheckedMinutaVendedorUniaoEstavel('');
        setMinutaVendedorEstadoCivil('0');
        setMinutaVendedorRegimeCasamento('0');
        setMinutaVendedorUniaoEstavel('')
        setValidateMinutaVendedorConjuge(false);
        setValidateMinutaVendedorCpf(false);
        setValidateMinutaVendedorTelefone(false);
        setValidateMinutaVendedorDataNascimento(false);
        setValidateMinutaVendedorNome(false);
        setValidateMinutaVendedorNomeMae(false);
        setValidateMinutaVendedorNacionalidade(false);
        setValidateMinutaVendedorCep(false);
        setValidateMinutaVendedorNumero(false);
        setValidateMinutaVendedorRg(false);
        setValidateMinutaVendedorRgExpedicao(false);
        setValidateMinutaVendedorDataExpedicao(false);
        setValidateMinutaVendedorEstadoCivil(false);
        setValidateMinutaVendedorRegimeCasamento(false);

        //Comprador
        setCheckedMinutaCompradorUniaoEstavel('');
        setMinutaCompradorEstadoCivil('0');
        setMinutaCompradorRegimeCasamento('0');
        setMinutaCompradorUniaoEstavel('')
        setValidateMinutaCompradorConjuge(false);
        setValidateMinutaCompradorCpf(false);
        setValidateMinutaCompradorTelefone(false);
        setValidateMinutaCompradorDataNascimento(false);
        setValidateMinutaCompradorNome(false);
        setValidateMinutaCompradorNomeMae(false);
        setValidateMinutaCompradorNacionalidade(false);
        setValidateMinutaCompradorCep(false);
        setValidateMinutaCompradorNumero(false);
        setValidateMinutaCompradorRg(false);
        setValidateMinutaCompradorRgExpedicao(false);
        setValidateMinutaCompradorDataExpedicao(false);
        setValidateMinutaCompradorEstadoCivil(false);
        setValidateMinutaCompradorRegimeCasamento(false);

        //Boletim de Vendas
        setValidateFieldValorVenda(false);
        setValidateBoletimVendaDocumentos(false);
        setValidateBoletimVendaDocumentosVendedor(false);
        setValidateProcuradorVendedor(true);
        setValidateTelefoneProcuradorVendedor(true);
        setCheckedProcuradorVendedor(false);
        setValidateBoletimVendaDocumentosComprador(false)
        setValidateProcuradorComprador(true)
        setValidateTelefoneProcuradorComprador(true);
        setCheckedProcuradorComprador(false);
        setValidateRadiusTotal(false);
        setValidateRadiusLiquida(false);
        setValidateComissaoTotal(false);
        setValidateComissaoLiquida(false);
        setValidateBoletimVendaDocumentosComissao(false);
    }

    const formVenda = async (e, venda) => {
        clearStatesVenda();
        verificaFormVenda(venda);
        verificarFormVendedor(venda);
        verificarFormComprador(venda);
        setDadosVenda(venda);
        setDadosMinutaVendedor(venda.vendedores.data.length !== 0 ? venda.vendedores.data[0] : []);
        setDadosMinutaComprador(venda.compradores.data.length !== 0 ? venda.compradores.data[0] : []);

        //Identifica qual formulario vai abrir
        if (venda.status_processo > 17) {
            setPosicaoMinuta(4);
            setCountMenu(4);
        }
        else {
            switch (venda.status_processo) {
                case 9:
                    setPosicaoMinuta(2);
                    setCountMenu(2);
                    break;
                case 10:
                    setPosicaoMinuta(3);
                    setCountMenu(3);
                    break;
                case 11:
                    setPosicaoMinuta(4);
                    setCountMenu(4);
                    break;
                case 12:
                    setPosicaoMinuta(1);
                    setCountMenu(1);
                    break;
                case 13:
                    setPosicaoMinuta(2);
                    setCountMenu(2);
                    break;
                case 14:
                    setPosicaoMinuta(3);
                    setCountMenu(3);
                    break;
                case 15:
                case 16:
                case 17:
                    setPosicaoMinuta(4);
                    setCountMenu(4);
                    break;
                default:
                    break;
            }
        }

        setIsShowFormMinuta(true);
    }

    const formUploadRecibo = (e, venda) => {
        venda.informacao.data_assinatura !== '' ? setValidateUploadReciboDataAssinatura(true) : setValidateUploadReciboDataAssinatura(false);
        venda.informacao.recibo !== null ? setValidateUploadReciboDocAssinado(true) : setValidateUploadReciboDocAssinado(false);

        setDadosVenda(venda);
        setIsShowFormUploadRecibo(true);
    }

    const formBoletimDeVendas = (e, venda) => {

        clearStatesVenda();

        //Imóvel        
        venda.informacao.valor_venda !== null ? setValidateFieldValorVenda(true) : setValidateFieldValorVenda(false);
        venda.documentos.data.length !== 0 ? setValidateBoletimVendaDocumentos(true) : setValidateBoletimVendaDocumentos(false);

        //Vendedor
        if (venda.vendedores.data[0].procurador === null) {
            setCheckedProcuradorVendedor(false);
            setValidateProcuradorVendedor(true);
            setValidateTelefoneProcuradorVendedor(true);
        }
        else {
            setCheckedProcuradorVendedor(true);
            setValidateProcuradorVendedor(false);
            setValidateTelefoneProcuradorVendedor(false);
        }
        venda.vendedores.data[0].documentos.length === 0 ? setValidateBoletimVendaDocumentosVendedor(false) : setValidateBoletimVendaDocumentosVendedor(true);

        //Comprador
        if (venda.compradores.data[0].procurador === null) {
            setCheckedProcuradorComprador(false);
            setValidateProcuradorComprador(true);
            setValidateTelefoneProcuradorComprador(true);
        }
        else {
            setCheckedProcuradorComprador(true);
            setValidateProcuradorComprador(false);
            setValidateTelefoneProcuradorComprador(false);
        }
        venda.compradores.data[0].documentos.length === 0 ? setValidateBoletimVendaDocumentosComprador(false) : setValidateBoletimVendaDocumentosComprador(true);

        //Comissão        
        if (venda.comissao !== null) {
            setValidateBoletimVendaDocumentosComissao(true);
            setTotalRadiusComissao(venda.comissao.comissao);

            if (venda.comissao.valor_comissao_total !== null) {
                if (totalRadiusComissao === 'integral') {
                    setValidateComissaoTotal(true);
                }
                else {
                    setValidateComissaoTotal(true);
                }
            }
            else {
                setValidateComissaoTotal(true);
            }

            venda.comissao.valor_parte_um !== null ? setCheckedPrimeiraParte(true) : setCheckedPrimeiraParte(false);
            venda.comissao.valor_parte_dois !== null ? setCheckedSegundaParte(true) : setCheckedSegundaParte(false);

            if (venda.comissao.liquida !== null) {
                let liquida = venda.comissao.liquida;
                setValidateRadiusLiquida(true);
                setValidateComissaoLiquida(true);

                switch (liquida) {
                    case 'especie':
                        setLiquidaRadius('especie');
                        break;

                    case 'deposito':
                        setLiquidaRadius('deposito');
                        break;

                    case 'ted_doc':
                        setLiquidaRadius('ted_doc');
                        break;

                    case 'pix':
                        setLiquidaRadius('pix');
                        break;

                    case 'cheque':
                        setLiquidaRadius('cheque');
                        break;

                    default:
                        break;
                }
            }
            else {
                setLiquidaRadius('null');
                setValidateComissaoLiquida(false);
            }
        }
        else {
            setValidateBoletimVendaDocumentosComissao(false);
            setTotalRadiusComissao('integral');
            setValidateComissaoTotal(false);
            setValidateRadiusLiquida(false);
            setValidateComissaoLiquida(false);
            setLiquidaRadius('null');
        }
        //venda.comissao === null ? setValidateBoletimVendaDocumentosComissao(false) : setValidateBoletimVendaDocumentosComissao(true);

        setDadosVenda(venda);
        setIdProcessosModal(venda.informacao.id);
        setVendedorProcesso(venda.vendedores);
        setCompradorProcesso(venda.compradores);

        switch (venda.status_processo) {
            case 16:
                setPosicaoBoletimDeVenda(1);
                setCountMenu(1);
                break;
            case 17:
                setPosicaoBoletimDeVenda(2);
                setCountMenu(2);
                break;
            case 18:
                setPosicaoBoletimDeVenda(3);
                setCountMenu(3);
                break;
            case 19:
                setPosicaoBoletimDeVenda(4);
                setCountMenu(4);
                break;
            case 20:
                setPosicaoBoletimDeVenda(4);
                setCountMenu(5);
                break;
            case 22:
                if (venda.revisao.valores_documentos_imovel === 1 && venda.revisao.valores_documentos_imovel_verific_save === 0) {
                    setPosicaoBoletimDeVenda(1);
                    setCountMenu(1);
                }
                else if (venda.revisao.vendedores === 1 && venda.revisao.vendedores_verific_save === 0) {
                    setPosicaoBoletimDeVenda(2);
                    setCountMenu(2);
                }
                else if (venda.revisao.compradores === 1 && venda.revisao.compradores_verific_save === 0) {
                    setPosicaoBoletimDeVenda(3);
                    setCountMenu(3);
                }
                else if (venda.revisao.comissao === 1 && venda.revisao.comissao_verific_save === 0) {
                    setPosicaoBoletimDeVenda(4);
                    setCountMenu(4);
                }
                else {
                    //setPosicaoBoletimDeVenda(4);
                    //setCountMenu(5);

                    if (venda.revisao.valores_documentos_imovel === 1 && venda.revisao.valores_documentos_imovel_verific_save === 1) {
                        setPosicaoBoletimDeVenda(1);
                        setCountMenu(5);
                    }
                    else if (venda.revisao.vendedores === 1 && venda.revisao.vendedores_verific_save === 1) {
                        setPosicaoBoletimDeVenda(2);
                        setCountMenu(5);
                    }
                    else if (venda.revisao.compradores === 1 && venda.revisao.compradores_verific_save === 1) {
                        setPosicaoBoletimDeVenda(3);
                        setCountMenu(5);
                    }
                    else if (venda.revisao.comissao === 1 && venda.revisao.comissao_verific_save === 1) {
                        setPosicaoBoletimDeVenda(4);
                        setCountMenu(5);
                    }
                }
                break;
            default:
                break;
        }
        setIsShowFormBoletimDeVenda(true);
    }

    const DialogEntrega = (e, venda) => {
        setDadosVenda(venda);
        setIsShowCornerDialog(false);
        setModelCornerDialog('');
        setIsShowEntregarVenda(true);
    }

    const confirmarEntregaVenda = (e, venda) => {
        e.preventDefault();
        let imovel_id = venda.id;
        let processo_id = venda.processo_id;

        setLoading(false);

        Axios.post(app + 'vincular_responsavel_processo', {
            'imovel_id': imovel_id,
            'processo_id': processo_id,
            'usuario_id': usuario_id
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then(res => {
                if (res.data.status && res.data.status === (401 || 498)) {
                    localStorage.clear();
                    history.push('/');
                } else {
                    //alert(res.data.mensagem_processo);
                    //let mensagem = res.data.mensagem_processo;
                    //Se tiver resposta do servidor que os dados foram editados 

                    //recarregarVendasAdicionadas();
                    //recarregarVendasEnviadas();
                    recarregarVendas();

                    setIsShowEntregarVenda(false);
                    setIsShowCornerDialog(true);
                    setModelCornerDialog(4);

                    // Abre o contado para o Corner de Confirmação de Envio para o Backoffice
                    var timeleft = 5;
                    var downloadTimer = setInterval(function () {
                        if (timeleft <= 0) {
                            clearInterval(downloadTimer);
                            setRegressivaEntregarVenda(0)
                        } else {
                            setRegressivaEntregarVenda(timeleft)
                        }
                        timeleft -= 1;
                    }, 1000);

                }
            })
            .catch(function (error) {
                // handle error
                if (error.response) {
                    let resposta = error.response.data;

                    let erros = '';
                    if (resposta.errors) {
                        for (let [key, value] of Object.entries(resposta.errors)) {
                            erros += value + "\n";
                        }
                    }

                    if (resposta.error) {
                        erros = resposta.error;
                    }

                    alert(erros);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }

            })
        //setTimeout(function () { setLoading(true); }, 1000);*/        
    };

    function verificarRevisao(venda) {
        let status = ''
        if (venda.revisao && venda.revisao.valores_documentos_imovel === venda.revisao.valores_documentos_imovel_verific_save &&
            venda.revisao.vendedores === venda.revisao.vendedores_verific_save &&
            venda.revisao.compradores === venda.revisao.compradores_verific_save &&
            venda.revisao.comissao === venda.revisao.comissao_verific_save) {
            status = 'revisado';
            return status;
        }
        else {
            status = 'revisar';
            return status;
        }
    };

    const recarregarVendas = async () => {
        setLoading(false);
        await Axios.post(app + 'retorno_processos_criados_gerente_minuta', {
            "usuario_id": usuario_id,
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then(res => {
                if (!res) {
                    setLoading(true);
                }
                if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                    localStorage.clear();
                    history.push('/');
                    setLoading(true);
                } else {
                    const itemsAdicionados = res.data.adicionados;
                    const itemsEnviados = res.data.enviados;
                    const itemsArquivados = res.data.arquivados;

                    setItemAdicionado(itemsAdicionados);
                    setItemEnviado(itemsEnviados);
                    setItemsArquivados(itemsArquivados);
                    setLoading(true);
                }
            })
    };

    async function handleRemoveAndRestore(processo_id, type) {
        if (type === "Remove") {
            setIsShowRemover(true);
            setProcessoIdRemove(processo_id);
        }
        else {
            setIsShowRestore(true);
            setProcessoIdRemove(processo_id);
        };
    };

    const handleSideSheet = (e, recibo) => {
        setDadosVenda(recibo);
        setDadosReciboEnviado(recibo);
        setIsShownSide(true);
    };

    const acessoTab = async (tab) => {
        setSelectedIndexEnviados(tab);
    };

    const handleFilter = async (page, temFiltro) => {
        setLoading(false);
        localStorage.setItem('page_number', page);
        setPageNumberAdicionados(page);
        
        await Axios.post(app + 'retorno_processos_painel_gerentes', {
            usuario_id: !filtro.gerente_rascunho ? usuario_id : filtro.gerente_rascunho.id,
            page: page,
            filtro: filtro.limpar_filtro_rascunho,
            tipo_processos: 'adicionados',
            loja_id: filtro.loja_rascunho,
            mes_cadastro: filtro.mes_cadastro_rascunho,            
            logradouro: filtro.endereco_rascunho ? filtro.endereco_rascunho.endereco : ""
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {
            if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                localStorage.clear();
                history.push('/');
            } else {
                const itensAdicionados = res.data.processos.data;
                let totalPaginas = res.data.total_pagina;
                let totalItensFiltrados = res.data.total_processos_filtrados;
                setItemAdicionado(itensAdicionados);
                setTotalPaginasAdicionados(totalPaginas);
                setTotalProcessosFiltradosAdicionados(totalItensFiltrados);
                setLoading(true);
            }
        })
    };

    // Paginação
    function listItemsPerPage(pageNumberAdicionados) {
        handleFilter(pageNumberAdicionados);
    };

    // Filtros
    useEffect(() => {
        quantidadeFiltros();
    })

    const quantidadeFiltros = async () => {
        let filtrosSelecionados = 0;

        if (filtro.endereco_rascunho !== '') {
            filtrosSelecionados++;
        }

        if (filtro.gerente_rascunho !== '') {
            filtrosSelecionados++;
        }

        if (filtro.loja_rascunho !== '') {
            filtrosSelecionados++;
        }

        if (filtro.mes_cadastro_rascunho !== '') {
            filtrosSelecionados++;
        }
        quantFiltro[0] = filtrosSelecionados;
    };

    const handleSelect = (e) => {
        quantidadeFiltros();
        setPageNumberAdicionados(1);
        const value = e.target.value;
        const name = e.target.name;
        localStorage.setItem('filtro_' + name, value);
        filtro[name] = value;

        if (name === 'gerente_rascunho') {
            localStorage.setItem('filtro_limpar_filtro_rascunho', 'ok');
            filtro['limpar_filtro_rascunho'] = 'ok';
        }
        handleFilter(1, 'filtro');
    };

    // Filtro de Combo
    const handleCombobox = (e, campo) => {
        if (e) {
            localStorage.setItem('filtro_'+ campo, JSON.stringify(e));
            filtro[campo] = e;
            handleFilter(1, 'filtro');
        }
    };

    const handleClearFilter = async (e) => {
        setLoading(false);
        setPageNumberAdicionados(1);        
        filtro.endereco_rascunho = '';
        filtro.gerente_rascunho = '';
        filtro.limpar_filtro_rascunho = '';
        filtro.loja_rascunho = '';
        filtro.mes_cadastro_rascunho = '';
        localStorage.removeItem('filtro_endereco_rascunho');
        localStorage.removeItem('filtro_gerente_rascunho');
        localStorage.removeItem('filtro_limpar_filtro_rascunho');
        localStorage.removeItem('filtro_loja_rascunho');
        localStorage.removeItem('filtro_mes_cadastro_rascunho');

        handleFilter(1, 'filtro');
        setTotalProcessosFiltradosAdicionados(0);
    };

    return (
        <Pane>
            <FormVenda />
            <Pane>
                <div className='flex space-between pb10'>
                    <div className="flex gap20 end">
                        <span>Filtrar por: </span>
                        <div>
                            <Combobox
                                openOnFocus
                                name='endereco_rascunho'
                                items={listEnderecos.rascunhos}
                                selectedItem={filtro.endereco_rascunho}
                                itemToString={item => (item ? item.endereco : '')}
                                placeholder="🔍︎ Endereço"
                                onChange={(e) => handleCombobox(e, 'endereco_rascunho')}
                                width={200}
                                id="filtro-endereco"
                                className="filtro-select color-select-filter"
                            />
                        </div>
                        <div>
                            <Combobox
                                openOnFocus
                                name='gerente_rascunho'
                                items={listaGerente}
                                selectedItem={filtro.gerente_rascunho}
                                itemToString={item => (item ? item.name : '')}
                                placeholder="🔍︎ Gerente"
                                onChange={(e) => handleCombobox(e, 'gerente_rascunho')}
                                width={180}
                                id="filtro-gerente"
                                className="filtro-select color-select-filter"
                            />
                        </div>
                        <div>
                            <Select
                                label=""
                                name='loja_rascunho'
                                value={filtro.loja_rascunho}
                                onChange={(e) => handleSelect(e)}
                                id="filtro-loja_rascunho"
                                width={160}
                                className={filtro.loja_rascunho === "" ? "filtro-select bgWhite color-select-filter-hidden" : "filtro-select bgWhite color-select-filter"}                            
                            >
                                <option value="" hidden>Loja</option>
                                {listaLojas.map(valor =>
                                    <option key={valor.id} value={valor.id}>{valor.nome}</option>
                                )}
                            </Select>
                        </div>
                        <div>
                            <Select
                                label=""
                                name='mes_cadastro_rascunho'
                                value={filtro.mes_cadastro_rascunho}
                                onChange={(e) => handleSelect(e)}
                                width={145}
                                id="filtro-mes-cadastro"
                                className={filtro.mes_cadastro_rascunho === "" ? "filtro-select bgWhite color-select-filter-hidden" : "filtro-select bgWhite color-select-filter"}                            
                            >
                                <option value="" hidden>Mês de cadastro</option>
                                <option value="1">Janeiro</option>
                                <option value="2">Fevereiro</option>
                                <option value="3">Março</option>
                                <option value="4">Abril</option>
                                <option value="5">Maio</option>
                                <option value="6">Junho</option>
                                <option value="7">Julho</option>
                                <option value="8">Agosto</option>
                                <option value="9">Setembro</option>
                                <option value="10">Outubro</option>
                                <option value="11">Novembro</option>
                                <option value="12">Dezembro</option>
                            </Select>
                        </div>
                    </div>
                    <div>
                        <Pagination
                            page={pageNumberAdicionados}
                            totalPages={Number(totalPaginasAdicionados)}
                            onPageChange={(e) => loading ? listItemsPerPage(e) : ''}
                            onNextPage={() => listItemsPerPage(pageNumberAdicionados + 1)}
                            onPreviousPage={() => listItemsPerPage(pageNumberAdicionados - 1)}
                        />
                    </div>
                </div>

                {quantFiltro[0] >= 1 ?
                    <div className="filter-info">
                        <p>
                            <FilterIcon className="icon-filter" />
                            {quantFiltro[0]} {quantFiltro[0] === 1 ? 'filtro aplicado. ' : 'filtros aplicados. '}
                            {itemAdicionado.length} / {totalProcessosFiltradosAdicionados} {totalProcessosFiltradosAdicionados === 1 ? 'processo visível' : 'processos visíveis'}.
                                    <span onClick={(e) => handleClearFilter(e)}> Limpar filtro</span>
                        </p>
                    </div>

                    :
                    ''}
            </Pane>

            <div className={selectTabs === 0 ? "Rectangle35 Blue" : "Rectangle35 Red300"}></div>
            <Table.Body className="table" style={{ overflow: 'hidden' }}>
                <Table.Head>
                    {<Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0} className="header-title">
                        Gerente
                    </Table.TextCell>}
                    <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0} className="header-title">
                        Loja
                    </Table.TextCell>
                    <Table.TextCell flexBasis={300} flexShrink={0} flexGrow={0} className="header-title">
                        Endereço
                    </Table.TextCell>
                    <Table.TextCell flexBasis={155} flexShrink={0} flexGrow={0} className="header-title">
                        Data de cadastro
                    </Table.TextCell>
                    <Table.TextCell flexBasis={300} flexShrink={0} flexGrow={0} className="header-title">
                        Etapa
                    </Table.TextCell>
                    <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="header-title">
                        {/*Forma de pagamento*/}
                    </Table.TextCell>
                    <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} className="header-title">
                        {/*Arquivo do venda*/}
                    </Table.TextCell>
                    <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0} className="header-title">
                        {/*Arquivo do venda*/}
                    </Table.TextCell>
                </Table.Head>

                {
                    //Enquanto não carregar os dados exibe um Spinner
                    /* loading === false && <Spinner size={24} style={{ margin: 20 }} /> */
                    loading === false &&
                    <div className='backgroundLoadingDocs'>
                        <Lottie
                            options={defaultOptions}
                            height={200}
                            width={400}
                        />
                    </div>
                }

                {
                    loading === true &&
                        selectTabs === 0
                        ?
                        <Table.Body style={{ backgroundColor: 'white' }}>
                            {
                                itemAdicionado.length > 0
                                    ?
                                    <div>
                                        {itemAdicionado.map(venda =>
                                            <Table.Row key={venda.id}>
                                                <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0} className="content">
                                                    <Avatar hashValue={venda.nome_criador_processo} name={venda.nome_criador_processo} size={40} marginRight={10} />
                                                </Table.TextCell>

                                                <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0} className="content">
                                                    {venda.loja !== null ? venda.loja.nome : 'Sem loja'}
                                                </Table.TextCell>

                                                <Table.TextCell
                                                    flexBasis={300}
                                                    flexShrink={0}
                                                    flexGrow={0}
                                                    className="content-endereco content-click"
                                                    style={parseInt(usuario_id) !== venda.id_usuario_criador_processo ? { cursor: 'default' } : { cursor: 'pointer' }}
                                                    onClick={(e) => parseInt(usuario_id) !== venda.id_usuario_criador_processo ? (e) : formVenda(e, venda)}
                                                >
                                                    {venda.logradouro + ', ' + venda.numero + (venda.unidade !== null ? " / " + venda.unidade : "")}
                                                </Table.TextCell>

                                                <Table.TextCell flexBasis={155} flexShrink={0} flexGrow={0} className="content">
                                                    {venda.data_criacao_processo}
                                                </Table.TextCell>

                                                <Table.TextCell
                                                    flexBasis={300}
                                                    flexShrink={0}
                                                    flexGrow={0}
                                                    className="content-endereco"
                                                >
                                                    <div className="steps steps-lista">
                                                        {venda.status_processo === 22
                                                            ?
                                                            <div className="step-item">
                                                                <span className="default">
                                                                    {verificarRevisao(venda) === 'revisado' ? "Revisão da devolução" : "Ajustes na devolução "}
                                                                    {
                                                                        verificarRevisao(venda) === 'revisado'
                                                                            ?
                                                                            <TickCircleIcon style={{ height: '16px' }} size={16} className='icon-tick-revisao' />
                                                                            :
                                                                            <ErrorIcon style={{ height: '16px' }} size={16} className="icon-error-revisao" />
                                                                    }
                                                                </span>
                                                            </div>

                                                            :
                                                            <>
                                                                {
                                                                    //venda.status_processo >= 8 && venda.status_processo <= 15
                                                                        venda.status_processo === 11 && venda.status_processo !== 24
                                                                        || (venda.status_processo === 15 && venda.status_processo !== 24)
                                                                        || (venda.status_processo === 9 && venda.status_processo !== 24)
                                                                        || (venda.status_processo === 10 && venda.status_processo !== 24)
                                                                        || (venda.status_processo === 24 && venda.status_anterior_processo_id === 10)
                                                                        || (venda.status_processo === 10 && venda.status_anterior_processo_id === 24)
                                                                        || (venda.status_processo === 24 && venda.status_anterior_processo_id === 23)
                                                                        || (venda.status_processo === 11 && venda.status_anterior_processo_id === 24)
                                                                        || (venda.status_processo === 15 && venda.status_anterior_processo_id === 24)
                                                                        || (venda.status_processo === 11 && venda.status_anterior_processo_id === 23)
                                                                        || (venda.status_processo === 15 && venda.status_anterior_processo_id === 23)
                                                                        ?
                                                                        <div className="step-item" style={{ marginRight: '70px' }}>
                                                                            <span className="default">Dados e rascunho</span>
                                                                        </div>

                                                                        : //venda.status_processo === 16px
                                                                            venda.status_processo >= 16 && venda.status_processo !== 24
                                                                            ?
                                                                            <div className="step-item" style={{ marginRight: '30px' }}>
                                                                                <span className="default">Upload do Recibo de Sinal</span>
                                                                            </div>

                                                                            : venda.status_processo >= 17 && venda.status_processo <= 20
                                                                                ?
                                                                                <div className="step-item" style={{ marginRight: '70px' }}>
                                                                                    <span className="default">Boletim de venda</span>
                                                                                </div>
                                                                                :
                                                                                ''
                                                                }

                                                                <div className="step-item">
                                                                    <div className="progress-line">
                                                                        <div className={
                                                                                //venda.status_processo < 15 ? "total-progress progress-step-10" : "total-progress progress-step-" + venda.status_processo
                                                                                venda.status_processo >= 15 && venda.status_processo !== 24
                                                                                || (venda.status_processo === 11 && venda.status_anterior_processo_id === 24)
                                                                                || (venda.status_processo === 11 && venda.status_anterior_processo_id === 23)
                                                                                || (venda.status_processo === 15 && venda.status_anterior_processo_id === 23)  
                                                                                ? "total-progress progress-step-" + venda.status_processo : "total-progress progress-step-10"
                                                                            }
                                                                        ></div>
                                                                    </div>
                                                                </div>

                                                                <div className="step-item">
                                                                    <span className="etapa">
                                                                        {
                                                                            /*venda.status_processo < 15 ? 0
                                                                                : venda.status_processo >= 8 && venda.status_processo <= 15 ? 1
                                                                                    : (venda.status_processo === 16) ? 2
                                                                                        : venda.status_processo >= 17 && venda.status_processo < 20 ? 3
                                                                                            : venda.status_processo === 20 ? <TickCircleIcon className="step-check" size={15} /> : 0*/

                                                                            venda.status_processo === 15 && venda.status_processo !== 24
                                                                            || (venda.status_processo === 11 && venda.status_anterior_processo_id === 24)
                                                                            || (venda.status_processo === 11 && venda.status_anterior_processo_id === 23)
                                                                            || (venda.status_processo === 15 && venda.status_anterior_processo_id === 23)
                                                                            ? 1
                                                                            : venda.status_processo >= 16 && venda.status_processo < 20
                                                                                ? 2
                                                                                : venda.status_processo === 20
                                                                                    ? <TickCircleIcon className="step-check" /> 
                                                                                    : 0
                                                                        }
                                                                    </span>{venda.status_processo === 20 ? '' : '/3'}
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </Table.TextCell>

                                                <Table.TextCell
                                                    flexBasis={
                                                        venda.status_processo === 22
                                                            ? venda.id_usuario_criador_processo !== parseInt(usuario_id) ? 155 : 130
                                                            : 155
                                                    }
                                                    flexShrink={0}
                                                    flexGrow={0}>

                                                    {
                                                        venda.status_processo !== 22 ?
                                                            parseInt(usuario_id) !== venda.id_usuario_criador_processo
                                                                ?
                                                                <Button
                                                                    marginY={8}
                                                                    marginRight={12}
                                                                    appearance="primary"
                                                                    onClick={(e) => handleSideSheet(e, venda, acessoTab(0))}
                                                                >
                                                                    <InfoSignIcon
                                                                        color="#fff"
                                                                        style={{ width: 11, height: 11, marginRight: 10 }} /
                                                                    >
                                                                Ver detalhes
                                                            </Button>
                                                                :
                                                                <Popover
                                                                    className="nav-rascunho"
                                                                    content={
                                                                        <Menu>
                                                                            <Menu.Group
                                                                                title="Continuar Edição"
                                                                            >
                                                                                <div className="nav-rascunho-venda">
                                                                                    <Menu.Item
                                                                                        icon={
                                                                                            venda.status_processo >= 14
                                                                                                ? TickCircleIcon : WarningSignIcon
                                                                                        }
                                                                                        className={
                                                                                            venda.status_processo >= 14
                                                                                                ? "tick-cicle-icon-menu nav-item"
                                                                                                : "warning-icon-menu nav-item"
                                                                                        }
                                                                                        onClick={(e) => formVenda(e, venda)}
                                                                                    >
                                                                                        Rascunho
                                                                            </Menu.Item>
                                                                                    <Menu.Item
                                                                                        icon={
                                                                                            venda.status_processo === 15 ? WarningSignIcon :
                                                                                                venda.status_processo >= 16 ? TickCircleIcon
                                                                                                    : LockIcon
                                                                                        }
                                                                                        className={
                                                                                            venda.status_processo === 15 ? "warning-icon-menu nav-item" :
                                                                                                venda.status_processo >= 16 ? "tick-cicle-icon-menu nav-item"
                                                                                                    : "lock-icon-menu nav-item"
                                                                                        }
                                                                                        onClick={(e) => venda.status_processo >= 15 ? formUploadRecibo(e, venda) : (e)}
                                                                                    >
                                                                                        Recibo de Sinal
                                                                            </Menu.Item>
                                                                                    <Menu.Item
                                                                                        icon={
                                                                                            venda.status_processo >= 16 && venda.status_processo <= 19 ? WarningSignIcon :
                                                                                                venda.status_processo === 20 ? TickCircleIcon
                                                                                                    : LockIcon
                                                                                        }
                                                                                        className={
                                                                                            venda.status_processo >= 16 && venda.status_processo <= 19 ? "warning-icon-menu nav-item" :
                                                                                                venda.status_processo === 20 ? "tick-cicle-icon-menu nav-item"
                                                                                                    : "lock-icon-menu nav-item"
                                                                                        }
                                                                                        onClick={(e) => venda.status_processo >= 16 ? formBoletimDeVendas(e, venda) : (e)}
                                                                                    >
                                                                                        Boletim de Vendas
                                                                            </Menu.Item>
                                                                                </div>
                                                                            </Menu.Group>
                                                                            <Menu.Divider />
                                                                        </Menu>
                                                                    }
                                                                >
                                                                    <Button
                                                                        marginY={8}
                                                                        marginRight={12}
                                                                        appearance="primary"
                                                                    >
                                                                        {venda.status_processo >= 20
                                                                            ?
                                                                            <>
                                                                                <EditIcon
                                                                                    color="white"
                                                                                    style={{ width: 12, height: 11, marginRight: 10 }} /
                                                                                >
                                                                        Editar
                                                                    </>

                                                                            :
                                                                            <>
                                                                                <DotIcon
                                                                                    color="white"
                                                                                    style={{ width: 8, height: 11, marginRight: 0 }} /
                                                                                >
                                                                                <DotIcon
                                                                                    color="white"
                                                                                    style={{ width: 8, height: 11, marginRight: 0 }} /
                                                                                >
                                                                                <DotIcon
                                                                                    color="white"
                                                                                    style={{ width: 8, height: 11, marginRight: 5 }} /
                                                                                >
                                                                        Continuar
                                                                    </>
                                                                        }
                                                                    </Button>
                                                                </Popover>

                                                            :
                                                            parseInt(usuario_id) !== venda.id_usuario_criador_processo
                                                                ?
                                                                <Button
                                                                    marginY={8}
                                                                    marginRight={12}
                                                                    appearance="primary"
                                                                    onClick={(e) => handleSideSheet(e, venda, acessoTab(0))}
                                                                >
                                                                    <InfoSignIcon
                                                                        color="#fff"
                                                                        style={{ width: 11, height: 11, marginRight: 10 }} /
                                                                    >
                                                                Ver detalhes
                                                            </Button>

                                                                :
                                                                <Button
                                                                    marginY={8}
                                                                    marginRight={0}
                                                                    appearance="primary"
                                                                    intent="danger"
                                                                    onClick={(e) => venda.status_processo === 22 ? formBoletimDeVendas(e, venda) : (e)}
                                                                >
                                                                    <EditIcon
                                                                        color="white"
                                                                        style={{ width: 12, height: 11, marginRight: 10 }}
                                                                    />
                                                                    {
                                                                        verificarRevisao(venda) === 'revisado'
                                                                            ?
                                                                            'Editar'
                                                                            :
                                                                            'Corrigir'
                                                                    }
                                                                </Button>
                                                    }
                                                </Table.TextCell>

                                                {
                                                    venda.status_processo === 22 ?
                                                        parseInt(usuario_id) !== venda.id_usuario_criador_processo
                                                            ?
                                                            ''
                                                            :
                                                            <Table.TextCell
                                                                flexBasis={180} flexShrink={0} flexGrow={0}>
                                                                <Button
                                                                    onClick={(e) => DialogEntrega(e, venda)}
                                                                    className={
                                                                        //venda.status_processo === 20 ? "btn-enabled-entregar-venda" : 'btn-disabled-entregar-venda'
                                                                        verificarRevisao(venda) === 'revisado'
                                                                            ? "btn-enabled-entregar-venda" : 'btn-disabled-entregar-venda'
                                                                    }
                                                                    disabled={
                                                                        //venda.status_processo === 20 ? false : true
                                                                        verificarRevisao(venda) === 'revisado'
                                                                            ? false : true
                                                                    }
                                                                >
                                                                    <ShareIcon
                                                                        color="white"
                                                                        style={{ width: 12, height: 12, marginRight: 8 }} /
                                                                    >
                                                            Reenviar Venda
                                                        </Button>
                                                            </Table.TextCell>

                                                        :
                                                        <Table.TextCell
                                                            flexBasis={180} flexShrink={0} flexGrow={0}>
                                                            {
                                                                parseInt(usuario_id) !== venda.id_usuario_criador_processo
                                                                    ?
                                                                    ''
                                                                    :
                                                                    <Button
                                                                        onClick={(e) => DialogEntrega(e, venda)}
                                                                        className={venda.status_processo === 20 ? "btn-enabled-entregar-venda" : 'btn-disabled-entregar-venda'}
                                                                        disabled={venda.status_processo === 20 ? false : true}
                                                                    >
                                                                        <ShareIcon
                                                                            color="white"
                                                                            style={{ width: 12, height: 12, marginRight: 8 }} /
                                                                        >
                                                                    Entregar venda
                                                                </Button>
                                                            }
                                                        </Table.TextCell>

                                                }
                                                {
                                                    parseInt(usuario_id) !== venda.id_usuario_criador_processo
                                                        ?
                                                        ''
                                                        :
                                                        venda.status_processo !== 22 &&
                                                            <Table.TextCell
                                                                flexBasis={200} flexShrink={0} flexGrow={0}>
                                                                <Tooltip content="Remover venda">
                                                                    <Button
                                                                        onClick={(e) => handleRemoveAndRestore(venda.processo_id, "Remove")}
                                                                    >
                                                                        <TrashIcon size={12} />
                                                                    </Button>
                                                                </Tooltip>
                                                            </Table.TextCell>
                                                }
                                            </Table.Row>
                                        )}
                                    </div>

                                    :
                                    // Se não tiver nenhum documento exibe esse bloco
                                    loading === true &&
                                    <Table.Row key={1}>
                                        <div className="mensagem-processo">
                                            {/*Puxa, você ainda não possui nenhuma venda cadastrada :( <a href onClick={(e) => dialogTipoVenda(e)} title="Para cadastrar sua primeira venda, clique aqui.">Para cadastrar sua primeira venda, clique aqui.</a>*/}
                                            Acompanhe por aqui todas as vendas cadastradas.
                                        </div>
                                    </Table.Row>
                            }
                        </Table.Body>
                        :
                        loading === true &&
                        <Table.Body style={{ backgroundColor: 'white' }}>
                            {
                                itemsArquivados.length > 0
                                    ?
                                    <div>
                                        {itemsArquivados.map(venda =>
                                            <Table.Row key={venda.id}>

                                                <Table.TextCell flexBasis={300} flexShrink={0} flexGrow={0} className="content-endereco" >
                                                    {venda.logradouro + ', ' + venda.numero + (venda.unidade !== null ? " / " + venda.unidade : "")}
                                                </Table.TextCell>

                                                <Table.TextCell
                                                    flexBasis={300}
                                                    flexShrink={0}
                                                    flexGrow={0}
                                                    className="content-endereco"
                                                >
                                                    <div className="steps steps-lista">
                                                        {venda.status_anterior_processo_id === 22
                                                            ?
                                                            <div className="step-item">
                                                                <span className="default">
                                                                    {verificarRevisao(venda) === 'revisado' ? "Revisão da devolução" : "Ajustes na devolução "}
                                                                    {
                                                                        verificarRevisao(venda) === 'revisado'
                                                                            ?
                                                                            <TickCircleIcon style={{ height: '16px' }} size={16} className='icon-tick-revisao' />
                                                                            :
                                                                            <ErrorIcon style={{ height: '16px' }} size={16} className="icon-error-revisao" />
                                                                    }
                                                                </span>
                                                            </div>

                                                            :
                                                            <>
                                                                {
                                                                    venda.status_processo >= 15
                                                                        ?
                                                                        <div className="step-item" style={{ marginRight: '70px' }}>
                                                                            <span className="default">Dados e rascunho</span>
                                                                        </div>

                                                                        : venda.status_processo >= 16
                                                                            ?
                                                                            <div className="step-item" style={{ marginRight: '30px' }}>
                                                                                <span className="default">Upload do Recibo de Sinal</span>
                                                                            </div>

                                                                            :
                                                                            <div className="step-item" style={{ marginRight: '70px' }}>
                                                                                <span className="default">Boletim de venda</span>
                                                                            </div>
                                                                }

                                                                <div className="step-item">
                                                                    <div className="progress-line">
                                                                        <div className={venda.status_anterior_processo_id < 15 ? "total-progress progress-step-10" : "total-progress progress-step-" + venda.status_anterior_processo_id}></div>
                                                                    </div>
                                                                </div>

                                                                <div className="step-item">
                                                                    <span className="etapa">
                                                                        {
                                                                            venda.status_anterior_processo_id < 15 ? 0
                                                                                : venda.status_anterior_processo_id === 15 ? 1
                                                                                    : (venda.status_anterior_processo_id >= 16 && venda.status_anterior_processo_id < 20) ? 2
                                                                                        : venda.status_anterior_processo_id === 20 ? <TickCircleIcon className="step-check" /> : 0
                                                                        }
                                                                    </span>{venda.status_anterior_processo_id === 20 ? '' : '/3'}
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </Table.TextCell>

                                                <Table.TextCell
                                                    flexBasis={150}
                                                    flexShrink={0}
                                                    flexGrow={0}
                                                >
                                                    {/* <Button disabled>
                                                        Ver detalhes
                                                    </Button> */}
                                                </Table.TextCell>
                                                {
                                                    <Table.TextCell
                                                        flexBasis={200} flexShrink={0} flexGrow={0}>
                                                        {!venda.verificar_status_imovel ?

                                                            <Tooltip content="Imovel está sendo usado em outra venda" position={'left'}>
                                                                <Button
                                                                    appearance="primary"
                                                                    style={{
                                                                        opacity: "0.5",
                                                                        cursor: 'not-allowed',
                                                                    }}
                                                                >
                                                                    <ResetIcon size={12} color="white" marginRight={5} /> Restaurar
                                                                </Button>
                                                            </Tooltip>
                                                            :
                                                            <Button
                                                                onClick={() => handleRemoveAndRestore(venda.processo_id, "Restore")}
                                                                appearance="primary"
                                                            >
                                                                <ResetIcon size={12} color="white" marginRight={5} /> Restaurar
                                                            </Button>

                                                        }
                                                    </Table.TextCell>
                                                }
                                            </Table.Row>
                                        )}
                                    </div>

                                    :
                                    // Se não tiver nenhum documento exibe esse bloco
                                    loading === true &&
                                    <Table.Row key={1}>
                                        <div className="mensagem-processo">
                                            Você não possui nenhuma venda na lixeira.
                                        </div>
                                    </Table.Row>
                            }
                        </Table.Body>
                }
            </Table.Body>

            <SideSheetRecibo />

            <DialogRemover recarregarVendas={recarregarVendas} />
            <DialogRestore recarregarVendas={recarregarVendas} />
            <Dialog
                width={592}
                isShown={isShowEntregarVenda}
                title=""
                onCloseComplete={() => setIsShowEntregarVenda(false)}
                confirmLabel=""
                containerProps={{
                    className: 'form-dialog-props'
                }}
                hasFooter={false}
                preventBodyScrolling={true}
                shouldCloseOnOverlayClick={false}
            >
                <Pane>
                    <div className="form-dialog">
                        <h4 style={{ marginTop: '15px' }}>
                            {dadosVenda.status_processo === 22 ? 'A venda devolvida foi revisada?' : 'Tem certeza que deseja prosseguir?'}
                        </h4>
                        <p className="descritption" style={{ marginTop: '15px', lineHeight: '22px' }}>
                            {dadosVenda.status_processo === 22
                                ? 'Entregue a venda somente se as correções solicitadas pela Equipe de pós-venda tenham sido realizadas. Caso contrário, o pós-venda poderá devolver a venda novamente. Atenção: Os prazos de vendas devolvidas só voltam ao normal, após as correções solicitadas. '
                                : 'Caso tenha certeza que tudo foi preenchido corretamente, clique em Entregar venda. Depois do envio para o Backoffice, as informações não poderão sofrer alterações. Revise atentamente.'}
                        </p>
                    </div>

                    <div className="footer-form" style={{ marginBottom: '25px' }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button appearance="default" style={{ marginRight: '10px' }} onClick={(e) => setIsShowEntregarVenda(false)}>
                                Fechar
                            </Button>

                            <Button
                                marginY={0}
                                marginRight={12}
                                onClick={(e) => confirmarEntregaVenda(e, dadosVenda)}
                                className="btn-enabled-entregar-venda"
                                disabled={false}
                            >
                                <ShareIcon
                                    color="white"
                                    style={{ width: 12, height: 12, marginRight: 8 }} /
                                >
                                Entregar venda
                            </Button>
                        </div>
                    </div>
                </Pane>
            </Dialog>
            {/*<CornerDialog/>*/}
        </Pane>
    )
}