import React, {useContext, useState} from 'react';
import { Pane, Button, PlusIcon, Dialog, ChevronRightIcon } from 'evergreen-ui';

//Context
import PosNegociacaoContext from '../../../../context/PosNegociacaoContext';

import '../../../../index.css';

export default function SelecionarForm() {

    const {
        setDadosRecibo,
        setEstadoRecibo,
        setValidateFieldRgi,
        setValidateFieldCep,
        setValidateFieldDataAssinatura,
        setValidateFieldFormaPagamento,
        setValidateInputFile,
        setValidateCepMessage,
        setValidateAdress,
        setCheckedFgts,
        setCheckedAVista,
        setCheckedFinanciamento,
        setValidateFieldCodImovel,
        setValidateFieldValorVenda,
        setValidateFieldDataVenda,
        setIsShownFormRecibo,
        setIsShowFormEspelhoVenda,
        isShownTipoVenda, setIsShownTipoVenda,
    } = useContext(PosNegociacaoContext)

    const dialogTipoVenda = async (e) => {
        setIsShownTipoVenda(true);
    }

    const [tipoVenda, setTipoVenda] = useState('');

    const definirFormVenda = async (e) => {
        const button = e.target.getAttribute('data-button');
        console.log(button);

        if (button === '1') {
            setTipoVenda('1');
        }
        else {
            setTipoVenda('2');
        }
    }

    const formRecibo = async (e) => {
        setDadosRecibo([]);
        setEstadoRecibo('cadastrar');
        setValidateFieldRgi(false);
        setValidateFieldCep(false);
        setValidateFieldDataAssinatura(false);
        setValidateFieldFormaPagamento(false);
        setValidateInputFile(false);
        setValidateCepMessage(true);
        setValidateAdress(false);
        setCheckedAVista(false);
        setCheckedFinanciamento(false);
        setCheckedFgts(false);

        setIsShownFormRecibo(true);
        setIsShownTipoVenda(false);
    }

    const formEspelho = async (e) => {
        setDadosRecibo([]);
        setValidateFieldCep(false);
        setValidateCepMessage(true);
        setValidateAdress(false);
        setValidateFieldCodImovel(false);
        setValidateFieldValorVenda(false);
        setValidateFieldDataVenda(false);
        //setValidateFieldUnidade(false);
        //setValidateFieldNumero(false);
        setValidateFieldFormaPagamento(false);
        setValidateInputFile(false)
        setCheckedAVista(false);
        setCheckedFinanciamento(false);
        setCheckedFgts(false);

        setCheckedAVista(false);
        setCheckedFinanciamento(false);
        setCheckedFgts(false);

        setIsShownTipoVenda(false);
        setIsShowFormEspelhoVenda(true);
    }

    const closeTipoVenda = async (e) =>{
        setTipoVenda('');
        setIsShownTipoVenda(false);
    }

    return (
        <Pane>
            <Button marginY={8} marginRight={12} appearance="primary" onClick={(e) => dialogTipoVenda(e)}>
                <PlusIcon
                    color="white"
                    style={{ width: 11, height: 11, marginRight: 10 }} /> Adicionar venda
            </Button>

            <Dialog
                isShown={isShownTipoVenda}
                title="Parabéns pela venda! :)"
                onCloseComplete={(e) => closeTipoVenda(e)}
                hasFooter={false}
                containerProps={{
                    className: "dialog-confirm",
                }}
                width={590}
            >
                <div className="content-dialog">
                    Cadastre o novo processo de venda como Recibo de Sinal ou
                    Espelho da Venda. Selecione a opção abaixo:
                </div>

                <div className="dialog-3-buttons">
                    <div>
                        <Button
                            marginLeft={0}
                            marginTop={25}
                            data-button='1'
                            onClick={(e) => definirFormVenda(e)}
                            appearance={tipoVenda === '1' ? 'primary' : 'default'}
                            className={tipoVenda === '' ? "default-button" : ''}
                        >
                            Recibo de sinal
                        </Button>

                        <Button
                            marginLeft={12}
                            marginTop={25}
                            data-button='2'
                            onClick={(e) => definirFormVenda(e)}
                            appearance={tipoVenda === '2' ? 'primary' : 'default'}
                            className={tipoVenda === '' ? "default-button" : ''}
                        >
                            Espelho da venda
                        </Button>
                    </div>

                    <div>
                        <Button
                            marginLeft={12}
                            marginTop={25}
                            appearance="primary"
                            onClick={(e) => {tipoVenda === '1' ? formRecibo(e) : formEspelho(e) }}
                            disabled={tipoVenda !== '' ? false : true}
                        >
                            Próximo
                            <ChevronRightIcon className="icon-primary-button" />
                        </Button>
                    </div>
                </div>
            </Dialog>
        </Pane>
    )
}