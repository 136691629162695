import Axios from 'axios';
import Serve from '../app/serve';

async function listEnderecos(tipo_listagem, usuario_id) {
  const app = Serve();
  let data;
  // tipo_listagem = "entregues" | "rascunhos"

  await Axios.post(app + 'lista_enderecos_safebox ', {
    tipo_listagem,
    usuario_id,
  }, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
  })
    .then(res => {
      if (res?.data.status && (res.data.status === 498 || res.data.status === 401)) {
        localStorage.clear();
      } else {
        // CASO PRECISE MUDAR O FILTRO PARA MOSTRAR NUMERO + UNIDADE E FILTRAR PELO ID
        // data = res.data.map((processo) => ({ 
        //   id: processo.imovel_id, 
        //   endereco: `${processo.logradouro}, ${processo.numero}${processo.unidade ? ", " + processo.unidade : ""}`
        // }));

        const enderecoNaoRepetidos = [...new Set(res.data.map(processo => processo.logradouro))];
        data = enderecoNaoRepetidos.map(processo => ({ endereco: processo }));
      }
    })
  return data;
  // return { endereco: String }
}

export default listEnderecos;