import React, { useContext, useState, useEffect } from 'react';
import { Pane, Avatar, Text, AddIcon, Tab, LogOutIcon } from 'evergreen-ui';
import PainelAdminContext from '../../../context/PainelAdminContext'
import imageAvatar from '../../../images/avatar_painelAdmin.jpg'
import Lottie from 'react-lottie';
import animationData from '../../../images/Animations/page-construction.json';

export default function Administradores({userLoading}) {  
  const { setShowAddUser, setUserSelected, users, loading, adminUsers } = useContext(PainelAdminContext);

  useEffect(() => {
    userLoading();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Pane className='painel ml202'>
      <div className='animationConstruction'>
        <Lottie options={defaultOptions} />
      </div>
      <div className='flex-center'>
        <Avatar src={imageAvatar} name="Safemores" size={80} marginRight={20} />
        <h1>Administradores da SafeBox</h1>
      </div>      
      <br />
      <Text>Em construção</Text>
      
      {/* {loading ? <div className='mt30vh'><Lottie options={defaultOptions} height={80} width={200} /></div> :
      <Pane className='flex-center vw80'>
        {adminUsers.map((user) => (
          <Pane className='userCard' onClick={() => cardUser(user)}>
            <Avatar src={user.avatar} name={user.name} size={60} /><br />
            <Text className='title' maxWidth={150}>{user.name}</Text><br />
            <Text>{user.cargo}</Text>
          </Pane>
        ))}
      </Pane>} */}
    </Pane>
  )
}