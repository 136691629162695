const formatoMoeda = v => {
    if(!v){
        return ''
    }    
    v = v.replace(/\D/g, '');
    v = (v / 100).toFixed(2) + '';
    v = v.replace(".", ",");
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
    v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
    
    if (v > '0,00') {
        return 'R$ ' + v;    
    }
    else {
        return '';
    }
    
}

export default formatoMoeda;