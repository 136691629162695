import React, { useContext, useState } from 'react';
import { Pane, Table, Heading } from 'evergreen-ui';
import FinanceiroContext from '../../../../context/FinanceiroContext';
import phoneMask from '../../../../components/phoneMask';

export default function Gerente() {
  const {
    processSelect,
  } = useContext(FinanceiroContext);
  const [gerenteProcesso] = useState(processSelect.gerente.data[0]);

  return (
    <Pane>
      <Heading size={600} className="mb10">
        Dados do Gerente
      </Heading>
      <Pane>
        <Table.Row>
          <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">CPF</span><br />
            {gerenteProcesso.cpf_cnpj || '---'}
          </Table.TextCell>
          <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0} className='wsNormal'>
            <span className="fontStatus">Telefone/Celular</span><br />
            {phoneMask(gerenteProcesso.telefone) || '---'}
          </Table.TextCell>
        </Table.Row>

        <Table.Row>
          <Table.TextCell flexBasis={600} flexShrink={0} flexGrow={0}>
            <span className="fontStatus">Nome completo</span><br />
            {gerenteProcesso.name || '---'}
          </Table.TextCell>
        </Table.Row>

        <Table.Row>
          <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0}>
            <span className="fontStatus">Data de nascimento</span><br />
            {gerenteProcesso.data_nascimento || '---'}
          </Table.TextCell>
          <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0}>
            <span className="fontStatus">Nacionalidade</span><br />
            {gerenteProcesso.nacionalidade || '---'}
          </Table.TextCell>
        </Table.Row>

        <Table.Row>
          <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0}>
            <span className="fontStatus">Estado Civil</span><br />
            {gerenteProcesso.estado_civil || '---'}
          </Table.TextCell>
          <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0}>
            <span className="fontStatus">Profissão</span><br />
            {gerenteProcesso.profissao || '---'}
          </Table.TextCell>
          <Table.TextCell flexBasis={250} flexShrink={0} flexGrow={0}>
            <span className="fontStatus">R.G</span><br />
            {gerenteProcesso.rg || '---'}
          </Table.TextCell>
        </Table.Row>

        <Table.Row>
          <Table.TextCell flexBasis={600} flexShrink={0} flexGrow={0}>
            <span className="fontStatus">E-mail</span><br />
            {gerenteProcesso.email || '---'}
          </Table.TextCell>
        </Table.Row>
      </Pane>

    </Pane>
  )
}