import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Pane, Tab, Tablist } from 'evergreen-ui';
import Navbar from '../../components/Navbar';
import TablePanel from './component/TablePanel';
import Dashboard from './component/Dashboard';
import ChangeLog from '../components/ChangeLog';
import DirecaoContext from '../../context/DirecaoContext';
import getGerenteList from '../../apis/getGerenteList';
import getResponsaveis from '../../apis/getResponsaveis';
import getLojas from '../../apis/getLojas';
import getProcessosGerente from '../../apis/getProcessosGerente';
import animationData from '../../images/Animations/loadingDocs.json';
import getStatusList from '../../apis/getStatusListProcessos';
import getListEnderecos from '../../apis/getListEnderecos';
import getLaudemiosList from '../../apis/getLaudemiosList';

export default function Comercial() {
    const history = useHistory();
    const [loading, setLoading] = useState({
        rascunho: true,
        entregues: true
    });
    const [perfil_login] = useState(localStorage.getItem('perfil_login'));
    const [usuario_id] = useState(localStorage.getItem('usuario_id'));

    if (perfil_login !== "Diretor Comercial") {
        history.push('/');
    };

    // PROCESSO
    const [processoId, setProcessoId] = useState("");

    // SIDESHEET
    const [isShowSide, setIsShowSide] = useState(false);
    const [dadosVenda, setDadosVenda] = useState({});

    const [selectedIndexEnviados, setSelectedIndexEnviados] = useState(0);
    const [selectedIndexRascunho, setSelectedIndexRascunho] = useState(0);
    const [selectedIndexBoletimDeVenda, setSelectedIndexBoletimDeVenda] = useState(0);
    const [showTabsDetalhes, setShowTabsDetalhes] = useState([true, false, false, false]);
    const [showTabsRascunho, setShowTabsRascunho] = useState([true, false, false]);
    const [showTabsBoletim, setShowTabsBoletim] = useState([true, false, false, false]);

    // LISTA SIDE
    const [tipoLaudemio, setTipoLaudemio] = useState([]);

    // TABS
    const [tabs] = useState(["Gestão dos processos", "Processos da equipe"]);
    const [selectTab, setSelectTab] = useState(0);

    // FILTROS
    const [filtro] = useState({
        'endereco_rascunho': JSON.parse(localStorage.getItem('filtro_endereco_rascunho')) || '',
        'gerente_rascunho': JSON.parse(localStorage.getItem('filtro_gerente_rascunho')) || '',
        'loja_rascunho': JSON.parse(localStorage.getItem('filtro_loja_rascunho')) || '',
        'mes_cadastro_rascunho': JSON.parse(localStorage.getItem('filtro_mes_cadastro_rascunho')) || '',

        'endereco_entregue': JSON.parse(localStorage.getItem('filtro_endereco_entregue')) || '',
        'gerente_entregue': JSON.parse(localStorage.getItem('filtro_gerente_entregue')) || '',
        'loja_entregue': JSON.parse(localStorage.getItem('filtro_loja_entregue')) || '',
        'status': Number(localStorage.getItem('filtro_status')) || '',
        'responsavel': Number(localStorage.getItem('filtro_responsavel')) || '',
    });
    const [quantFiltro, setQuantFiltro] = useState({ rascunho: 0, entregues: 0 });

    // LISTA SELECT FILTROS
    const [listGerentes, setListGerentes] = useState([]);
    const [listStatus, setListStatus] = useState([]);
    const [listResponsaveis, setListResponsaveis] = useState([]);
    const [listLojas, setListLojas] = useState([]);
    const [listEnderecos, setListEnderecos] = useState({
        rascunhos: [],
        entregues: []
    });

    // TABLE
    const [processosEntregues, setProcessosEntregues] = useState({
        items: [],
        page: 1,
        totalItems: 0,
        totalPaginas: 0,
        totalItemsFilter: 0,
    });
    const [processosRascunho, setProcessosRascunho] = useState({
        items: [],
        page: 1,
        totalItems: 0,
        totalPaginas: 0,
        totalItemsFilter: 0,
    });

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const getSelects = async () => {
        const gerentes = await getGerenteList();
        setListGerentes(gerentes);

        const responsaveis = await getResponsaveis();
        setListResponsaveis(responsaveis);

        const lojas = await getLojas();
        setListLojas(lojas);

        const status = await getStatusList();
        setListStatus(status);

        const entregues = await getListEnderecos('entregues', usuario_id);
        const rascunhos = await getListEnderecos('rascunhos', usuario_id);
        setListEnderecos({ entregues: entregues, rascunhos: rascunhos });

        await getProcessosRascunho(1);        
        await getProcessosEntregues(1);

        const laudemios = await getLaudemiosList();
        setTipoLaudemio(laudemios);
        console.log(tipoLaudemio);
    };

    useEffect(() => {
        getSelects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getProcessosEntregues = async (page) => {
        setLoading({rascunho: false, entregues: true});
        const entregues = await getProcessosGerente(filtro, page, "entregues");
        if (entregues) {
            setProcessosEntregues({
                items: entregues.processos.data,
                page: page,
                totalItems: entregues.total_processos,
                totalPaginas: entregues.total_pagina,
                totalItemsFilter: entregues.total_processos_filtrados,
            });
            quantidadeFiltros();
        } else {

        }
        setLoading({rascunho: false, entregues: false});
    };

    const getProcessosRascunho = async (page) => {
        setLoading({...loading, rascunho: true});
        const rascunhos = await getProcessosGerente(filtro, page, "adicionados");
        if (rascunhos) {
            setProcessosRascunho({
                items: rascunhos.processos.data,
                page: page,
                totalItems: rascunhos.total_processos,
                totalPaginas: rascunhos.total_pagina,
                totalItemsFilter: rascunhos.total_processos_filtrados,
            });
            quantidadeFiltros();
        } else {

        }
        setLoading({...loading, rascunho: false});
    };

    const quantidadeFiltros = async () => {
        let rascunho = 0;
        if (filtro.endereco_rascunho?.endereco) rascunho++;
        if (filtro.gerente_rascunho) rascunho++
        if (filtro.loja_rascunho) rascunho++
        if (filtro.mes_cadastro_rascunho) rascunho++
        quantFiltro.rascunho = rascunho;

        let entregue = 0;
        if (filtro.endereco_entregue?.endereco) entregue++;
        if (filtro.gerente_entregue) entregue++
        if (filtro.loja_entregue) entregue++
        if (filtro.status) entregue++
        if (filtro.responsavel) entregue++
        quantFiltro.entregues = entregue;

        setQuantFiltro({ ...quantFiltro });
    };

    const handleClearFilter = async (type) => {
        if (type === 'rascunho') {
            filtro.endereco_rascunho = '';
            filtro.gerente_rascunho = '';
            filtro.loja_rascunho = '';
            filtro.mes_cadastro_rascunho = '';
            localStorage.removeItem('filtro_endereco_rascunho');
            localStorage.removeItem('filtro_gerente_rascunho');
            localStorage.removeItem('filtro_loja_rascunho');
            localStorage.removeItem('filtro_mes_cadastro_rascunho');
            getProcessosRascunho(1);
        } else {
            filtro.endereco_entregue = '';
            filtro.gerente_entregue = '';
            filtro.loja_entregue = '';
            filtro.status = '';
            filtro.responsavel = '';
            localStorage.removeItem('filtro_endereco_entregue');
            localStorage.removeItem('filtro_gerente_entregue');
            localStorage.removeItem('filtro_loja_entregue');
            localStorage.removeItem('filtro_status');
            localStorage.removeItem('filtro_responsavel');
            getProcessosEntregues(1);
        }
    };

    const contextData = {
        // LOADING
        loading,
        setLoading,
        defaultOptions,

        usuario_id,
        processoId,
        setProcessoId,

        //SIDESHEET
        isShowSide,
        setIsShowSide,
        dadosVenda, setDadosVenda,
        tipoLaudemio,
        selectedIndexRascunho,
        selectedIndexBoletimDeVenda,

        // dadosReciboEnviado,
        selectedIndexEnviados, setSelectedIndexEnviados,
        showTabsDetalhes, setShowTabsDetalhes,
        showTabsRascunho,
        setShowTabsRascunho,
        showTabsBoletim,
        setShowTabsBoletim,
        setSelectedIndexRascunho,
        setSelectedIndexBoletimDeVenda,

        // FILTROS
        filtro,
        quantFiltro,

        // LISTAS SELECT
        listGerentes,
        listLojas,
        listStatus,
        listResponsaveis,
        listEnderecos,

        // ITEMS TABLE
        processosRascunho,
        processosEntregues,

        // REFRESH TABLE ITEMS
        getProcessosRascunho,
        getProcessosEntregues,
        handleClearFilter,
    };

    return (

        <Pane>
            <DirecaoContext.Provider value={contextData}>
                <Navbar Context={DirecaoContext} />
                <div className="painel mt68">
                    <Tablist marginBottom={30}>
                        {tabs.map((tab, index) => (
                            <Tab
                                key={tab}
                                id={tab}
                                onSelect={() => setSelectTab(index)}
                                isSelected={index === selectTab}
                                aria-controls={`panel-${tab}`}
                                appearance="primary"
                                height={44}
                                size={14}
                            >
                                {tab}
                            </Tab>
                        ))}
                    </Tablist>
                    {selectTab === 0 &&  <Dashboard />}
                    {selectTab === 1 &&  <TablePanel />}
                   
                </div>
                <ChangeLog data="Vendas" />
            </DirecaoContext.Provider>
        </Pane>
    )
}