import React, { useState, useContext, useEffect } from 'react';
import { Pane, TextInputField, Button, Textarea, TickIcon, SelectField, RadioGroup, ErrorIcon, Autocomplete, Badge, EditIcon, Label, ArrowRightIcon, TickCircleIcon, PlusIcon, CrossIcon, toaster } from 'evergreen-ui';
import ApoioContext from '../../../../context/ApoioContext';
import { useForm } from 'react-hook-form';
import formatoMoeda from '../../../../components/formatoMoeda';
import Axios from 'axios';
import Serve from '../../../../app/serve';
import ShowDocument from '../../../../app/showDocument';
import { useHistory } from 'react-router-dom';
import validarCNPJ from '../../../../components/validarCNPJ';
import validarCPF from '../../../../components/validarCPF';
import cpfMask from '../../../../components/cpfMask';
import cnpjMask from '../../../../components/cnpjMask';
import PlanilhaDeComissao from './components/PlanilhaDeComissao/VisualizarPlanilha';

export default function Comissao({ refreshProcessoSideSheet }) {
  const [token] = useState(localStorage.getItem('token'));
  const gerente_id = localStorage.getItem('usuario_id')
  const app = Serve();
  const history = useHistory();

  const {
    selectProcess,
    loading, setLoading,
    listOpcionistas,
    listBank,
    listaPeriodo,
    setSelectProcess,
    setIsShownPlanilha,
    listEmpresas,
    baneshBank,
  } = useContext(ApoioContext);

  console.log(selectProcess);

  const comissao = selectProcess.imovel.comissao;
  const comissao_gerente_gg = selectProcess.imovel;
  const diretorData = selectProcess.imovel.dados_diretor_comercial;
  const empresa = selectProcess.imovel.comissao_empresas;
  const repasseFranquia = selectProcess.imovel.dados_repasse;
  const royaltiesData = selectProcess.imovel?.dados_royalties || baneshBank.find((conta) => conta.tipo === "royalties");
  const comunicacaoData = selectProcess.imovel?.dados_comunicacao || baneshBank.find((conta) => conta.tipo === "comunicacao");
  const { planilha_antiga } = selectProcess?.imovel;
  const planilha = planilha_antiga ? comissao?.documentos?.data.filter((documento) => documento.identifica_documento === "comissão") : [];
  const msgError = "Algo não saiu como esperado. Tente novamente mais tarde.";
  const parcela = { 'id': '', 'valor': '', 'periodo': '' };
  const inicialDataParcela = comissao?.parcelas_empresa.length > 0 ? comissao.parcelas_empresa.map((parcela) => ({
    id: parcela.id,
    valor: parcela.valor_parcela,
    periodo: parcela.periodo_pagamento,
  })) : [parcela];

  //CSS inputs
  const inputGreen = {
    borderColor: '#429777',
    background: '#F5FBF8'
  };

  const inputDefault = {
    borderColor: '#ADC2FF',
    background: '#fff'
  };

  const inputError = {
    borderColor: '#FF0000',
    background: '#fff'
  };

  const [optionsRadio] = useState([
    { label: 'Integral', value: 'integral' },
    { label: 'Parcelada', value: 'partes' }
  ]);

  const [optionsRadioLiquida] = useState([
    { label: 'Espécie', value: 'especie' },
    { label: 'Depósito', value: 'deposito' },
    { label: 'TED/DOC', value: 'ted_doc' },
    { label: 'Pix', value: 'pix' },
    { label: 'Cheque/Cheque Administrativo', value: 'cheque' }
  ]);

  const [optionsFranquia] = useState([
    { label: "Sim", value: 'true' },
    { label: "Não", value: 'false' }
  ]);

  const [dadosBancarios] = useState([
    { label: "Pix", value: 'pix' },
    { label: "Banco", value: 'banco' }
  ]);

  const laudoOptions = [
    { label: "Simples", value: "simples", porcentagem: 16 },
    { label: "Com chave", value: "com_chave", porcentagem: 18 },
    { label: "Exclusividade", value: "exclusividade", porcentagem: 20 },
    { label: "Lançamentos", value: "lançamento", porcentagem: 8 },
  ];

  const [optionsTipoPessoa] = React.useState([
    { label: 'Física', value: 'PF' },
    { label: 'Juridica', value: 'PJ' },
  ]);

  const percentualOpcionistas = (e) => {
    switch (e) {
      case "simples":
        return "16%";
      case "com_chave":
        return "18%";
      case "exclusividade":
        return "20%";
      case "lançamento":
        return "8%";
      default:
        return "";
    }
  };

  const { register, unregister, handleSubmit, clearErrors, setValue, watch, setError, formState: { errors } } = useForm({
    defaultValues: {
      radioTipoComissao: comissao?.comissao || "integral",
      valor_comissao_total: comissao?.valor_comissao_total || "",
      valor_parte_um: comissao?.valor_parte_um || "",
      valor_parte_dois: comissao?.valor_parte_dois || "",
      data_motivo: comissao?.data_motivo || '',
      deducao: comissao?.deducao || '',
      liquida: comissao?.liquida || '',
      valor_comissao_liquida: comissao?.valor_comissao_liquida || '',
      observacoes: comissao?.observacoes || '',
      comissao_id: comissao?.id || '',

      parcelas: inicialDataParcela,

      // CAMPOS DE FRANQUIA
      radioFranquiaRepasse: (repasseFranquia && comissao?.verificar_repasse === 'true') ? 'true' : 'false',
      franquia: {
        empresa: (
          empresa.map(empresas => ({
            agencia: empresas.agencia || '',
            banco_id: empresas.banco_id || '',
            cnpj: empresas.cnpj || '',
            creci: empresas.creci || '',
            desconto: empresas?.desconto ? ("R$ " + empresas.desconto) : '',
            id: empresas.empresa_id || '',
            nomeEmpresarial: empresas.nome_empresarial || '',
            conta: empresas.numero_conta || '',
            chavePix: empresas.pix || '',
            porcentagem: Number(empresas?.porcentagem_real) || '',
            relacaoEmpresaComissao: empresas.relacao_empresa_comissao_id || '',
            dadosBancarios: empresas.tipo_pagamento || '',
          })
          )
        ),
        repasseFranquia: {
          id: repasseFranquia?.id || '',
          empresa_id: repasseFranquia?.empresa_resse_id || '',
          nomeEmpresarial: repasseFranquia?.nome_empresarial || '',
          cnpj: repasseFranquia?.empresa_cnpj || '',
          creci: repasseFranquia?.creci_empresa || '',
          porcentagem: Number(repasseFranquia?.porcentagem) || 2.0,
          dadosBancarios: repasseFranquia?.tipo_pagamento || 'banco',
          chavePix: repasseFranquia?.pix || '',
          banco_id: repasseFranquia?.banco_id || '',
          agencia: repasseFranquia?.agencia || '',
          conta: repasseFranquia?.numero_conta || ''
        },
        royalties: {
          porcentagem: royaltiesData?.porcentagem ? Number(royaltiesData.porcentagem) : 7.0,
          desconto: royaltiesData?.desconto ? ("R$ " + royaltiesData.desconto) : '',
          dadosBancarios: royaltiesData?.tipo_pagamento || 'banco',
          chavePix: royaltiesData?.pix || "",
          banco_id: royaltiesData?.banco_id || "",
          agencia: royaltiesData?.agencia || "",
          conta: royaltiesData?.numero_conta || "",
        },
        comunicacao: {
          porcentagem: comunicacaoData?.porcentagem ? Number(comunicacaoData.porcentagem) : 3.0,
          desconto: comunicacaoData?.desconto ? ("R$ " + comunicacaoData.desconto) : '',
          dadosBancarios: comunicacaoData?.tipo_pagamento || 'banco',
          chavePix: comunicacaoData?.pix || "",
          banco_id: comunicacaoData?.banco_id || "",
          agencia: comunicacaoData?.agencia || "",
          conta: comunicacaoData?.numero_conta || ""
        },
      },

      // CAMPOS DE GERENTES, GG, VENDEDORES, E OPCIONISTAS
      percentual: {
        comissaoTotal: comissao?.corretores_vendedores_comissao[0]?.total_comissao_corretor || 20,
        comissaoTotalGerenteGeral: comissao.valor_comissao_gg || 2.5,
        comissaoTotalGerente: comissao.valor_comissao_gerente || 10,
        gerentesGerais: comissao_gerente_gg.loja.gerente_geral_id !== null
          ?
          comissao_gerente_gg.comissao_gerente_gerais.length === 0
            ? ([{
              id: "",
              userId: "",
              tipoPessoa: "PF",
              nome: "",
              nomeEmpresarial: "",
              cpf: "",
              cnpj: "",
              porcentagemComissao: '',
              desconto: formatoMoeda('0.00'),
              valorReal: '',
              dadosBancarios: 'pix',
              chavePix: '',
              banco_id: '',
              agencia: '',
              conta: ''
            }])
            : comissao_gerente_gg.comissao_gerente_gerais.map((gerenteGeral) => ({
              id: gerenteGeral.id || "",
              userId: gerenteGeral.usuario_id || "",
              tipoPessoa: gerenteGeral.tipo_pessoa || 'PF',
              nome: gerenteGeral.name || "",
              nomeEmpresarial: gerenteGeral.nome_empresarial || "",
              cpf: gerenteGeral.cpf || "",
              cnpj: gerenteGeral.cnpj || "",
              porcentagemComissao: gerenteGeral.porcentagem_real || '',
              desconto: gerenteGeral.desconto ? "R$ " + gerenteGeral.desconto : "",
              valorReal: gerenteGeral.valor_real || "",
              dadosBancarios: gerenteGeral.tipo_pagamento || 'pix',
              chavePix: gerenteGeral.pix || '',
              banco_id: gerenteGeral.banco_id || '',
              agencia: gerenteGeral.agencia || '',
              conta: gerenteGeral.numero_conta || ''
            }))
          :
          comissao_gerente_gg.loja.gerente_geral_id === null
            ? comissao_gerente_gg.comissao_gerente_gerais.map((gerenteGeral) => ({
              id: gerenteGeral.id || "",
              userId: gerenteGeral.usuario_id || "",
              tipoPessoa: gerenteGeral.tipo_pessoa || 'PF',
              nome: gerenteGeral.name || "",
              nomeEmpresarial: gerenteGeral.nome_empresarial || "",
              cpf: gerenteGeral.cpf || "",
              cnpj: gerenteGeral.cnpj || "",
              porcentagemComissao: gerenteGeral.porcentagem_real || '',
              desconto: gerenteGeral.desconto ? "R$ " + gerenteGeral.desconto : "",
              valorReal: gerenteGeral.valor_real || "",
              dadosBancarios: gerenteGeral.tipo_pagamento || 'pix',
              chavePix: gerenteGeral.pix || '',
              banco_id: gerenteGeral.banco_id || '',
              agencia: gerenteGeral.agencia || '',
              conta: gerenteGeral.numero_conta || ''
            }))
            : ([]),
        gerentes: comissao_gerente_gg.comissao_gerentes.length === 0
          ? ([{
            id: "",
            userId: "",
            tipoPessoa: "PF",
            nome: "",
            nomeEmpresarial: "",
            cpf: "",
            cnpj: "",
            porcentagemComissao: '',
            desconto: formatoMoeda('0.00'),
            valorReal: "",
            dadosBancarios: 'pix',
            chavePix: '',
            banco_id: '',
            agencia: '',
            conta: ''
          }])
          : comissao_gerente_gg.comissao_gerentes.map((gerente) => ({
            id: gerente.id || "",
            userId: gerente.usuario_id || "",
            tipoPessoa: gerente.tipo_pessoa || 'PF',
            nome: gerente.name || "",
            nomeEmpresarial: gerente.nome_empresarial || "",
            cpf: gerente.cpf || "",
            cnpj: gerente.cnpj || "",
            porcentagemComissao: gerente.porcentagem_real || '',
            desconto: gerente.desconto ? "R$ " + gerente.desconto : "",
            valorReal: gerente.valor_real || "",
            dadosBancarios: gerente.tipo_pagamento || 'pix',
            chavePix: gerente.pix || '',
            banco_id: gerente.banco_id || '',
            agencia: gerente.agencia || '',
            conta: gerente.numero_conta || ''
          })),
        corretores: comissao === null || comissao.corretores_vendedores_comissao.length === 0
          ? ([{
            id: "",
            userId: "",
            tipoPessoa: "PF",
            nome: "",
            nomeEmpresarial: "",
            cpf: "",
            cnpj: "",
            porcentagemComissao: '',
            desconto: formatoMoeda('0.00'),
            valorReal: "",
            dadosBancarios: 'pix',
            chavePix: '',
            banco_id: '',
            agencia: '',
            conta: ''
          }])
          : comissao.corretores_vendedores_comissao.map((corretor) => ({
            id: corretor.id || "",
            userId: corretor.usuario_id || "",
            tipoPessoa: corretor.tipo_pessoa || 'PF',
            nome: corretor.name || "",
            nomeEmpresarial: corretor.nome_empresarial || "",
            cpf: corretor.cpf || "",
            cnpj: corretor.cnpj || "",
            porcentagemComissao: corretor.porcentagem_real || '',
            desconto: corretor.desconto ? "R$ " + corretor.desconto : "",
            valorReal: corretor.valor_real || "",
            dadosBancarios: corretor.tipo_pagamento || 'pix',
            chavePix: corretor.pix || '',
            banco_id: corretor.banco_id || '',
            agencia: corretor.agencia || '',
            conta: corretor.numero_conta || ''
          })),
        laudoType: comissao?.corretores_opicionistas_comissao[0]?.tipo_laudo_opcionista || "simples",
        opcionistas: comissao === null || comissao.corretores_opicionistas_comissao.length === 0
          ? ([{
            id: "",
            userId: "",
            tipoPessoa: "PF",
            nome: "",
            nomeEmpresarial: "",
            cpf: "",
            cnpj: "",
            porcentagemComissao: '',
            desconto: formatoMoeda('0.00'),
            valorReal: "",
            dadosBancarios: 'pix',
            chavePix: '',
            banco_id: '',
            agencia: '',
            conta: ''
          }])
          : comissao.corretores_opicionistas_comissao.map((opcionista) => ({
            id: opcionista.id || "",
            userId: opcionista.usuario_id || "",
            tipoPessoa: opcionista.tipo_pessoa || 'PF',
            nome: opcionista.name || "",
            nomeEmpresarial: opcionista.nome_empresarial || "",
            cpf: opcionista.cpf || "",
            cnpj: opcionista.cnpj || "",
            porcentagemComissao: opcionista.porcentagem_real || '',
            desconto: opcionista.desconto ? "R$ " + opcionista.desconto : "",
            valorReal: opcionista.valor_real || "",
            dadosBancarios: opcionista.tipo_pagamento || 'pix',
            chavePix: opcionista.pix || '',
            banco_id: opcionista.banco_id || '',
            agencia: opcionista.agencia || '',
            conta: opcionista.numero_conta || ''
          })),
        diretor_comercial: {
          userId: diretorData?.usuario_id || "",
          nome: diretorData?.name || "",
          cnpj: diretorData?.cpf_cnpj || "",
          porcentagemComissao: Number(diretorData?.porcetagem_comissao) || "",
          desconto: diretorData?.desconto || "",
          valorReal: diretorData?.valor_real ? "R$ " + diretorData.valor_real.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : "",
          dadosBancarios: diretorData?.tipo_pagamento || 'pix',
          chavePix: diretorData?.pix || '',
          banco_id: diretorData?.banco_id || '',
          agencia: diretorData?.agencia || '',
          conta: diretorData?.numero_conta || '',
          creci: diretorData?.creci
        }
      }
    }
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    calcularTotalUsuarios();
    calcularTotalEmpresas();
    calcularTotalEmpresa();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // CALCULO DE VALORES PARA OS CAMPOS OBRIGATORIOS (SEM DIVISAO PARA CADA)
  const comissaoLiquida = watch("valor_comissao_liquida").replace(/[R$.]+/g, '');
  
  //Calcula porcentagem da primeira empresa
  const calcularTotalEmpresa = () => {
    const porcentagem = {
      repasse: watch('radioFranquiaRepasse') === 'true' ? Number(watch('franquia.repasseFranquia.porcentagem')) : 0,
      royalties: Number(watch('franquia.royalties.porcentagem')) || 0,
      comunicacao: Number(watch('franquia.comunicacao.porcentagem')) || 0,
      gerentesGerais: watch('percentual.gerentesGerais').map((gg) => Number(gg.porcentagemComissao)) || 0,
      gerentes: watch('percentual.gerentes').map((gerente) => Number(gerente.porcentagemComissao)) || 0,
      corretores: watch('percentual.corretores').map((corretor) => Number(corretor.porcentagemComissao)) || 0,
      opcionistas: watch('percentual.opcionistas').map((opcionista) => Number(opcionista.porcentagemComissao)) || 0,
      diretor_comercial: franquia.empresa.some((empresa) => empresa.nomeEmpresarial.includes("DNA Imóveis")) ? Number(watch('percentual.diretor_comercial.porcentagemComissao')) : 0,
      empresas: watch('franquia.empresa').length > 0 ? watch('franquia.empresa').map((empresa, index) => index >= 1 ? Number(empresa.porcentagem) : 0) : [0],
    };
    const total = porcentagem.repasse +
      porcentagem.royalties +
      porcentagem.comunicacao +
      porcentagem.gerentesGerais.reduce((e, x) => e + x, 0) +
      porcentagem.gerentes.reduce((e, x) => e + x, 0) +
      porcentagem.corretores.reduce((e, x) => e + x, 0) +
      porcentagem.opcionistas.reduce((e, x) => e + x, 0) +
      porcentagem.diretor_comercial +
      porcentagem.empresas.reduce((e, x) => e + x, 0);

    const empresa1 = {
      porcentagem: 100 - total,
      valor: (Number(comissaoLiquida.replace(",", ".")) * (100 - total)) / 100
    };
    setValue('franquia.empresa.0.porcentagem', empresa1.porcentagem);
    setValue('franquia.empresa.0.valor', formatoMoeda(empresa1.valor.toFixed(2)));
    if (franquia.empresa[0]) {
      franquia.empresa[0].valor = empresa1.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      franquia.empresa[0].porcentagem = empresa1.porcentagem;
    }
    console.log(porcentagem)
    console.log("Porcentagem utilizada:", total, '| "Sobrando" Empresa 1', empresa1.porcentagem);
    if (empresa1.porcentagem < 0.01) setError('franquia.empresa.0.porcentagem', { type: "custom", message: 'Somatório passou de 100%' });
    else clearErrors('franquia.empresa.0.porcentagem');
  };

  //Calcula porcentagem de toda as empresa
  const calcularTotalEmpresas = () => {
    console.log(franquia);
    //calculando valor empresas
    let comissaoLiquidaWatch = watch("valor_comissao_liquida").replace(/[R$.]+/g, '');
    console.log("comissaoLiquida = " + comissaoLiquida);
    console.log("comissaoLiquidaWatch = " + comissaoLiquidaWatch);
    let valorCalculadoE = 0;
    watch("franquia.empresa").forEach((empresa, index_e) => {
      valorCalculadoE = Number(comissaoLiquidaWatch.replace(",", ".")) * empresa.porcentagem / 100;
      valorCalculadoE = valorCalculadoE.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      setValue("franquia.empresa." + index_e + ".valor", valorCalculadoE);
    });

    //calculando valor repasse
    let valorCalculadoR = 0;
    valorCalculadoR = Number(comissaoLiquidaWatch.replace(",", ".")) * watch("franquia.repasseFranquia.porcentagem") / 100;
    valorCalculadoR = valorCalculadoR.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    setValue("franquia.repasseFranquia.valor", valorCalculadoR);

    //calculando valor royalties
    let valorCalculadoRoy = 0;
    valorCalculadoRoy = (Number(comissaoLiquidaWatch.replace(",", ".")) * watch("franquia.royalties.porcentagem") / 100) - removeR$(watch('franquia.royalties.desconto'));
    valorCalculadoRoy = valorCalculadoRoy.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    setValue("franquia.royalties.valor", valorCalculadoRoy);

    //calculando valor comunicacao
    let valorCalculadoC = 0;
    valorCalculadoC = (Number(comissaoLiquidaWatch.replace(",", ".")) * watch("franquia.comunicacao.porcentagem") / 100) - removeR$(watch("franquia.comunicacao.desconto"));
    valorCalculadoC = valorCalculadoC.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    setValue("franquia.comunicacao.valor", valorCalculadoC);

  };
  
  const calcularTotalUsuarios = async () => {
    console.log(percentual);
    let comissaoLiquidaWatch = watch("valor_comissao_liquida").replace(/[R$.]+/g, '');
    let valorCalculadoGG = 0;
    watch("percentual.gerentesGerais").forEach((gg, index_gg) => {
      valorCalculadoGG = (Number(comissaoLiquidaWatch.replace(",", ".")) * gg.porcentagemComissao / 100) - removeR$(gg.desconto);
      valorCalculadoGG = valorCalculadoGG.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      percentual.gerentesGerais[index_gg].valorReal = valorCalculadoGG;
      setValue("percentual.gerentesGerais." + index_gg + ".valorReal", valorCalculadoGG);
    });

    let valorCalculadoG = 0;
    watch("percentual.gerentes").forEach((gerente, index_g) => {
      valorCalculadoG = (Number(comissaoLiquidaWatch.replace(",", ".")) * gerente.porcentagemComissao / 100) - removeR$(gerente.desconto);
      valorCalculadoG = valorCalculadoG.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      percentual.gerentes[index_g].valorReal = valorCalculadoG;
      setValue("percentual.gerentes." + index_g + ".valorReal", valorCalculadoG);
    });

    let valorCalculadoC = 0;
    watch("percentual.corretores").forEach((corretores, index_c) => {
      valorCalculadoC = (Number(comissaoLiquidaWatch.replace(",", ".")) * corretores.porcentagemComissao / 100) - removeR$(corretores.desconto);
      valorCalculadoC = valorCalculadoC.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      percentual.corretores[index_c].valorReal = valorCalculadoC;
      setValue("percentual.corretores." + index_c + ".valorReal", valorCalculadoC);
    });

    let valorCalculadoO = 0;
    watch("percentual.opcionistas").forEach((opcionista, index_o) => {
      valorCalculadoO = (Number(comissaoLiquidaWatch.replace(",", ".")) * opcionista.porcentagemComissao / 100) - removeR$(opcionista.desconto);
      valorCalculadoO = valorCalculadoO.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      percentual.opcionistas[index_o].valorReal = valorCalculadoO;
      setValue("percentual.opcionistas." + index_o + ".valorReal", valorCalculadoO);
    });

    //calculando valor DC
    let valorCalculadoDC = 0;
    valorCalculadoDC = (Number(comissaoLiquidaWatch.replace(",", ".")) * watch("percentual.diretor_comercial.porcentagemComissao") / 100) - removeR$(watch("percentual.diretor_comercial.desconto"));
    valorCalculadoDC = valorCalculadoDC.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    setValue("percentual.diretor_comercial.valorReal", valorCalculadoDC);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [franquia, setFranquia] = useState({
    empresa: (
      empresa.map(empresas => ({
        agencia: empresas.agencia || '',
        banco_id: empresas.banco_id || '',
        cnpj: empresas.cnpj || '',
        creci: empresas.creci || '',
        desconto: empresas?.desconto ? ("R$ " + empresas.desconto) : '',
        id: empresas.empresa_id || '',
        nomeEmpresarial: empresas.nome_empresarial || '',
        conta: empresas.numero_conta || '',
        pix: empresas.pix || '',
        porcentagem: empresas?.porcentagem_real ? empresas?.porcentagem_real : '',
        relacaoEmpresaComissao: empresas.relacao_empresa_comissao_id || '',
        dadosBancarios: empresas.tipo_pagamento || '',
        valor: empresas?.valor_real ? ("R$ " + empresas.valor_real) : '',
      })
      )
    ),
  })

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [percentual, setPercentual] = useState({
    limitGerentesGerais: 100,
    limitGerente: 100,
    limitCorretores: 100,
    limitOpcionistas: 100,
    gerentesGerais: comissao_gerente_gg.loja.gerente_geral_id !== null
      ?
      comissao_gerente_gg.comissao_gerente_gerais.length === 0
        ? ([{
          id: "",
          userId: "",
          tipoPessoa: "PF",
          nome: "",
          nomeEmpresarial: "",
          cpf: "",
          cnpj: "",
          porcentagemComissao: '',
          desconto: formatoMoeda('0.00'),
          valorReal: "",
          dadosBancarios: 'pix',
          chavePix: '',
          banco_id: '',
          agencia: '',
          conta: ''
        }])
        : comissao_gerente_gg.comissao_gerente_gerais.map((gerenteGeral) => ({
          id: gerenteGeral.id || "",
          userId: gerenteGeral.usuario_id || "",
          tipoPessoa: gerenteGeral.tipo_pessoa || 'PF',
          nome: gerenteGeral.name || "",
          nomeEmpresarial: gerenteGeral.nome_empresarial || "",
          cpf: gerenteGeral.cpf || "",
          cnpj: gerenteGeral.cnpj || "",
          porcentagemComissao: gerenteGeral.porcentagem_real || '',
          desconto: gerenteGeral.desconto ? "R$ " + gerenteGeral.desconto : "",
          valorReal: formatoMoeda(gerenteGeral.valor_real?.replace('.', ',')) || "",
          dadosBancarios: gerenteGeral.tipo_pagamento || 'pix',
          chavePix: gerenteGeral.pix || '',
          banco_id: gerenteGeral.banco_id || '',
          agencia: gerenteGeral.agencia || '',
          conta: gerenteGeral.numero_conta || ''
        }))
      :
      comissao_gerente_gg.loja.gerente_geral_id === null
        ? comissao_gerente_gg.comissao_gerente_gerais.map((gerenteGeral) => ({
          id: gerenteGeral.id || "",
          userId: gerenteGeral.usuario_id || "",
          tipoPessoa: gerenteGeral.tipo_pessoa || 'PF',
          nome: gerenteGeral.name || "",
          nomeEmpresarial: gerenteGeral.nome_empresarial || "",
          cpf: gerenteGeral.cpf || "",
          cnpj: gerenteGeral.cnpj || "",
          porcentagemComissao: gerenteGeral.porcentagem_real || '',
          desconto: gerenteGeral.desconto ? "R$ " + gerenteGeral.desconto : "",
          valorReal: gerenteGeral.valor_real || "",
          dadosBancarios: gerenteGeral.tipo_pagamento || 'pix',
          chavePix: gerenteGeral.pix || '',
          banco_id: gerenteGeral.banco_id || '',
          agencia: gerenteGeral.agencia || '',
          conta: gerenteGeral.numero_conta || ''
        }))
        : ([]),
    gerentes: comissao_gerente_gg.comissao_gerentes.length === 0
      ? ([{
        id: "",
        userId: "",
        tipoPessoa: "PF",
        nome: "",
        nomeEmpresarial: "",
        cpf: "",
        cnpj: "",
        porcentagemComissao: '',
        desconto: formatoMoeda('0.00'),
        valorReal: "",
      }])
      : comissao_gerente_gg.comissao_gerentes.map((gerente) => ({
        id: gerente.id || "",
        userId: gerente.usuario_id || "",
        tipoPessoa: gerente.tipo_pessoa || 'PF',
        nome: gerente.name || "",
        nomeEmpresarial: gerente.nome_empresarial || "",
        cpf: gerente.cpf || "",
        cnpj: gerente.cnpj || "",
        porcentagemComissao: gerente.porcentagem_real || '',
        desconto: gerente.desconto ? "R$ " + gerente.desconto : "",
        valorReal: gerente.valor_real || "",
        dadosBancarios: gerente.tipo_pagamento || 'pix',
        chavePix: gerente.pix || '',
        banco_id: gerente.banco_id || '',
        agencia: gerente.agencia || '',
        conta: gerente.numero_conta || ''
      })),
    corretores: comissao === null || comissao.corretores_vendedores_comissao.length === 0
      ? ([{
        id: "",
        userId: "",
        tipoPessoa: "PF",
        nome: "",
        nomeEmpresarial: "",
        cpf: "",
        cnpj: "",
        porcentagemComissao: '',
        desconto: formatoMoeda('0.00'),
        valorReal: "",
      }])
      : comissao.corretores_vendedores_comissao.map((corretor) => ({
        id: corretor.id || "",
        userId: corretor.usuario_id || "",
        tipoPessoa: corretor.tipo_pessoa || 'PF',
        nome: corretor.name || "",
        nomeEmpresarial: corretor.nome_empresarial || "",
        cpf: corretor.cpf || "",
        cnpj: corretor.cnpj || "",
        porcentagemComissao: corretor.porcentagem_real || '',
        desconto: corretor.desconto ? "R$ " + corretor.desconto : "",
        valorReal: corretor.valor_real || "",
        dadosBancarios: corretor.tipo_pagamento || 'pix',
        chavePix: corretor.pix || '',
        banco_id: corretor.banco_id || '',
        agencia: corretor.agencia || '',
        conta: corretor.numero_conta || ''
      })),
    laudoType: comissao?.corretores_opicionistas_comissao[0]?.tipo_laudo_opcionista || "simples",
    opcionistas: comissao === null || comissao.corretores_opicionistas_comissao.length === 0
      ? ([{
        id: "",
        userId: "",
        tipoPessoa: "PF",
        nome: "",
        nomeEmpresarial: "",
        cpf: "",
        cnpj: "",
        porcentagemComissao: '',
        desconto: formatoMoeda('0.00'),
        valorReal: "",
      }])
      : comissao.corretores_opicionistas_comissao.map((opcionista) => ({
        id: opcionista.id || "",
        userId: opcionista.usuario_id || "",
        tipoPessoa: opcionista.tipo_pessoa || 'PF',
        nome: opcionista.name || "",
        nomeEmpresarial: opcionista.nome_empresarial || "",
        cpf: opcionista.cpf || "",
        cnpj: opcionista.cnpj || "",
        porcentagemComissao: opcionista.porcentagem_real || '',
        desconto: opcionista.desconto ? "R$ " + opcionista.desconto : "",
        valorReal: opcionista.valor_real || "",
        dadosBancarios: opcionista.tipo_pagamento || 'pix',
        chavePix: opcionista.pix || '',
        banco_id: opcionista.banco_id || '',
        agencia: opcionista.agencia || '',
        conta: opcionista.numero_conta || ''
      })),
  });

  const valorLaudo = percentualOpcionistas(percentual.laudoType);

  // CALCULO DE PORCENTAGEM APENAS DOS CAMPOS OBRIGATORIO (SEM FRANQUIA)
  let percentagemCamposObrigatorios =
    Number(watch("percentual.comissaoTotalGerente")) +
    Number(valorLaudo.replace("%", "")) +
    Number(watch("percentual.comissaoTotal")) +
    Number(watch("franquia.comunicacao.porcentagem")) +
    Number(watch("franquia.royalties.porcentagem"));

  // CALCULO DE PORCENTAGEM DOS CAMPOS DE FRANQUIA
  const porcentagemEmpresaComFranquia = (
    100 - (percentagemCamposObrigatorios +
      (watch('radioFranquiaRepasse') === 'true' ? Number(watch("franquia.repasseFranquia.porcentagem")) : 0))
  );

  // CALCULO DE VALORES DE CADA CAMPO EM FRANQUIA
  const removeR$ = (e) => {
    const value = e.replace(/[R$.]+/g, '');
    return Number(value.replace(",", "."));
  };

  const valorRepasse = (Number(comissaoLiquida.replace(",", ".")) * Number(watch('franquia.repasseFranquia.porcentagem') / 100)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  const valorRoyalities = ((Number(comissaoLiquida.replace(",", ".")) * Number(watch('franquia.royalties.porcentagem')) / 100) - removeR$(watch('franquia.royalties.desconto'))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  const valorComunicacao = ((Number(comissaoLiquida.replace(",", ".")) * Number(watch('franquia.comunicacao.porcentagem')) / 100) - removeR$(watch('franquia.comunicacao.desconto'))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

  const errorTextInput = "Campo obrigatório.";

  const handleChangeText = (e, index, name, type) => {
    let value = e;
    if (name === 'desconto' || name === 'valor' || name === 'valorReal') value = formatoMoeda(e);
    if (name === 'cpf') value = cpfMask(e);
    if (name === 'cnpj') value = cnpjMask(e);

    let valorLiquido = watch('valor_comissao_liquida').replace(/[R$.]+/g, '').replace(",", ".");
    if (index !== null && e) {
      //Empresa
      if (type === 'empresa') {

        franquia[type][index][name] = value;
        if (name === 'porcentagem') {
          if (index === 0) {
            setValue("franquia." + type + "." + index + ".valor", ((Number(comissaoLiquida.replace(",", ".")) * Number(watch('franquia.' + type + "." + index + '.porcentagem'))) / 100 - removeR$(watch('franquia.' + type + "." + index + '.desconto'))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
            franquia.empresa[index].valor = ((Number(comissaoLiquida.replace(",", ".")) * Number(watch('franquia.' + type + "." + index + '.porcentagem'))) / 100 - removeR$(watch('franquia.' + type + "." + index + '.desconto'))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
          }
          else {
            let valorPrimeiraEmpresa = Number(franquia.empresa[0].valor.replace(/[R$.]+/g, '').replace(",", "."));
            let valor = (valorPrimeiraEmpresa * Number(watch('franquia.' + type + "." + index + '.porcentagem'))) / 100 - removeR$(watch('franquia.' + type + "." + index + '.desconto'));
            let valorEmpresaSelecionado = (((valorLiquido * Number(watch('franquia.' + type + "." + index + '.porcentagem'))) / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))

            setValue("franquia." + type + "." + index + ".valor", (valorEmpresaSelecionado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })));

            const valorProcentagem = (valor / valorPrimeiraEmpresa) * 100;
            franquia[type][index].porcentagem = Math.round(valorProcentagem * 100) / 100;

            franquia[type][index].valor = valorEmpresaSelecionado;
            calcularTotalEmpresa();
          }
        }

        if (name === 'valor') {
          const valor = formatoMoeda(watch("franquia." + type + "." + index + ".valor")).replace(/[R$.]+/g, '').replace(",", ".");
          const valorProcentagem = (Number(valor) / Number(comissaoLiquida.replace(",", "."))) * 100;
          setValue("franquia." + type + "." + index + ".porcentagem", Math.round(valorProcentagem * 100) / 100);
          setValue("franquia." + type + "." + index + ".desconto", "");
          setValue("franquia." + type + "." + index + ".valor", formatoMoeda(watch("franquia." + type + "." + index + ".valor")));
          franquia[type][index].desconto = '';
          franquia[type][index].porcentagem = Math.floor(valorProcentagem * 100) / 100;
          franquia[type][index].porcentagem = Math.round(valorProcentagem * 100) / 100;

        }

        if (name === 'desconto') {
          setValue("franquia." + type + "." + index + ".desconto", formatoMoeda(watch("franquia." + type + "." + index + ".desconto")));
          setValue("franquia." + type + "." + index + ".valor", ((Number(comissaoLiquida.replace(",", ".")) * Number(watch('franquia.' + type + "." + index + '.porcentagem'))) / 100 - Number(formatoMoeda(watch('franquia.' + type + "." + index + '.desconto')).replace(/[R$.]+/g, '').replace(',', '.'))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
        }

        // Tira valor e porcentagem da primeira emrpesa
        if (index > 0) {

        }
        setFranquia({ ...franquia });
      }

      //GG / Gerente / Gerente Vendedor / Opcionista
      else {
        //Recalcula o valor da empresa 1
        percentual[type][index][name] = value;

        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //LIBERDADE CANTOU
        //GG / Gerente / Gerente Vendedor / Opcionista
        if (name === 'porcentagemComissao') {
          setValue("percentual." + type + "." + index + ".valorReal", ((Number(comissaoLiquida.replace(",", ".")) * Number(watch('percentual.' + type + '.' + index + '.porcentagemComissao'))) / 100 - removeR$(watch('percentual.' + type + '.' + index + '.desconto'))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
        }

        if (name === 'valorReal') {
          const valor = formatoMoeda(watch("percentual." + type + "." + index + ".valorReal")).replace(/[R$.]+/g, '').replace(",", ".");

          const valorProcentagem = (Number(valor) / Number(comissaoLiquida.replace(",", "."))) * 100;

          setValue("percentual." + type + "." + index + ".porcentagemComissao", Math.round(valorProcentagem * 100) / 100);
          setValue("percentual." + type + "." + index + ".desconto", "");
          percentual[type][index].desconto = '';
        }

        if (name === 'desconto') {
          setValue("percentual." + type + "." + index + ".valorReal", ((Number(comissaoLiquida.replace(",", ".")) * Number(watch('percentual.' + type + '.' + index + '.porcentagemComissao'))) / 100 - Number(formatoMoeda(watch('percentual.' + type + '.' + index + '.desconto')).replace(/[R$.]+/g, '').replace(',', '.'))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
        }
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        setValue("percentual." + type + "." + index + "." + name, value)
        clearErrors("percentual." + type + "." + index + "." + name)
        const arrayOpcionista = listOpcionistas.find((opcionista) => opcionista.nome === e);
        if (name === 'nome' && arrayOpcionista) {
          setValue("percentual." + type + "." + index + ".cpf", arrayOpcionista.cpf_cnpj || '')
          setValue("percentual." + type + "." + index + ".banco_id", arrayOpcionista.banco_id || '')
          setValue("percentual." + type + "." + index + ".chavePix", arrayOpcionista.pix || '')
          setValue("percentual." + type + "." + index + ".agencia", arrayOpcionista.agencia || '')
          setValue("percentual." + type + "." + index + ".conta", arrayOpcionista.numero_conta || '')
          setValue("percentual." + type + "." + index + ".userId", arrayOpcionista.usuario_id || '')
          percentual[type][index].cpf = arrayOpcionista.cpf_cnpj || '';
          percentual[type][index].banco_id = arrayOpcionista.banco_id || '';
          percentual[type][index].chavePix = arrayOpcionista.pix || '';
          percentual[type][index].agencia = arrayOpcionista.agencia || '';
          percentual[type][index].conta = arrayOpcionista.numero_conta || '';
          percentual[type][index].userId = arrayOpcionista.usuario_id || '';

          //Forçar Opcionista como Corretor Empresa a passar para o PJ
          if (value.includes('Corretor Empresa')) {
            setValue("percentual." + type + "." + index + ".nomeEmpresarial", arrayOpcionista?.nome_empresa || '');
            percentual[type][index].nomeEmpresarial = arrayOpcionista?.nome_empresa || '';

            setValue("percentual." + type + "." + index + ".cnpj", arrayOpcionista?.cnpj || '');
            percentual[type][index].cnpj = arrayOpcionista?.cnpj || '';

            setValue("percentual." + type + "." + index + ".dadosBancarios", 'banco');
            percentual[type][index].dadosBancarios = 'banco';

            setValue("percentual." + type + "." + index + ".tipoPessoa", 'PJ');
            percentual[type][index].tipoPessoa = 'PJ'
          }
          else {

          }
        }
        setPercentual({ ...percentual });
        calcularTotalEmpresa();
      }
    } else {
      //Recalcula o valor da empresa
      setValue("franquia.empresa.0.valor", franquia.empresa[0].valor);
      setValue("franquia.empresa.0.porcentagem", franquia.empresa[0].porcentagem);

      setValue("franquia." + type + "." + name, value)

      if (type === 'royalties' || type === 'comunicacao') {
        //Royalties / Comunicação
        if (name === 'porcentagem') {
          setValue("franquia." + type + ".valor", ((Number(comissaoLiquida.replace(",", ".")) * Number(watch('franquia.' + type + '.porcentagem'))) / 100 - removeR$(watch('franquia.' + type + '.desconto'))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
        }

        if (name === 'valor') {
          const valor = formatoMoeda(watch("franquia." + type + ".valor")).replace(/[R$.]+/g, '').replace(",", ".");
          const valorProcentagem = (Number(valor) / Number(comissaoLiquida.replace(",", "."))) * 100;
          setValue("franquia." + type + ".porcentagem", Math.round(valorProcentagem * 100) / 100);
          setValue("franquia." + type + ".desconto", "");
        }

        if (name === 'desconto') {
          setValue("franquia." + type + ".valor", ((Number(comissaoLiquida.replace(",", ".")) * Number(watch('franquia.' + type + '.porcentagem'))) / 100 - Number(formatoMoeda(watch('franquia.' + type + '.desconto')).replace(/[R$.]+/g, '').replace(',', '.'))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
        }
      }
      else {
        if (name === 'porcentagem') {
          setValue("franquia." + type + ".valor", ((Number(comissaoLiquida.replace(",", ".")) * Number(watch('franquia.' + type + '.porcentagem'))) / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));
        }

        if (name === 'valor') {
          const valor = formatoMoeda(watch("franquia." + type + ".valor")).replace(/[R$.]+/g, '').replace(",", ".");
          const valorProcentagem = (Number(valor) / Number(comissaoLiquida.replace(",", "."))) * 100;
          setValue("franquia." + type + ".porcentagem", Math.round(valorProcentagem * 100) / 100);
          setValue("franquia." + type + ".desconto", "");
        }
      }
      calcularTotalEmpresa();
    }
  };

  const removeCorretor = (e, type, i) => {
    let recuperaValor = Number(percentual[type][i].valorReal.replace(/[R$.]+/g, '').replace(",", ".")) + Number(franquia.empresa[0].valor.replace(/[R$.]+/g, '').replace(",", "."));
    let recuperaPorcentagem = Number(percentual[type][i].porcentagemComissao) + Number(franquia.empresa[0].porcentagem);

    if (percentual[type][i].id) {
      Axios.post(app + 'excluir_corretores', {
        "id_corretor": percentual[type][i].id,
        'type': type,
        'empresaValor': recuperaValor.toLocaleString('pt-BR', { minimumFractionDigits: 2 }),
        'empresaPorcentagem': recuperaPorcentagem
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
        .then(res => {
          if (res.data.status && res.data.status === (401 || 498)) {
            localStorage.clear();
            history.push('/');
          } else {
            percentual[type].splice(i, 1);
            setValue('percentual.' + type, percentual[type])
            setPercentual({ ...percentual })
            recarregarProcesso(e, type);
            setSelectProcess(res.data.processo);
            calcularTotalEmpresa();

            refreshProcessoSideSheet();
          }
        })
        .catch(function (error) {
          console.log(error);
          setLoading(true);
        })
    } else {
      percentual[type].splice(i, 1);
      setValue('percentual.' + type, percentual[type])
      setPercentual({ ...percentual })
      calcularTotalEmpresa();
    }
  };

  const addCorretor = (type) => {
    if (percentual[type] === undefined) {
      percentual[type] = [];
    }

    percentual[type].push({
      id: "",
      userId: "",
      tipoPessoa: "PF",
      nome: "",
      nomeEmpresarial: "",
      cpf: "",
      cnpj: "",
      porcentagemComissao: "",
      desconto: formatoMoeda('0.00'),
      valorReal: "",
      dadosBancarios: 'pix',
      chavePix: '',
      banco_id: '',
      agencia: '',
      conta: ''
    });
    setValue('percentual.' + type, percentual[type])
    setPercentual({ ...percentual })
  };

  const addEmpresa = (type) => {
    if (franquia[type] === undefined) {
      franquia[type] = [];
    }

    franquia[type].push({
      agencia: '',
      banco_id: '',
      cnpj: '',
      creci: '',
      desconto: '',
      id: '',
      nomeEmpresarial: '',
      conta: '',
      pix: '',
      porcentagem: '',
      relacaoEmpresaComissao: '',
      dadosBancarios: 'banco',
      valor: ''
    });

    setValue('franquia.' + type, franquia[type])
    setFranquia({ ...franquia })
  };

  const removeEmpresa = (e, type, i) => {

    let recuperaValor = Number(franquia.empresa[i].valor.replace(/[R$.]+/g, '').replace(",", ".")) + Number(franquia.empresa[0].valor.replace(/[R$.]+/g, '').replace(",", "."));
    let recuperaPorcentagem = Number(franquia.empresa[i].porcentagem) + Number(franquia.empresa[0].porcentagem);

    if (franquia[type][i].relacaoEmpresaComissao) {
      Axios.post(app + 'excluir_empresas', {
        "id": franquia[type][i].id,
        "relacaoEmpresaComissao": franquia[type][i].relacaoEmpresaComissao,
        'type': type,
        'empresaValor': recuperaValor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
        'empresaPorcentagem': recuperaPorcentagem
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
        .then(res => {
          if (res.data.status && res.data.status === (401 || 498)) {
            localStorage.clear();
            history.push('/');
          } else {
            // Recupera para a primeira empresa o valor da empresa excluída
            franquia[type].splice(i, 1);
            setValue("franquia." + type, franquia[type]);
            clearErrors("franquia." + type);

            recarregarProcesso(e, type);
            setSelectProcess(res.data.processo);

            refreshProcessoSideSheet();
            calcularTotalEmpresa();
          }
        })
        .catch(function (error) {
          console.log(error);
          setLoading(true);
        })
    } else {
      franquia[type].splice(i, 1);
      setValue("franquia." + type, franquia[type]);
      clearErrors("franquia." + type);
      calcularTotalEmpresa();
    }
  };

  const handleComissaoLiquida = (e) => {
    const valorLiquido = (watch('valor_comissao_liquida').replace(/[R$.]+/g, '')).replace(",", ".");
    console.log('valor', valorLiquido);
    setValue('valor_comissao_liquida', formatoMoeda(valorLiquido));
    calcularTotalUsuarios();
    calcularTotalEmpresas();
    calculoValorTotal(watch('parcelas'));
  }

  const handleSalvar = async (e, type) => {
    setLoading(true);
    let CondicaoStatus = 2;

    const data = new FormData();
    data.append('comissaoId', e.comissao_id);
    data.append('total', e.radioTipoComissao);
    data.append('comissaoTotal', e.valor_comissao_total);
    data.append('deducoes', e.deducao);
    data.append('processo_id', selectProcess.imovel.comissao.processo_id);
    data.append('usuario_id_logado', gerente_id);
    data.append('pagamento', e.liquida);
    data.append('comissaoLiquida', e.valor_comissao_liquida);
    data.append('observacao', e.observacoes);
    data.append('condicaostatus', CondicaoStatus);
    data.append('status_processo', selectProcess.imovel.status_processo)

    e.parcelas.forEach((parcela) => {
      data.append('id_parcela[]', parcela.id)
      data.append('valor_parcela[]', e.radioTipoComissao === 'partes' ? parcela.valor : "");
      data.append('periodo_pagamento[]', parcela.periodo);
    })

    //FRANQUIAS
    data.append('verificar_franquia', e.radioFranquia);
    data.append('verificar_repasse', e.radioFranquiaRepasse);

    const empresa = e.franquia.empresa;
    const repasseFranquia = e.franquia.repasseFranquia;
    const royalties = e.franquia.royalties;
    const comunicao = e.franquia.comunicacao;

    //CALCULO DE %
    if (e.radioFranquia === "true") {
      empresa.porcentagem = porcentagemEmpresaComFranquia;
    } else {
      empresa.porcentagem = 100 - (percentagemCamposObrigatorios + 3);
      empresa.valor = ((comissaoLiquida.replace(",", ".") * empresa.porcentagem) / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }

    // DIRETOR COMERCIAL
    if (franquia.empresa.some((empresa) => empresa.nomeEmpresarial.includes("DNA Imóveis"))) {
      const dc_data = e.percentual.diretor_comercial;
      // data.append('empresa_id[]', dc_data.userId);
      data.append('documento_dc', dc_data.cnpj);
      data.append('dc_creci', dc_data.creci);
      data.append('nome_empresarial_dc', dc_data.nome);
      data.append('desconto_dc', dc_data.desconto);
      data.append('porcentagem_comissao_dc', dc_data.porcentagemComissao);
      data.append('valor_real_dc', dc_data.valorReal.replace(/[R$]+/g, ''));

      // Dados bancários
      data.append('tipo_pagamento_dc', dc_data.dadosBancarios);
      data.append('dc_chavePix', dc_data.chavePix);
      data.append('dc_banco_id', dc_data.banco_id);
      data.append('dc_agencia', dc_data.agencia);
      data.append('dc_conta', dc_data.conta);
    };

    // EMPRESAS
    e.franquia.empresa.forEach((empresa) => {
      data.append('id_relacao_empresa_comissao[]', empresa.relacaoEmpresaComissao || '');
      data.append('empresa_id[]', empresa.id);
      data.append('empresa_cnpj[]', empresa.cnpj);
      data.append('empresa_creci[]', empresa.creci);
      data.append('empresa_nomeEmpresarial[]', empresa.nomeEmpresarial);
      data.append('empresa_desconto[]', empresa.desconto);
      data.append('empresa_porcentagem[]', empresa.porcentagem);
      data.append('empresa_valor[]', empresa.valor.replace(/[R$]+/g, ''));

      // Dados bancários
      data.append('empresa_dadosBancarios[]', empresa.dadosBancarios);
      data.append('empresa_chavePix[]', empresa.chavePix);
      data.append('empresa_banco_id[]', empresa.banco_id);
      data.append('empresa_agencia[]', empresa.agencia);
      data.append('empresa_conta[]', empresa.conta);
    });

    // REPASSE
    if (e.radioFranquiaRepasse === "true") {
      data.append('repasseFranquia_empresa_id', repasseFranquia.empresa_id);
      data.append('repasseFranquia_agencia', repasseFranquia.agencia);
      data.append('repasseFranquia_banco_id', repasseFranquia.banco_id);
      data.append('repasseFranquia_chavePix', repasseFranquia.chavePix);
      data.append('repasseFranquia_conta', repasseFranquia.conta);
      data.append('repasseFranquia_dadosBancarios', repasseFranquia.dadosBancarios);
      data.append('repasseFranquia_porcentagem', repasseFranquia.porcentagem);
      data.append('repasseFranquia_valor', valorRepasse.replace(/[R$]+/g, ''));
    }

    ////////////////////////////////////////////////////////////////////////////////////
    // REPASSE
    empresa.porcentagem = porcentagemEmpresaComFranquia;
    //empresa.valor = valorEmpresa;

    // DADOS EMPRESA
    if (e.radioFranquiaRepasse === "true") {
      data.append('repasseFranquia_empresa_id', repasseFranquia.empresa_id);
      data.append('repasseFranquia_agencia', repasseFranquia.agencia);
      data.append('repasseFranquia_banco_id', repasseFranquia.banco_id);
      data.append('repasseFranquia_chavePix', repasseFranquia.chavePix);
      data.append('repasseFranquia_conta', repasseFranquia.conta);
      data.append('repasseFranquia_dadosBancarios', repasseFranquia.dadosBancarios);
      data.append('repasseFranquia_porcentagem', repasseFranquia.porcentagem);
      data.append('repasseFranquia_valor', valorRepasse.replace(/[R$]+/g, ''));
    }
    ////////////////////////////////////////////////////////////////////////////////////

    // DADOS ROYALTIES
    data.append('royalites_agencia', royalties.agencia);
    data.append('royalites_banco_id', royalties.banco_id);
    data.append('royalites_chavePix', royalties.chavePix);
    data.append('royalites_conta', royalties.conta);
    data.append('royalites_dadosBancarios', royalties.dadosBancarios);
    data.append('royalites_desconto', royalties.desconto);
    data.append('royalites_porcentagem', royalties.porcentagem);
    data.append('royalites_valor', valorRoyalities.replace(/[R$]+/g, ''));

    // DADOS COMUNICAÇÃO
    data.append('comunicao_agencia', comunicao.agencia);
    data.append('comunicao_banco_id', comunicao.banco_id);
    data.append('comunicao_chavePix', comunicao.chavePix);
    data.append('comunicao_conta', comunicao.conta);
    data.append('comunicao_dadosBancarios', comunicao.dadosBancarios);
    data.append('comunicao_desconto', comunicao.desconto);
    data.append('comunicao_porcentagem', comunicao.porcentagem);
    data.append('comunicao_valor', valorComunicacao.replace(/[R$]+/g, ''));

    // DADOS GERENTES GERAIS
    data.append('total_comissao_GG', e.percentual.comissaoTotalGerenteGeral);
    e.percentual.gerentesGerais.forEach((gerentesGerais) => {
      const desconto = parseFloat(Number(gerentesGerais.desconto.replace(',', '.')).toFixed(2));

      data.append('id_gerente_geral_usuario[]', listOpcionistas.find((usuarios) => usuarios.nome === gerentesGerais.nome)?.usuario_id || gerentesGerais.userId)
      data.append('tipo_pessoa_gerente_geral[]', gerentesGerais.tipoPessoa);

      // FORMA DE PAGAMENTO PIX OU BANCO
      data.append('tipo_pagamento_gerente_geral[]', gerentesGerais.dadosBancarios);
      if (gerentesGerais.dadosBancarios === 'pix') {
        data.append('pix_gerente_geral[]', gerentesGerais.chavePix);
        data.append('banco_id_gerente_geral[]', '');
        data.append('agencia_gerente_geral[]', '');
        data.append('conta_gerente_geral[]', '');
      } else {
        data.append('pix_gerente_geral[]', '');
        data.append('banco_id_gerente_geral[]', gerentesGerais.banco_id);
        data.append('agencia_gerente_geral[]', gerentesGerais.agencia);
        data.append('conta_gerente_geral[]', gerentesGerais.conta);
      }

      if (gerentesGerais.tipoPessoa === 'PF') {
        data.append('documento_gerente_geral[]', gerentesGerais.cpf);
      }
      else {
        data.append('documento_gerente_geral[]', gerentesGerais.cnpj);
      }

      data.append('nome_gerente_geral[]', gerentesGerais.nome);
      data.append('nome_empresarial_gerente_geral[]', gerentesGerais.nomeEmpresarial);
      data.append('porcentagem_comissao_gerente_geral[]', gerentesGerais.porcentagemComissao);
      data.append('desconto_gerente_geral[]', desconto === 0 ? '' : gerentesGerais.desconto);
      data.append('valor_real_gerente_geral[]', gerentesGerais.valorReal);
      data.append('id_gerente_geral[]', gerentesGerais.id);
    });

    // DADOS GERENTES
    data.append('total_comissao_gerentes', e.percentual.comissaoTotalGerente);
    e.percentual.gerentes.forEach((gerentes) => {
      const desconto = parseFloat(Number(gerentes.desconto.replace(',', '.')).toFixed(2));
      data.append('id_gerente_usuario[]', listOpcionistas.find((usuarios) => usuarios.nome === gerentes.nome)?.usuario_id || gerentes.userId)
      data.append('tipo_pessoa_gerente[]', gerentes.tipoPessoa);

      // FORMA DE PAGAMENTO PIX OU BANCO
      data.append('tipo_pagamento_gerente[]', gerentes.dadosBancarios);
      if (gerentes.dadosBancarios === 'pix') {
        data.append('pix_gerente[]', gerentes.chavePix);
        data.append('banco_id_gerente[]', '');
        data.append('agencia_gerente[]', '');
        data.append('conta_gerente[]', '');
      } else {
        data.append('pix_gerente[]', '');
        data.append('banco_id_gerente[]', gerentes.banco_id);
        data.append('agencia_gerente[]', gerentes.agencia);
        data.append('conta_gerente[]', gerentes.conta);
      }

      if (gerentes.tipoPessoa === 'PF') {
        data.append('documento_gerente[]', gerentes.cpf);
      }
      else {
        data.append('documento_gerente[]', gerentes.cnpj);
      }

      data.append('nome_gerente[]', gerentes.nome);
      data.append('nome_empresarial_gerente[]', gerentes.nomeEmpresarial);
      data.append('porcentagem_comissao_gerente[]', gerentes.porcentagemComissao);
      data.append('id_gerente[]', gerentes.id);
      data.append('desconto_gerente[]', desconto === 0 ? '' : gerentes.desconto);
      data.append('valor_real_gerente[]', gerentes.valorReal);
    });

    // DADOS CORRETORES
    data.append('total_comissao', e.percentual.comissaoTotal);
    e.percentual.corretores.forEach((corretores) => {
      const desconto = parseFloat(Number(corretores.desconto.replace(',', '.')).toFixed(2));
      data.append('id_corretor_usuario[]', listOpcionistas.find((usuarios) => usuarios.nome === corretores.nome)?.usuario_id || corretores.userId)
      data.append('tipo_pessoa_corretor[]', corretores.tipoPessoa);

      // FORMA DE PAGAMENTO PIX OU BANCO
      data.append('tipo_pagamento_corretor[]', corretores.dadosBancarios);
      if (corretores.dadosBancarios === 'pix') {
        data.append('pix_corretor[]', corretores.chavePix);
        data.append('banco_id_corretor[]', '');
        data.append('agencia_corretor[]', '');
        data.append('conta_corretor[]', '');
      } else {
        data.append('pix_corretor[]', '');
        data.append('banco_id_corretor[]', corretores.banco_id);
        data.append('agencia_corretor[]', corretores.agencia);
        data.append('conta_corretor[]', corretores.conta);
      }

      if (corretores.tipoPessoa === 'PF') {
        data.append('documento_corretor[]', corretores.cpf);
      }
      else {
        data.append('documento_corretor[]', corretores.cnpj);
      }

      data.append('nome_corretor[]', corretores.nome);
      data.append('nome_empresarial_corretor[]', corretores.nomeEmpresarial);
      data.append('porcentagem_comissao_corretor[]', corretores.porcentagemComissao);
      data.append('id_corretor[]', corretores.id);
      data.append('desconto_corretor[]', desconto === 0 ? '' : corretores.desconto);
      data.append('valor_real_corretor[]', corretores.valorReal); //valorReal

    });

    // DADOS OPCIONISTAS
    data.append('tipo_laudo_opicionista', percentual.laudoType)
    e.percentual.opcionistas.forEach((opcionistas) => {
      const desconto = parseFloat(Number(opcionistas.desconto.replace(',', '.')).toFixed(2));
      data.append('id_opcionista_usuario[]', listOpcionistas.find((usuarios) => usuarios.nome === opcionistas.nome)?.usuario_id || opcionistas.userId)
      data.append('tipo_pessoa_opcionista[]', opcionistas.tipoPessoa);

      // FORMA DE PAGAMENTO PIX OU BANCO
      data.append('tipo_pagamento_opcionista[]', opcionistas.dadosBancarios);
      if (opcionistas.dadosBancarios === 'pix') {
        data.append('pix_opcionista[]', opcionistas.chavePix);
        data.append('banco_id_opcionista[]', '');
        data.append('agencia_opcionista[]', '');
        data.append('conta_opcionista[]', '');
      } else {
        data.append('pix_opcionista[]', '');
        data.append('banco_id_opcionista[]', opcionistas.banco_id);
        data.append('agencia_opcionista[]', opcionistas.agencia);
        data.append('conta_opcionista[]', opcionistas.conta);
      }

      if (opcionistas.tipoPessoa === 'PF') {
        data.append('documento_opcionista[]', opcionistas.cpf);
      }
      else {
        data.append('documento_opcionista[]', opcionistas.cnpj);
      }

      data.append('nome_opcionista[]', opcionistas.nome);
      data.append('nome_empresarial_opcionista[]', opcionistas.nomeEmpresarial);
      data.append('porcentagem_comissao_opcionista[]', opcionistas.porcentagemComissao);
      data.append('id_opcionista[]', opcionistas.id);
      data.append('desconto_opcionista[]', desconto === 0 ? '' : opcionistas.desconto);
      data.append('valor_real_opcionista[]', opcionistas.valorReal);
    });

    await Axios.post(app + 'salvar_comissao', data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json; charset=utf-8',
        }
      }).then(async res => {
        if (res.data.status && res.data.status === (401 || 498)) {

        } else {
          recarregarProcesso(e, type);
          setSelectProcess(res.data.processo);
          let opcionistaRetorno = res.data.opcionista?.array_relacao;
          let corretoresRetorno = res.data.corretor?.array_relacao;
          let gerenteRetorno = res.data.gerente?.array_relacao;
          let gerenteGeralRetorno = res.data.gerente_geral?.array_relacao;
          let empresaRetorno = res.data.empresa?.array_relacao;

          percentual.opcionistas.map((opcionista, index) => (
            percentual.opcionistas[index].id = opcionistaRetorno[index].id
          ));

          percentual.corretores.map((corretor, index) => (
            percentual.corretores[index].id = corretoresRetorno[index].id
          ));

          percentual.gerentes.map((gerente, index) => (
            percentual.gerentes[index].id = gerenteRetorno[index].id
          ));

          percentual.gerentesGerais.map((gerenteGeral, index) => (
            percentual.gerentesGerais[index].id = gerenteGeralRetorno[index].id
          ));

          franquia.empresa.map((empresa, index) => (
            franquia.empresa[index].relacaoEmpresaComissao = empresaRetorno[index].id
          ));
        }
      }).catch(function (error) {
        console.log(error);
      }).then(() => {
        setLoading(false);
      })
  };

  const recarregarProcesso = async (e, type) => {
    await refreshProcessoSideSheet();

    if (type === 'gerar') {
      setIsShownPlanilha(true);
    }
    else {
      setIsShownPlanilha(false);
    }

  };

  const handleTipoPessoa = (index, type, value) => {
    percentual[type][index].tipoPessoa = value;
    const idOpcionista = percentual[type][index].userId;
    const arrayOpcionista = listOpcionistas.find((opcionista) => opcionista.usuario_id === idOpcionista);
    if (percentual[type][index].tipoPessoa === 'PJ') {
      setValue("percentual." + type + "." + index + ".nomeEmpresarial", arrayOpcionista?.nome_empresa || '');
      setValue("percentual." + type + "." + index + ".cnpj", arrayOpcionista?.cnpj || '');
      percentual[type][index].nomeEmpresarial = arrayOpcionista?.nome_empresa || '';
      percentual[type][index].cnpj = arrayOpcionista?.cnpj || '';
    }
    else {
      setValue("percentual." + type + "." + index + ".nomeEmpresarial", '');
      setValue("percentual." + type + "." + index + ".cnpj", '');
      setValue("percentual." + type + "." + index + ".cpf", arrayOpcionista?.cpf_cnpj || '');
      percentual[type][index].nomeEmpresarial = '';
      percentual[type][index].cnpj = '';
    }
  };

  const handleSelect = (e, origin, type, index) => {
    const value = e.target.value;
    const patch = type === 'empresa' ? origin + '.' + type + ('.' + index + '.banco_id') : origin + '.' + type + (index !== undefined ? ('.' + index + '.banco_id') : ('.banco_id'));

    setValue(patch, value);
  };

  const handleAutoComplete = (e, type, index) => {
    const loja = listEmpresas.find((loja) => loja.nome_empresarial === e);

    if (type === 'repasse') {
      setValue('franquia.repasseFranquia.nomeEmpresarial', e);
      setValue('franquia.repasseFranquia.empresa_id', loja.empresa_id);
      setValue('franquia.repasseFranquia.cnpj', loja.cnpj);
      setValue('franquia.repasseFranquia.creci', loja.creci);
      setValue('franquia.repasseFranquia.chavePix', loja.pix);
      setValue('franquia.repasseFranquia.banco_id', loja.banco_id);
      setValue('franquia.repasseFranquia.agencia', loja.agencia);
      setValue('franquia.repasseFranquia.conta', loja.numero_conta);
      clearErrors('franquia.repasseFranquia');
    }
    else {
      setValue('franquia.empresa.' + index + '.nomeEmpresarial', e);
      setValue('franquia.empresa.' + index + '.id', loja.empresa_id);
      setValue('franquia.empresa.' + index + '.cnpj', loja.cnpj);
      setValue('franquia.empresa.' + index + '.creci', loja.creci);
      setValue('franquia.empresa.' + index + '.chavePix', loja.pix);
      setValue('franquia.empresa.' + index + '.banco_id', loja.banco_id);
      setValue('franquia.empresa.' + index + '.agencia', loja.agencia);
      setValue('franquia.empresa.' + index + '.conta', loja.numero_conta);

      franquia.empresa[index].nomeEmpresarial = e;
      franquia.empresa[index].id = loja.empresa_id;
      franquia.empresa[index].cnpj = loja.cnpj;
      franquia.empresa[index].creci = loja.creci;
      franquia.empresa[index].chavePix = loja.pix;
      franquia.empresa[index].banco_id = loja.banco_id;
      franquia.empresa[index].agencia = loja.agencia;
      franquia.empresa[index].conta = loja.numero_conta;

      clearErrors('franquia.empresa');
    }
  };

  // FUNÇÕES PARA OS CAMPOS DE PARCELAS
  const limitValidateParcela = () => {
    let value = 0;
    const parcelas = watch('parcelas');
    const totalLiquido = watch('valor_comissao_liquida').replace(/[R$.]+/g, '');
    parcelas.forEach((parcela) => {
      const valor = parcela.valor.replace(/[R$.]+/g, '')
      value = value + Number(valor.replace(",", "."));
    })
    if (value > totalLiquido.replace(",", ".")) return "Valor inválido"
    else return true
  };

  function calculoDeducao() {
    const deducao = watch('deducao').replace(/[R$.]+/g, '');
    const bruto = watch('valor_comissao_total').replace(/[R$.]+/g, '');
    const valorliquida = (bruto.replace(",", ".") - deducao.replace(",", ".")).toFixed(2);
    setValue('valor_comissao_liquida', formatoMoeda(valorliquida));
    calcularTotalUsuarios();
    calcularTotalEmpresas();
    calculoValorTotal(watch('parcelas'));
  };

  async function removeParcela(index) {
    const parcelasAdd = watch("parcelas");
    if (parcelasAdd[index].id) {

      await Axios.post(app + 'excluir_parcela', {
        id_parcela: parcelasAdd[index].id,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          // "Content-Type": "multipart/form-data"
        }
      })
        .then(res => {
          if (res?.data?.mensagem_parcela === "Parcela excluída") {
            parcelasAdd.splice(index, 1);
            setValue('parcelas', parcelasAdd);
            unregister('parcelas.' + index);
            clearErrors('parcelas.' + index);
            recarregarProcesso();
            calculoValorTotal(watch("parcelas"));
          } else {
            toaster.notify(res?.data?.mensagem_parcela || msgError)
          }
        })
        .catch(error => {
          console.log(error);
        })
    } else {
      parcelasAdd.splice(index, 1);
      setValue('parcelas', parcelasAdd);
      unregister('parcelas.' + index);
      clearErrors('parcelas.' + index);
      calculoValorTotal(watch("parcelas"));
    }
  };

  function addParcela() {
    const parcelasAdd = [...watch("parcelas"), parcela];
    calculoValorTotal(parcelasAdd);
  };

  function calculoValorTotal(campo) {
    const valorLiquido = (watch('valor_comissao_liquida').replace(/[R$.]+/g, '')).replace(",", ".");
    let count = 0
    campo.forEach((parcela, index) => {
      let value = (valorLiquido / campo.length).toFixed(2);
      if (count !== valorLiquido.replace(",", ".") && index === campo.length - 1) {
        value = Number(valorLiquido - count).toFixed(2);
      }
      count += Number(value)
      parcela.valor = formatoMoeda(value);
    });
    setValue('parcelas', campo);
  };

  const handleTotalComissao = (e) => {
    const tipoComissao = e.target.value;
    setValue('radioTipoComissao', tipoComissao);
    const parcelada = watch('parcelas').length <= 1 ? inicialDataParcela : [...watch('parcelas'), parcela];
    clearErrors();
    if (tipoComissao === 'partes') setValue('parcelas', parcelada);
    else if (tipoComissao === 'integral') setValue('parcelas', [watch('parcelas.0')]);
  };

  const onError = (errors) => {
    const divId = Object.keys(errors)[0];
    const divSelect = document.getElementById(divId);
    if (divSelect) divSelect.focus();
  };

  const handleLaudoType = (e) => {
    const value = e.target.value;
    const select = laudoOptions.find((e) => e.value === value);
    const porcentagens = (select.porcentagem / percentual.opcionistas.length).toFixed(2);
    const valores = Number(comissaoLiquida.replace(",", ".")) * porcentagens / 100;
    console.log(valores);
    const opcionistas = percentual.opcionistas.map((opcionista) => ({ ...opcionista, porcentagemComissao: porcentagens, valorReal: `R$ ${valores.toFixed(2)}` }));
    setValue('percentual.opcionistas', opcionistas)
    setPercentual({ ...percentual, laudoType: value, opcionistas });

    calcularTotalEmpresa();
  };

  const handleDCValorPorcentagem = (type) => {
    //const oldPercent = watch('percentual.diretor_comercial.porcentagemComissao').replace(",", ".");
    const oldPercent = watch('percentual.diretor_comercial.porcentagemComissao');

    console.log(oldPercent);
    const oldDescont = (watch('percentual.diretor_comercial.desconto').replace(/[R$.]+/g, '')).replace(",", ".");
    const oldValue = (watch('percentual.diretor_comercial.valorReal').replace(/[R$.]+/g, '')).replace(",", ".");
    const newValue = (Number(comissaoLiquida.replace(",", ".")) * oldPercent / 100) - oldDescont;
    const newPercent = (oldValue + oldDescont) * 100 / Number(comissaoLiquida.replace(",", "."));
    console.log(newPercent);

    if (type === 'valor') setValue('percentual.diretor_comercial.porcentagemComissao', newPercent.toFixed(2))
    else setValue('percentual.diretor_comercial.valorReal', formatoMoeda(newValue.toFixed(2)))
  };

  console.log('Watch: ', watch());
  console.log('Error: ', errors);
  // console.log('Processo: ', selectProcess);

  return (
    <Pane id="formGroup" className='formGroup formAdd pl10' >
      <h2 className='subtitle mt20 mb20'>Dados da venda</h2>

      {planilha_antiga &&
        <div className='mb20'>
          <p className='fw400 f12 mb5'>{planilha?.length > 1 ? "(Arquivos enviados pelo Gerente)" : "(Arquivo enviado pelo Gerente)"}</p>
          {planilha?.map((documento) =>
            <Button appearance="primary" className='mb10' onClick={(e) => [ShowDocument(documento.id, 'documento', token), e.preventDefault()]}>
              Download {documento.tipo_documento.nome.toLowerCase()}
            </Button>
          )}
        </div>
      }

      <div className="rowForm mb25">
        <TextInputField
          width={150}
          label="Código do imóvel"
          placeholder="Ex: DNA123456"
          value={selectProcess.imovel?.codigo || '---'}
          disabled={true}
        />

        <TextInputField
          width={150}
          label="Loja"
          placeholder="Ex: Botafogo"
          value={selectProcess.loja?.nome || '---'}
          disabled={true}
        />

        <TextInputField
          width={150}
          label="Data da assinatura"
          placeholder="Ex: 31/05/2022"
          value={selectProcess.imovel?.informacao?.data_assinatura || '---'}
          disabled={true}
          marginLeft={20}
        />
      </div>


      <h2 className='subtitle mb29'>Dados da comissão</h2>
      <div className="mb20">Sobre valores</div>
      {/* Radio Tipo Comissao */}
      <div className="rowForm line-radius radius-select">
        <RadioGroup
          label=""
          size={16}
          defaultValue={watch('radioTipoComissao')}
          options={optionsRadio}
          className="radius mb10"
          onChange={(e) => handleTotalComissao(e)}
        />
        <input
          type="hidden"
          className="hidden-comissao"
          {...register("radioTipoComissao", {
            required: true,
          })}
        />
        {errors.radioTipoComissao &&
          <div className="error-genero">
            <span> <ErrorIcon /> {errorTextInput} </span>
          </div>
        }
      </div>

      <div className="mt20 flex divChildMr20">
        <TextInputField
          width={150}
          label="Comissão total *"
          placeholder="Ex: R$ 500.000,00"
          value={watch('valor_comissao_total')}
          style={watch('valor_comissao_total') ? inputGreen : errors.valor_comissao_total ? inputError : inputDefault}
          validationMessage={errors.valor_comissao_total && errorTextInput}
          {...register("valor_comissao_total", {
            required: true,
            setValueAs: e => formatoMoeda(e)
          })}
        />

        <TextInputField
          width={150}
          label="Deduções"
          placeholder="Ex: R$ 500.000,00"
          value={watch('deducao')}
          style={watch('deducao') ? inputGreen : errors.deducao ? inputError : {}}
          {...register("deducao", {
            required: false,
            setValueAs: e => formatoMoeda(e),
            onBlur: () => calculoDeducao()
          })}
        />
      </div>

      <div className='divChildMr20'>
        {watch("parcelas").map((parcela, index) => (
          <div className='flex' id={'parcelas'} tabIndex={0}>
            <div hidden={watch('radioTipoComissao') === 'integral'} className="mr0">
              <TextInputField
                width={150}
                label={(index + 1) + "º Parcela*"}
                name={'parcela.' + index + '.valor'}
                placeholder="Ex: R$ 500.000,00"
                value={parcela.valor}
                style={parcela.valor ? inputGreen : errors.parcelas?.[index]?.valor ? inputError : inputDefault}
                validationMessage={errors.parcelas?.[index]?.valor && errors.parcelas?.[index]?.valor.message}
                {...register('parcelas.' + index + '.valor', {
                  required: watch('radioTipoComissao') === 'partes' && errorTextInput,
                  setValueAs: e => formatoMoeda(e),
                  validate: () => limitValidateParcela(),
                })}
              />
            </div>
            <SelectField
              width={250}
              label="Período do pagamento*"
              value={parcela.periodo}
              className={parcela.periodo ? 'inputGreenSelect'
                : errors.parcelas?.[index]?.periodo ? 'inputRedSelect' : ''
              }
              {...register('parcelas.' + index + '.periodo', {
                required: true,
                onChange: e => setValue('parcelas.' + index + '.periodo', e.target.value)
              })}
              validationMessage={errors.parcelas?.[index]?.periodo && errorTextInput}
            >
              <option value="" hidden>Selecione o período</option>
              {listaPeriodo.map((item) => (
                <option value={item.value}>{item.label}</option>
              ))}
            </SelectField>

            {(index > 0 && index === watch('parcelas').length - 1) &&
              <Button className='mt26' appearance='primary' intent='danger' onClick={() => removeParcela(index)}><CrossIcon color='white' /></Button>
            }
          </div>
        ))}
      </div>
      <div className='mb20'>
        {watch('radioTipoComissao') === 'partes' &&
          <Button iconBefore={PlusIcon} onClick={() => addParcela()}>Adicionar parcela</Button>}
      </div>

      <div className="row-recibo">
        <div className="mb20">Líquida*</div>
        <div className="rowForm line-radius">
          <RadioGroup
            size={14}
            marginTop={0}
            value={watch("liquida")}
            options={optionsRadioLiquida}
            onChange={(e) => setValue("liquida", e.target.value)}
            className="radius"
          />
        </div>
        <input type="hidden" {...register("liquida", { required: true })} />
        {errors.liquida &&
          <div className="error-genero">
            <span> <ErrorIcon /> {errorTextInput} </span>
          </div>
        }
      </div>

      <div className="rowForm" style={{ marginTop: '10px' }}>
        <TextInputField
          required
          width={150}
          label="Comissão líquida"
          placeholder="Ex: R$ 100.000,00"
          disabled={watch("liquida") === null}
          value={watch('valor_comissao_liquida')}
          style={watch('valor_comissao_liquida') ? inputGreen : errors.valor_comissao_liquida ? inputError : {}}
          validationMessage={errors.valor_comissao_liquida && errorTextInput}
          {...register("valor_comissao_liquida", {
            required: true,
            setValueAs: (e) => formatoMoeda(e),
            onChange: (e) => handleComissaoLiquida(e.target.value)
          })}
        />
      </div>

      {!planilha_antiga &&
        <Pane>

          {/* INPUTS DE FRANQUIA */}
          <Pane>

            {/* FRANQUIA - REPASSE FRANQUIA */}
            <Pane className='mt29'>
              {/*<div hidden={watch('radioFranquia') === 'false'}>*/}
              <span className='fw600'>Repasse Franquia</span>
              <div className="rowForm line-radius radius-select mt8">
                <RadioGroup
                  label=""
                  size={16}
                  defaultValue={watch('radioFranquiaRepasse')}
                  options={optionsFranquia}
                  className="radius"
                  onChange={(e) => [setValue("radioFranquiaRepasse", e.target.value), calcularTotalEmpresa()]}
                />
              </div>
              {/*</div>*/}

              <div className="border-box" hidden={watch('radioFranquiaRepasse') === 'false'}>
                <div className='mr20 flex'>
                  <div className='w250 mr16'>

                    <Pane className='w250 mr20'>
                      <Autocomplete
                        onChange={e => handleAutoComplete(e, 'repasse', null)}
                        items={[...new Set(listEmpresas.map((lojas) => lojas.nome_empresarial))]}
                        selectedItem={watch('franquia.repasseFranquia.nomeEmpresarial')}
                      >
                        {props => {
                          const { getInputProps, getRef, inputValue, openMenu } = props
                          return (
                            <TextInputField
                              label={"Nome empresarial*"}
                              placeholder={"Nome empresarial*"}
                              value={inputValue}
                              ref={getRef}
                              validationMessage={errors?.franquia?.repasseFranquia?.nomeEmpresarial && errorTextInput}
                              style={watch('franquia.repasseFranquia.nomeEmpresarial') ? inputGreen : errors?.franquia?.repasseFranquia?.nomeEmpresarial ? inputError : inputDefault}
                              {...getInputProps({
                                onClick: () => {
                                  openMenu()
                                }
                              })}
                            />
                          )
                        }}
                      </Autocomplete>

                      <input
                        {...register('franquia.repasseFranquia.nomeEmpresarial', {
                          required: watch('radioFranquiaRepasse') === 'true',
                        })}
                        hidden
                      />
                    </Pane>
                  </div>

                  <TextInputField
                    label="CNPJ*"
                    type="text"
                    marginRight={16}
                    placeholder='---'
                    {...register('franquia.repasseFranquia.cnpj', {
                      // required: watch('radioFranquiaRepasse') === 'true',
                      // onChange: e => handleChangeText(e.target.value, null, 'cnpj', 'franquia')
                    })}
                    // validationMessage={errors?.franquia?.repasseFranquia?.cnpj && errors.franquia.repasseFranquia.cnpj.message}
                    // style={watch('franquia.repasseFranquia.cnpj') ? inputGreen : errors?.franquia?.repasseFranquia?.cnpj ? inputError : inputDefault}
                    disabled
                  />

                  <TextInputField
                    label="CRECI*"
                    type="text"
                    placeholder='---'
                    {...register('franquia.repasseFranquia.creci')}
                    // style={watch('franquia.repasseFranquia.creci') ? inputGreen : inputDefault}
                    disabled
                  />
                </div>

                <div className='flex'>
                  <div className='flex w134 mr16'>
                    <TextInputField
                      label="Porcentagem*"
                      className='pr25'
                      type='number'
                      style={errors?.franquia?.repasseFranquia?.ajskajskajs ? inputError : watch('franquia.repasseFranquia.porcentagem') ? inputGreen : inputDefault}
                      {...register('franquia.repasseFranquia.porcentagem', {
                        required: watch('radioFranquiaRepasse') === 'true' && errorTextInput,
                        onChange: (e) => handleChangeText(e.target.value, null, 'porcentagem', 'repasseFranquia'),
                        //validate: value => value < 100 || porcentagemInvalida,
                      })}
                      validationMessage={errors.franquia?.repasseFranquia?.porcentagem && errors.franquia.repasseFranquia.porcentagem.message}
                    />
                    <span className='sobrepor mr10'>%</span>
                  </div>
                  <div className='w120'>
                    <TextInputField
                      label="Valor"
                      placeholder="R$"
                      disabled={false}
                      //value={valorRepasse}
                      {...register('franquia.repasseFranquia.valor', {
                        value: valorRepasse,
                        required: watch('radioFranquiaRepasse') === 'true' && errorTextInput,
                        onChange: (e) => handleChangeText(e.target.value, null, 'valor', 'repasseFranquia'),
                      })}
                      style={errors?.franquia?.repasseFranquia?.valor ? inputError : watch('franquia.repasseFranquia.valor') ? inputGreen : inputDefault}
                      validationMessage={errors.franquia?.repasseFranquia?.valor && errors.franquia.repasseFranquia.valor.message}
                    />
                  </div>
                </div>

                {/* LINHA DE DADOS BANCARIOS */}
                <div className='flex'>
                  <div className="line-radius radius-select mr10">
                    <span className=''>Dados bancários:</span>
                    <RadioGroup
                      label=""
                      size={16}
                      defaultValue={watch('franquia.repasseFranquia.dadosBancarios')}
                      options={dadosBancarios}
                      className="radius"
                      onChange={(e) => setValue('franquia.repasseFranquia.dadosBancarios', e.target.value)}
                    />
                  </div>

                  {/* CASO PIX */}
                  <div className='w392' hidden={watch('franquia.repasseFranquia.dadosBancarios') !== 'pix'}>
                    <TextInputField
                      label="Chave Pix*"
                      type="text"
                      placeholder="CPF, celular ou e-mail"
                      {...register('franquia.repasseFranquia.chavePix', {
                        required: watch('radioFranquiaRepasse') === 'true' && watch('franquia.repasseFranquia.dadosBancarios') === 'pix',
                      })}
                      validationMessage={errors?.franquia?.repasseFranquia?.chavePix && errorTextInput}
                      style={watch('franquia.repasseFranquia.chavePix') ? inputGreen : errors?.franquia?.repasseFranquia?.chavePix ? inputError : inputDefault}
                    />
                  </div>

                  {/* CASO BANCO */}
                  <div hidden={watch('franquia.repasseFranquia.dadosBancarios') !== 'banco'}>
                    <div className='flex'>
                      <SelectField
                        hidden={watch('franquia.repasseFranquia.dadosBancarios') !== 'banco'}
                        label="Banco*"
                        marginRight={10}
                        minWidth={120}
                        className={(
                          watch('franquia.repasseFranquia.banco_id') ? 'inputGreenSelect bgWhite' :
                            errors?.franquia?.repasseFranquia?.banco_id ? 'inputRedSelect bgWhite' : 'bgWhite'
                        )}
                        value={watch('franquia.repasseFranquia.banco_id')}
                        onChange={(e) => handleSelect(e, 'franquia', 'repasseFranquia')}
                      >
                        <option value="" hidden selected >Selecione</option>
                        {listBank.map((bank) =>
                          <option value={bank.id} > {bank.nome}</option>
                        )}
                      </SelectField>

                      <TextInputField
                        label="Agência*"
                        type="text"
                        placeholder="1234"
                        marginRight={10}
                        width={88}
                        {...register('franquia.repasseFranquia.agencia', {
                          required: watch('radioFranquiaRepasse') === 'true' && watch('franquia.repasseFranquia.dadosBancarios') === 'banco',
                        })}
                        validationMessage={errors?.franquia?.repasseFranquia?.agencia && errorTextInput}
                        style={watch('franquia.repasseFranquia.agencia') ? inputGreen : errors?.franquia?.repasseFranquia?.agencia ? inputError : inputDefault}
                      />

                      <TextInputField
                        label="Conta*"
                        type="text"
                        width={124}
                        placeholder="21463-5"
                        {...register('franquia.repasseFranquia.conta', {
                          required: watch('radioFranquiaRepasse') === 'true' && watch('franquia.repasseFranquia.dadosBancarios') === 'banco',
                        })}
                        validationMessage={errors?.franquia?.repasseFranquia?.agencia && errorTextInput}
                        style={watch('franquia.repasseFranquia.conta') ? inputGreen : errors?.franquia?.repasseFranquia?.conta ? inputError : inputDefault}
                      />
                    </div>
                  </div>
                </div>

              </div>
            </Pane>

            {/* FRANQUIA - ROYALTIES */}
            <Pane className='mt29'>
              <div>
                <span className='fw600'>Royalties</span>

                <div className="border-box mt16">
                  <div className='flex'>
                    <div className='flex w134'>
                      <TextInputField
                        label="Porcentagem*"
                        className='pr25'
                        type='number'
                        style={errors.franquia?.royalties?.porcentagem ? inputError : watch('franquia.royalties.porcentagem') ? inputGreen : inputDefault}
                        {...register('franquia.royalties.porcentagem', {
                          required: watch('radioFranquia') === 'true' && errorTextInput,
                          //validate: value => value < 100 || porcentagemInvalida,
                          onChange: (e) => handleChangeText(e.target.value, null, 'porcentagem', 'royalties')
                        })}
                        validationMessage={errors.franquia?.royalties?.porcentagem && errors.franquia.royalties.porcentagem.message}
                      />
                      <span className='sobrepor mr10'>%</span>
                    </div>
                    <div className='flex w120'>
                      <TextInputField
                        label="Desconto"
                        placeholder="R$"
                        {...register('franquia.royalties.desconto', {
                          onChange: (e) => handleChangeText(e.target.value, null, 'desconto', 'royalties'),
                          setValueAs: e => e.replace(/[R$]+/g, '')
                        })}
                        style={watch('franquia.royalties.desconto') ? inputGreen : errors.franquia?.royalties?.desconto ? inputError : inputDefault}
                        marginLeft={20}
                      />
                    </div>
                    <div className='flex w120'>
                      <TextInputField
                        label="Valor"
                        placeholder='R$'
                        disabled={false}
                        defaultValue={
                          selectProcess.imovel.dados_royalties === null
                            ? ((Number(comissaoLiquida.replace(",", ".")) * Number(watch('franquia.royalties.porcentagem')) / 100) - removeR$(watch('franquia.royalties.desconto'))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                            : formatoMoeda(selectProcess.imovel.dados_royalties?.valor_real)
                        }
                        {...register('franquia.royalties.valor', {
                          onChange: (e) => handleChangeText(e.target.value, null, 'valor', 'royalties'),
                          required: true
                        })}
                        style={watch('franquia.royalties.valor') ? inputGreen : errors.franquia?.royalties?.valor ? inputError : inputDefault}
                        marginLeft={20}
                      />
                    </div>
                  </div>

                  {/* LINHA DE DADOS BANCARIOS */}
                  <div className='flex'>
                    <div className="line-radius radius-select mr10">
                      <span className=''>Dados bancários:</span>
                      <RadioGroup
                        label=""
                        size={16}
                        defaultValue={watch('franquia.royalties.dadosBancarios')}
                        options={dadosBancarios}
                        className="radius"
                        onChange={(e) => setValue('franquia.royalties.dadosBancarios', e.target.value)}
                      />
                    </div>

                    {/* CASO PIX */}
                    <div className='w392' hidden={watch('franquia.royalties.dadosBancarios') !== 'pix'}>
                      <TextInputField
                        label="Chave Pix*"
                        type="text"
                        placeholder="CPF, celular ou e-mail"
                        {...register('franquia.royalties.chavePix', {
                          required: watch('radioFranquia') === 'true' && watch('franquia.royalties.dadosBancarios') === 'pix',
                        })}
                        validationMessage={errors?.franquia?.royalties?.chavePix && errorTextInput}
                        style={watch('franquia.royalties.chavePix') ? inputGreen : errors.franquia?.royalties?.chavePix ? inputError : inputDefault}
                      />
                    </div>

                    {/* CASO BANCO */}
                    <div hidden={watch('franquia.royalties.dadosBancarios') !== 'banco'}>
                      <div className='flex'>
                        <SelectField
                          hidden={watch('franquia.royalties.dadosBancarios') !== 'banco'}
                          label="Banco*"
                          marginRight={10}
                          minWidth={120}
                          className={(
                            watch('franquia.royalties.banco_id') ? 'inputGreenSelect bgWhite' :
                              errors?.franquia?.royalties?.banco_id ? 'inputRedSelect bgWhite' : 'bgWhite'
                          )}
                          value={watch('franquia.royalties.banco_id')}
                          onChange={(e) => handleSelect(e, 'franquia', 'royalties')}
                        >
                          <option value="" hidden selected >Selecione</option>
                          {listBank.map((bank) =>
                            <option value={bank.id} > {bank.nome}</option>
                          )}
                        </SelectField>

                        <TextInputField
                          label="Agência*"
                          type="text"
                          placeholder="1234"
                          marginRight={10}
                          width={88}
                          {...register('franquia.royalties.agencia', {
                            required: watch('radioFranquia') === 'true' && watch('franquia.royalties.dadosBancarios') === 'banco',
                          })}
                          validationMessage={errors?.franquia?.royalties?.agencia && errorTextInput}
                          style={watch('franquia.royalties.agencia') ? inputGreen : errors?.franquia?.royalties?.agencia ? inputError : inputDefault}
                        />

                        <TextInputField
                          label="Conta*"
                          type="text"
                          width={124}
                          placeholder="21463-5"
                          {...register('franquia.royalties.conta', {
                            required: watch('radioFranquia') === 'true' && watch('franquia.royalties.dadosBancarios') === 'banco',
                          })}
                          validationMessage={errors?.franquia?.royalties?.conta && errorTextInput}
                          style={watch('franquia.royalties.conta') ? inputGreen : errors?.franquia?.royalties?.conta ? inputError : inputDefault}
                        />
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </Pane>

            {/* FRANQUIA - COMUNICAÇÃO */}
            <Pane className='mt29 mb20'>
              <div>
                <span className='fw600'>Comunicação</span>

                <div className="border-box mt16">
                  <div className='flex'>
                    <div className='flex w134'>
                      <TextInputField
                        label="Porcentagem*"
                        className='pr25'
                        type='number'
                        style={errors.franquia?.comunicacao?.porcentagem ? inputError : watch('franquia.comunicacao.porcentagem') ? inputGreen : inputDefault}
                        {...register('franquia.comunicacao.porcentagem', {
                          required: watch('radioFranquia') === 'true' && errorTextInput,
                          //validate: value => value < 100 || porcentagemInvalida,
                          onChange: (e) => handleChangeText(e.target.value, null, 'porcentagem', 'comunicacao'),
                        })}
                        validationMessage={errors.franquia?.comunicacao?.porcentagem && errors.franquia.comunicacao.porcentagem.message}
                      />
                      <span className='sobrepor mr10'>%</span>
                    </div>
                    <div className='flex w120'>
                      <TextInputField
                        label="Desconto"
                        placeholder="R$"
                        {...register('franquia.comunicacao.desconto', {
                          onChange: (e) => handleChangeText(e.target.value, null, 'desconto', 'comunicacao'),
                          setValueAs: e => e.replace(/[R$]+/g, '')
                        })}
                        style={watch('franquia.comunicacao.desconto') ? inputGreen : inputDefault}
                        marginLeft={20}
                      />
                    </div>

                    <div className='flex w120'>
                      <TextInputField
                        label="Valor"
                        placeholder='R$'
                        disabled={false}
                        defaultValue={
                          selectProcess.imovel.dados_comunicacao === null
                            ? ((Number(comissaoLiquida.replace(",", ".")) * Number(watch('franquia.comunicacao.porcentagem')) / 100) - removeR$(watch('franquia.comunicacao.desconto'))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                            : formatoMoeda(selectProcess.imovel.dados_comunicacao?.valor_real)
                        }
                        {...register('franquia.comunicacao.valor', {
                          onChange: (e) => handleChangeText(e.target.value, null, 'valor', 'comunicacao'),
                          required: true
                        })}
                        marginLeft={20}
                        style={errors.franquia?.comunicacao?.valor ? inputError : watch('franquia.comunicacao.valor') ? inputGreen : inputDefault}
                        //style={watch('franquia.comunicacao.valor') || franquia?.comunicacao?.valor ? inputGreen : errors.franquia?.comunicacao?.valor ? inputError : inputDefault}
                        validationMessage={errors.franquia?.comunicacao?.valor && errors.franquia.comunicacao.valor.message}
                      />
                    </div>
                  </div>

                  {/* LINHA DE DADOS BANCARIOS */}
                  <div className='flex'>
                    <div className="line-radius radius-select mr10">
                      <span className=''>Dados bancários:</span>
                      <RadioGroup
                        label=""
                        size={16}
                        defaultValue={watch('franquia.comunicacao.dadosBancarios')}
                        options={dadosBancarios}
                        className="radius"
                        onChange={(e) => setValue('franquia.comunicacao.dadosBancarios', e.target.value)}
                      />
                    </div>

                    {/* CASO PIX */}
                    <div className='w392' hidden={watch('franquia.comunicacao.dadosBancarios') !== 'pix'}>
                      <TextInputField
                        label="Chave Pix*"
                        type="text"
                        placeholder="CPF, celular ou e-mail"
                        {...register('franquia.comunicacao.chavePix', {
                          required: watch('radioFranquia') === 'true' && watch('franquia.comunicacao.dadosBancarios') === 'pix'
                        })}
                        validationMessage={errors?.franquia?.comunicacao?.chavePix && errorTextInput}
                        style={watch('franquia.comunicacao.chavePix') ? inputGreen : errors.franquia?.comunicacao?.chavePix ? inputError : inputDefault}
                      />
                    </div>

                    {/* CASO BANCO */}
                    <div hidden={watch('franquia.comunicacao.dadosBancarios') !== 'banco'}>
                      <div className='flex'>
                        <SelectField
                          hidden={watch('franquia.comunicacao.dadosBancarios') !== 'banco'}
                          label="Banco*"
                          marginRight={10}
                          minWidth={120}
                          className={(
                            watch('franquia.comunicacao.banco_id') ? 'inputGreenSelect bgWhite' :
                              errors?.franquia?.comunicacao?.banco_id ? 'inputRedSelect bgWhite' : 'bgWhite'
                          )}
                          value={watch('franquia.comunicacao.banco_id')}
                          onChange={(e) => handleSelect(e, 'franquia', 'comunicacao')}
                        >
                          <option value="" hidden selected >Selecione</option>
                          {listBank.map((bank) =>
                            <option value={bank.id} > {bank.nome}</option>
                          )}
                        </SelectField>

                        <TextInputField
                          label="Agência*"
                          type="text"
                          placeholder="1234"
                          marginRight={10}
                          width={88}
                          {...register('franquia.comunicacao.agencia', {
                            required: watch('radioFranquia') === 'true' && watch('franquia.comunicacao.dadosBancarios') === 'banco'
                          })}
                          validationMessage={errors?.franquia?.comunicacao?.agencia && errorTextInput}
                          style={watch('franquia.comunicacao.agencia') ? inputGreen : errors?.franquia?.comunicacao?.agencia ? inputError : inputDefault}
                        />

                        <TextInputField
                          label="Conta*"
                          type="text"
                          width={124}
                          placeholder="21463-5"
                          {...register('franquia.comunicacao.conta', {
                            required: watch('radioFranquia') === 'true' && watch('franquia.comunicacao.dadosBancarios') === 'banco'
                          })}
                          validationMessage={errors?.franquia?.comunicacao?.agencia && errorTextInput}
                          style={watch('franquia.comunicacao.conta') ? inputGreen : errors.franquia?.comunicacao?.conta ? inputError : inputDefault}
                        />
                      </div>
                    </div>
                  </div>


                </div>
              </div>

            </Pane>

          </Pane>

          <div className="subtitle mt30">
            Comissões e porcentagens
          </div>

          {/* INPUTS GERENTES GERAIS */}
          <Pane className='mt29'>
            <div className="mb15">
              <span className='fw600 mt20 mb-15'>Gerentes Gerais</span>
            </div>

            {
              comissao_gerente_gg.loja.gerente_geral_id === null
                ? <div style={{ color: '#474D66', fontWeight: '400', marginBottom: '15px' }}>A loja {comissao_gerente_gg.loja.nome} não possui nenhum Gerente Geral no momento, entretanto é possível adicionar um profissional para receber a comissão equivalente.</div>
                : ''
            }

            {percentual.gerentesGerais?.map((gerenteGeral, index) => (

              <div className="border-box mb10">
                {/* RADIO TIPO PESSOA */}
                <div>
                  <div className="rowForm line-radius radius-select">
                    <RadioGroup
                      label=""
                      size={16}
                      defaultValue={watch('percentual.gerentesGerais.' + index + '.tipoPessoa')}
                      options={optionsTipoPessoa}
                      className="radius mb10"
                      onChange={(e) => [setValue('percentual.gerentesGerais.' + index + '.tipoPessoa', e.target.value),
                      (
                        watch('percentual.gerentesGerais.' + index + '.tipoPessoa') === 'PF'
                          ? setValue('percentual.gerentesGerais.' + index + '.cpf', gerenteGeral.cpf)
                          : setValue('percentual.gerentesGerais.' + index + '.cnpj', gerenteGeral.cnpj)
                      ), handleTipoPessoa(index, 'gerentesGerais', e.target.value)
                      ]}
                    />
                    <input
                      type="hidden"
                      className="hidden-comissao"
                      // value={totalRadiusComissao}
                      {...register('percentual.gerentesGerais.' + index + '.tipoPessoa', {
                        required: true,
                      })}
                    />
                    {errors.optionsTipoPessoa &&
                      <div className="error-genero">
                        <span> <ErrorIcon /> {errorTextInput} </span>
                      </div>
                    }
                  </div>
                </div>

                {/* INPUTS NOME E CPF */}
                <div className='flex'>
                  {
                    watch('percentual.gerentesGerais.' + index + '.tipoPessoa') === 'PF' ?

                      <Pane className='w250 mr20'>
                        <Autocomplete
                          onChange={e => handleChangeText(e, index, 'nome', 'gerentesGerais')}
                          items={listOpcionistas.map((opcionista) => opcionista.nome)}
                          allowOtherValues={true}
                          selectedItem={gerenteGeral.nome}
                        >
                          {props => {
                            const { getInputProps, getRef, inputValue, openMenu } = props
                            return (
                              <TextInputField
                                label={"Nome completo do gerente geral*"}
                                placeholder={"Nome completo do gerente geral*"}
                                value={inputValue}
                                ref={getRef}
                                validationMessage={errors.percentual?.gerentesGerais?.[index]?.nome && errors.percentual.gerentesGerais[index].nome.message}
                                style={gerenteGeral.nome ? inputGreen : errors.percentual?.gerentesGerais?.[index]?.nome ? inputError : inputDefault}
                                {...getInputProps({
                                  onClick: () => {
                                    openMenu()
                                  }
                                })}
                              />
                            )
                          }}
                        </Autocomplete>

                        <input
                          {...register('percentual.gerentesGerais.' + index + '.nome', {
                            required: errorTextInput,
                          })}
                          hidden
                        />
                      </Pane>
                      :
                      <div className='w250 mr20'>
                        <TextInputField
                          label="Nome empresarial*"
                          type="text"
                          placeholder='Nome empresarial*'
                          {...register('percentual.gerentesGerais.' + index + '.nomeEmpresarial', {
                            required: 'Campo obrigatório',
                          })}
                          validationMessage={errors.percentual?.gerentesGerais?.[index]?.nomeEmpresarial && errors.percentual.gerentesGerais[index].nomeEmpresarial.message}
                          style={gerenteGeral.nomeEmpresarial ? inputGreen : errors.percentual?.gerentesGerais?.[index]?.nomeEmpresarial ? inputError : inputDefault}
                          onChange={(e) => [percentual.gerentesGerais[index].nomeEmpresarial = e.target.value, setPercentual({ ...percentual })]}
                        />
                      </div>
                  }
                  <div className='flex'>
                    <TextInputField
                      label={watch('percentual.gerentesGerais.' + index + '.tipoPessoa') === 'PF' ? "CPF*" : 'CNPJ/MEI*'}
                      type='text'
                      maxLength={watch('percentual.gerentesGerais.' + index + '.tipoPessoa') === 'PF' ? '14' : '18'}
                      placeholder={watch('percentual.gerentesGerais.' + index + '.tipoPessoa') === 'PF' ? '000.000.000-00' : '00.000.000/0000-00'}
                      {...register('percentual.gerentesGerais.' + index + (watch('percentual.gerentesGerais.' + index + '.tipoPessoa') === 'PF' ? '.cpf' : '.cnpj'), {
                        required: "Campo obrigatório",
                        maxLength: watch('percentual.gerentesGerais.' + index + '.tipoPessoa') === 'PF' ? '14' : '18',
                        validate: (value) => {
                          if (watch('percentual.gerentesGerais.' + index + '.tipoPessoa') === 'PJ') {
                            if (!validarCNPJ(value)) return "CNPJ/MEI inválido";
                          } else {
                            if (!validarCPF(value)) return "CPF inválido";
                          }
                        },
                        onChange: (e) => handleChangeText(e.target.value, index, (gerenteGeral.tipoPessoa === 'PF' ? 'cpf' : 'cnpj'), 'gerentesGerais'),
                      })}
                      width={160}
                      marginLeft={5}
                      style={
                        watch('percentual.gerentesGerais.' + index + '.tipoPessoa') === 'PF'
                          ? gerenteGeral.cpf ? inputGreen : errors.percentual?.gerentesGerais?.[index]?.cpf ? inputError : inputDefault
                          : gerenteGeral.cnpj ? inputGreen : errors.percentual?.gerentesGerais?.[index]?.cnpj ? inputError : inputDefault
                      }
                      validationMessage={
                        watch('percentual.gerentesGerais.' + index + '.tipoPessoa') === 'PF'
                          ? errors.percentual?.gerentesGerais?.[index]?.cpf && errors.percentual.gerentesGerais[index].cpf.message
                          : errors.percentual?.gerentesGerais?.[index]?.cnpj && errors.percentual.gerentesGerais[index].cnpj.message
                      }
                    />
                  </div>
                </div>

                {/* INPUTS PORCENTAGEM DESCONTO VALOR */}
                <div className="flex">
                  <div className='flex'>
                    <TextInputField
                      label="Porcentagem da comissão*"
                      className='w200 pr25'
                      type='number'
                      style={errors.percentual?.gerentesGerais?.[index]?.porcentagemComissao ? inputError : gerenteGeral.porcentagemComissao ? inputGreen : inputDefault}
                      {...register('percentual.gerentesGerais.' + index + '.porcentagemComissao', {
                        required: errorTextInput,
                        //validate: value => limitValidate('gerentesGerais') || porcentagemInvalida,
                        onChange: (e) => handleChangeText(e.target.value, index, 'porcentagemComissao', 'gerentesGerais')

                      })}
                      validationMessage={errors.percentual?.gerentesGerais?.[index]?.porcentagemComissao && errors.percentual.gerentesGerais[index].porcentagemComissao.message}
                    />
                    <span className='sobrepor mr10'>%</span>
                  </div>
                  <div className='flex w120'>
                    <TextInputField
                      label="Desconto"
                      //value={gerenteGeral.desconto}
                      placeholder="R$"
                      {...register('percentual.gerentesGerais.' + index + '.desconto', {
                        onChange: (e) => handleChangeText(e.target.value, index, 'desconto', 'gerentesGerais'),
                        setValueAs: e => e.replace(/[R$]+/g, '')
                      })}
                      style={gerenteGeral.desconto ? inputGreen : errors.percentual?.gerentesGerais?.[index]?.desconto ? inputError : inputDefault}
                      marginLeft={20}
                    />
                  </div>

                  <div className='flex w120'>
                    <TextInputField
                      label="Valor em R$"
                      disabled={false}
                      //value={((valorComissaoGerentesGerais * Number(watch('percentual.gerentesGerais.' + index + '.porcentagemComissao'))) / 100 - removeR$(watch('percentual.gerentesGerais.' + index + '.desconto'))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                      {...register('percentual.gerentesGerais.' + index + '.valorReal', {
                        onChange: (e) => handleChangeText(e.target.value, index, 'valorReal', 'gerentesGerais'),
                        //required: true
                        required: errorTextInput,
                      })}
                      style={watch('percentual.gerentesGerais.' + index + '.valorReal') ? inputGreen : errors.franquia?.gerentesGerais?.[index]?.valorReal ? inputError : inputDefault}
                      validationMessage={errors.percentual?.gerentesGerais?.[index]?.valorReal && errors.percentual.gerentesGerais[index].valorReal.message}
                      //style={errors.percentual?.gerentesGerais?.[index]?.valorReal ? inputError : gerenteGeral.valorReal ? inputGreen : inputDefault}
                      marginLeft={20}
                    />
                  </div>
                  {
                    comissao_gerente_gg.loja.gerente_geral_id === null
                      ?
                      <Button
                        type='button'
                        appearance='primary'
                        intent='danger'
                        className='ml20 mt26'
                        onClick={(e) => removeCorretor(e, 'gerentesGerais', index)}
                      >
                        Excluir
                      </Button>
                      :
                      index !== 0 &&
                      <Button
                        type='button'
                        appearance='primary'
                        intent='danger'
                        className='ml20 mt26'
                        onClick={(e) => removeCorretor(e, 'gerentesGerais', index)}
                      >
                        Excluir
                      </Button>
                  }
                </div>

                {/* LINHA DE DADOS BANCARIOS */}
                <div className='flex'>
                  <div className="line-radius radius-select mr10">
                    <span className=''>Dados bancários:</span>
                    <RadioGroup
                      label=""
                      size={16}
                      defaultValue={watch('percentual.gerentesGerais.' + index + '.dadosBancarios')}
                      options={dadosBancarios}
                      className="radius"
                      onChange={(e) => setValue('percentual.gerentesGerais.' + index + '.dadosBancarios', e.target.value)}
                    />
                  </div>

                  {/* CASO PIX */}
                  <div className='w392' hidden={watch('percentual.gerentesGerais.' + index + '.dadosBancarios') !== 'pix'}>
                    <TextInputField
                      label="Chave Pix*"
                      type="text"
                      placeholder="CPF, celular ou e-mail"
                      {...register('percentual.gerentesGerais.' + index + '.chavePix', {
                        required: watch('percentual.gerentesGerais.' + index + '.dadosBancarios') === 'pix'
                      })}
                      validationMessage={errors?.percentual?.gerentesGerais?.[index]?.chavePix && errorTextInput}
                      style={watch('percentual.gerentesGerais.' + index + '.chavePix') ? inputGreen : errors?.percentual?.gerentesGerais?.[index]?.chavePix ? inputError : inputDefault}
                    />
                  </div>

                  {/* CASO BANCO */}
                  <div hidden={watch('percentual.gerentesGerais.' + index + '.dadosBancarios') !== 'banco'}>
                    <div className='flex'>
                      <SelectField
                        hidden={watch('percentual.gerentesGerais.' + index + '.dadosBancarios') !== 'banco'}
                        label="Banco*"
                        marginRight={10}
                        minWidth={120}
                        className={(
                          watch('percentual.gerentesGerais.' + index + '.banco_id') ? 'inputGreenSelect bgWhite' :
                            errors?.franquia?.gerentesGerais?.[index]?.banco_id ? 'inputRedSelect bgWhite' : 'bgWhite'
                        )}
                        value={watch('percentual.gerentesGerais.' + index + '.banco_id')}
                        onChange={(e) => handleSelect(e, 'percentual', 'gerentesGerais', index)}
                      >
                        <option value="" hidden selected >Selecione</option>
                        {listBank.map((bank) =>
                          <option value={bank.id} > {bank.nome}</option>
                        )}
                      </SelectField>

                      <TextInputField
                        label="Agência*"
                        type="text"
                        placeholder="1234"
                        marginRight={10}
                        width={88}
                        {...register('percentual.gerentesGerais.' + index + '.agencia', {
                          required: watch('percentual.gerentesGerais.' + index + '.dadosBancarios') === 'banco'
                        })}
                        validationMessage={errors?.percentual?.gerentesGerais?.[index]?.agencia && errorTextInput}
                        style={watch('percentual.gerentesGerais.' + index + '.agencia') ? inputGreen : errors?.percentual?.gerentesGerais?.[index]?.agencia ? inputError : inputDefault}
                      />

                      <TextInputField
                        label="Conta*"
                        type="text"
                        width={124}
                        placeholder="21463-5"
                        {...register('percentual.gerentesGerais.' + index + '.conta', {
                          required: watch('percentual.gerentesGerais.' + index + '.dadosBancarios') === 'banco'
                        })}
                        validationMessage={errors?.percentual?.gerentesGerais?.[index]?.conta && errorTextInput}
                        style={watch('percentual.gerentesGerais.' + index + '.conta') ? inputGreen : errors?.percentual?.gerentesGerais?.[index]?.conta ? inputError : inputDefault}
                      />
                    </div>
                  </div>
                </div>

              </div>

            ))}

            <Button type='button' onClick={() => addCorretor('gerentesGerais')}>
              + {selectProcess.loja?.gerente_geral_id ? 'Adicionar gerente geral' : 'Adicionar profissional'}
            </Button>

          </Pane>

          {/*INPUTS GERENTES*/}
          <Pane className='mt46'>
            <div className="mb15">
              <span className='fw600 mb-15'>Gerentes</span>
            </div>

            {percentual.gerentes?.map((gerente, index) => (
              <div className="border-box mb10">
                {/* RADIO TIPO PESSOA */}
                <div>
                  <div className="rowForm line-radius radius-select">
                    <RadioGroup
                      label=""
                      size={16}
                      defaultValue={watch('percentual.gerentes.' + index + '.tipoPessoa')}
                      options={optionsTipoPessoa}
                      className="radius mb10"
                      onChange={(e) => [setValue('percentual.gerentes.' + index + '.tipoPessoa', e.target.value),
                      (
                        watch('percentual.gerentes.' + index + '.tipoPessoa') === 'PF'
                          ? setValue('percentual.gerentes.' + index + '.cpf', gerente.cpf)
                          : setValue('percentual.gerentes.' + index + '.cnpj', gerente.cnpj)
                      ), handleTipoPessoa(index, 'gerentes', e.target.value)
                      ]}
                    />
                    <input
                      type="hidden"
                      className="hidden-comissao"
                      // value={totalRadiusComissao}
                      {...register('percentual.gerentes.' + index + '.tipoPessoa', {
                        required: true,
                      })}
                    />
                    {errors.optionsTipoPessoa &&
                      <div className="error-genero">
                        <span> <ErrorIcon /> {errorTextInput} </span>
                      </div>
                    }
                  </div>
                </div>

                {/* INPUTS NOME E CPF */}
                <div className='flex'>
                  {
                    watch('percentual.gerentes.' + index + '.tipoPessoa') === 'PF' ?

                      <Pane className='w250 mr20'>
                        <Autocomplete
                          onChange={e => handleChangeText(e, index, 'nome', 'gerentes')}
                          items={listOpcionistas.map((opcionista) => opcionista.nome)}
                          allowOtherValues={true}
                          selectedItem={gerente.nome}
                        >
                          {props => {
                            const { getInputProps, getRef, inputValue, openMenu } = props
                            return (
                              <TextInputField
                                label={"Nome completo do gerente*"}
                                placeholder={"Nome completo do gerente*"}
                                value={inputValue}
                                ref={getRef}
                                validationMessage={errors.percentual?.gerentes?.[index]?.nome && errors.percentual.gerentes[index].nome.message}
                                style={gerente.nome ? inputGreen : errors.percentual?.gerentes?.[index]?.nome ? inputError : inputDefault}
                                {...getInputProps({
                                  onClick: () => {
                                    openMenu()
                                  }
                                })}
                              />
                            )
                          }}
                        </Autocomplete>

                        <input
                          {...register('percentual.gerentes.' + index + '.nome', {
                            required: errorTextInput,
                          })}
                          hidden
                        />
                      </Pane>
                      :
                      <div className='w250 mr20'>
                        <TextInputField
                          label="Nome empresarial*"
                          type="text"
                          placeholder='Nome empresarial*'
                          {...register('percentual.gerentes.' + index + '.nomeEmpresarial', {
                            required: 'Campo obrigatório',
                          })}
                          validationMessage={errors.percentual?.gerentes?.[index]?.nomeEmpresarial && errors.percentual.gerentes[index].nomeEmpresarial.message}
                          style={gerente.nomeEmpresarial ? inputGreen : errors.percentual?.gerentes?.[index]?.nomeEmpresarial ? inputError : inputDefault}
                          onChange={(e) => [percentual.gerentes[index].nomeEmpresarial = e.target.value, setPercentual({ ...percentual })]}
                        />
                      </div>
                  }


                  <div className='flex'>
                    <TextInputField
                      label={watch('percentual.gerentes.' + index + '.tipoPessoa') === 'PF' ? "CPF*" : 'CNPJ/MEI*'}
                      type='text'
                      // className={"gerentes-cpf-cnpj-" + index}
                      maxLength={watch('percentual.gerentes.' + index + '.tipoPessoa') === 'PF' ? '14' : '18'}
                      placeholder={watch('percentual.gerentes.' + index + '.tipoPessoa') === 'PF' ? '000.000.000-00' : '00.000.000/0000-00'}
                      {...register('percentual.gerentes.' + index + (watch('percentual.gerentes.' + index + '.tipoPessoa') === 'PF' ? '.cpf' : '.cnpj'), {
                        required: "Campo obrigatório",
                        maxLength: watch('percentual.gerentes.' + index + '.tipoPessoa') === 'PF' ? '14' : '18',
                        validate: (value) => {
                          if (watch('percentual.gerentes.' + index + '.tipoPessoa') === 'PJ') {
                            if (!validarCNPJ(value)) return "CNPJ/MEI inválido";
                          } else {
                            if (!validarCPF(value)) return "CPF inválido";
                          }
                        },
                        onChange: (e) => handleChangeText(e.target.value, index, (gerente.tipoPessoa === 'PF' ? 'cpf' : 'cnpj'), 'gerentes'),
                      })}
                      width={160}
                      marginLeft={5}
                      style={
                        watch('percentual.gerentes.' + index + '.tipoPessoa') === 'PF'
                          ? gerente.cpf ? inputGreen : errors.percentual?.gerentes?.[index]?.cpf ? inputError : inputDefault
                          : gerente.cnpj ? inputGreen : errors.percentual?.gerentes?.[index]?.cnpj ? inputError : inputDefault
                      }
                      validationMessage={
                        watch('percentual.gerentes.' + index + '.tipoPessoa') === 'PF'
                          ? errors.percentual?.gerentes?.[index]?.cpf && errors.percentual.gerentes[index].cpf.message
                          : errors.percentual?.gerentes?.[index]?.cnpj && errors.percentual.gerentes[index].cnpj.message
                      }
                    />
                  </div>
                </div>

                {/* INPUTS PORCENTAGEM DESCONTO VALOR */}
                <div className="flex">
                  <div className='flex'>
                    <TextInputField
                      label="Porcentagem da comissão*"
                      className='w200 pr25'
                      type='number'
                      style={errors.percentual?.gerentes?.[index]?.porcentagemComissao ? inputError : gerente.porcentagemComissao ? inputGreen : inputDefault}
                      {...register('percentual.gerentes.' + index + '.porcentagemComissao', {
                        required: errorTextInput,
                        //validate: value => limitValidate('gerentes') || porcentagemInvalida,
                        onChange: (e) => handleChangeText(e.target.value, index, 'porcentagemComissao', 'gerentes')

                      })}
                      validationMessage={errors.percentual?.gerentes?.[index]?.porcentagemComissao && errors.percentual.gerentes[index].porcentagemComissao.message}
                    />
                    <span className='sobrepor mr10'>%</span>
                  </div>
                  <div className='flex w120'>
                    <TextInputField
                      label="Desconto"
                      //value={gerente.desconto}
                      placeholder="R$"
                      {...register('percentual.gerentes.' + index + '.desconto', {
                        onChange: (e) => handleChangeText(e.target.value, index, 'desconto', 'gerentes'),
                        setValueAs: e => e.replace(/[R$]+/g, '')
                      })}
                      style={gerente.desconto ? inputGreen : errors.percentual?.gerentes?.[index]?.desconto ? inputError : inputDefault}
                      marginLeft={20}
                    />
                  </div>

                  <div className='flex w120'>
                    <TextInputField
                      label="Valor em R$"
                      disabled={false}
                      //value={((valorComissaoGerentes * Number(watch('percentual.gerentes.' + index + '.porcentagemComissao'))) / 100 - removeR$(watch('percentual.gerentes.' + index + '.desconto'))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                      {...register('percentual.gerentes.' + index + '.valorReal', {
                        onChange: (e) => handleChangeText(e.target.value, index, 'valorReal', 'gerentes'),
                        required: errorTextInput,
                      })}
                      validationMessage={errors.percentual?.gerentes?.[index]?.valorReal && errors.percentual.gerentes[index].valorReal.message}
                      //style={errors.percentual?.gerentes?.[index]?.valorReal ? inputError : percentual?.gerentes?.valorReal ? inputGreen : inputDefault}
                      style={watch('percentual.gerentes.' + index + '.valorReal') ? inputGreen : errors.franquia?.gerentes?.[index]?.valorReal ? inputError : inputDefault}
                      marginLeft={20}
                    />
                  </div>
                  {index !== 0 &&
                    <Button
                      type='button'
                      appearance='primary'
                      intent='danger'
                      className='ml20 mt26'
                      onClick={(e) => removeCorretor(e, 'gerentes', index)}
                    >
                      Excluir
                    </Button>
                  }
                </div>

                {/* LINHA DE DADOS BANCARIOS */}
                <div className='flex'>
                  <div className="line-radius radius-select mr10">
                    <span className=''>Dados bancários:</span>
                    <RadioGroup
                      label=""
                      size={16}
                      defaultValue={watch('percentual.gerentes.' + index + '.dadosBancarios')}
                      options={dadosBancarios}
                      className="radius"
                      onChange={(e) => setValue('percentual.gerentes.' + index + '.dadosBancarios', e.target.value)}
                    />
                  </div>

                  {/* CASO PIX */}
                  <div className='w392' hidden={watch('percentual.gerentes.' + index + '.dadosBancarios') !== 'pix'}>
                    <TextInputField
                      label="Chave Pix*"
                      type="text"
                      placeholder="CPF, celular ou e-mail"
                      {...register('percentual.gerentes.' + index + '.chavePix', {
                        required: watch('percentual.gerentes.' + index + '.dadosBancarios') === 'pix'
                      })}
                      validationMessage={errors?.percentual?.gerentes?.[index]?.chavePix && errorTextInput}
                      style={watch('percentual.gerentes.' + index + '.chavePix') ? inputGreen : errors?.percentual?.gerentes?.[index]?.chavePix ? inputError : inputDefault}
                    />
                  </div>

                  {/* CASO BANCO */}
                  <div hidden={watch('percentual.gerentes.' + index + '.dadosBancarios') !== 'banco'}>
                    <div className='flex'>
                      <SelectField
                        hidden={watch('percentual.gerentes.' + index + '.dadosBancarios') !== 'banco'}
                        label="Banco*"
                        marginRight={10}
                        minWidth={120}
                        className={(
                          watch('percentual.gerentes.' + index + '.banco_id') ? 'inputGreenSelect bgWhite' :
                            errors?.franquia?.gerentes?.[index]?.banco_id ? 'inputRedSelect bgWhite' : 'bgWhite'
                        )}
                        value={watch('percentual.gerentes.' + index + '.banco_id')}
                        onChange={(e) => handleSelect(e, 'percentual', 'gerentes', index)}
                      >
                        <option value="" hidden selected >Selecione</option>
                        {listBank.map((bank) =>
                          <option value={bank.id} > {bank.nome}</option>
                        )}
                      </SelectField>

                      <TextInputField
                        label="Agência*"
                        type="text"
                        placeholder="1234"
                        marginRight={10}
                        width={88}
                        {...register('percentual.gerentes.' + index + '.agencia', {
                          required: watch('percentual.gerentes.' + index + '.dadosBancarios') === 'banco'
                        })}
                        validationMessage={errors?.percentual?.gerentes?.[index]?.agencia && errorTextInput}
                        style={watch('percentual.gerentes.' + index + '.agencia') ? inputGreen : errors?.percentual?.gerentes?.[index]?.agencia ? inputError : inputDefault}
                      />

                      <TextInputField
                        label="Conta*"
                        type="text"
                        width={124}
                        placeholder="21463-5"
                        {...register('percentual.gerentes.' + index + '.conta', {
                          required: watch('percentual.gerentes.' + index + '.dadosBancarios') === 'banco'
                        })}
                        validationMessage={errors?.percentual?.gerengerentestesGerais?.[index]?.conta && errorTextInput}
                        style={watch('percentual.gerentes.' + index + '.conta') ? inputGreen : errors?.percentual?.gerentes?.[index]?.conta ? inputError : inputDefault}
                      />
                    </div>
                  </div>
                </div>

              </div>

            ))}

            <Button type='button' onClick={() => addCorretor('gerentes')}>+  Adicionar gerente</Button>
          </Pane>

          {/* INPUTS VENDEDORES */}
          <Pane style={{ marginTop: '35px' }}>
            <div className="mb15">
              <span className='fw600'>Corretores vendedores</span>
            </div>

            {percentual.corretores?.map((corretor, index) => (
              <div className="border-box mb10">
                {/* RADIO TIPO PESSOA */}
                <div>
                  <div className="rowForm line-radius radius-select">
                    <RadioGroup
                      label=""
                      size={16}
                      defaultValue={corretor.tipoPessoa}
                      options={optionsTipoPessoa}
                      className="radius mb10"
                      onChange={(e) => [setValue('percentual.corretores.' + index + '.tipoPessoa', e.target.value),
                      (
                        corretor.tipoPessoa === 'PF'
                          ? setValue('percentual.corretores.' + index + '.cpf', corretor.cpf)
                          : setValue('percentual.corretores.' + index + '.cnpj', corretor.cnpj)
                      ), handleTipoPessoa(index, 'corretores', e.target.value)
                      ]}
                    />
                    <input
                      type="hidden"
                      className="hidden-comissao"
                      // value={totalRadiusComissao}
                      {...register('percentual.corretores.' + index + '.tipoPessoa', {
                        required: true,
                      })}
                    />
                    {errors.optionsTipoPessoa &&
                      <div className="error-genero">
                        <span> <ErrorIcon /> {errorTextInput} </span>
                      </div>
                    }
                  </div>
                </div>

                {/* INPUTS NOME E CPF */}
                <div className='flex'>
                  {
                    corretor.tipoPessoa === 'PF' ?

                      <Pane className='w250 mr20'>
                        <Autocomplete
                          onChange={e => handleChangeText(e, index, 'nome', 'corretores')}
                          items={listOpcionistas.map((opcionista) => opcionista.nome)}
                          allowOtherValues={true}
                          selectedItem={corretor.nome}
                        >
                          {props => {
                            const { getInputProps, getRef, inputValue, openMenu } = props
                            return (
                              <TextInputField
                                label={"Nome completo do corretor*"}
                                placeholder={"Nome completo do corretor*"}
                                value={inputValue}
                                ref={getRef}
                                validationMessage={errors.percentual?.corretores?.[index]?.nome && errors.percentual.corretores[index].nome.message}
                                style={corretor.nome ? inputGreen : errors.percentual?.corretores?.[index]?.nome ? inputError : inputDefault}
                                {...getInputProps({
                                  onClick: () => {
                                    openMenu()
                                  }
                                })}
                              />
                            )
                          }}
                        </Autocomplete>

                        <input
                          {...register('percentual.corretores.' + index + '.nome', {
                            required: errorTextInput,
                          })}
                          hidden
                        />
                      </Pane>
                      :
                      <div className='w250 mr20'>
                        <TextInputField
                          label="Nome empresarial*"
                          type="text"
                          placeholder='Nome empresarial*'
                          {...register('percentual.corretores.' + index + '.nomeEmpresarial', {
                            required: 'Campo obrigatório',
                          })}
                          validationMessage={errors.percentual?.corretores?.[index]?.nomeEmpresarial && errors.percentual.corretores[index].nomeEmpresarial.message}
                          style={corretor.nomeEmpresarial ? inputGreen : errors.percentual?.corretores?.[index]?.nomeEmpresarial ? inputError : inputDefault}
                          onChange={(e) => [percentual.corretores[index].nomeEmpresarial = e.target.value, setPercentual({ ...percentual })]}
                        />
                      </div>
                  }


                  <div className='flex'>
                    <TextInputField
                      label={corretor.tipoPessoa === 'PF' ? "CPF*" : 'CNPJ/MEI*'}
                      type='text'
                      maxLength={corretor.tipoPessoa === 'PF' ? '14' : '18'}
                      placeholder={corretor.tipoPessoa === 'PF' ? '000.000.000-00' : '00.000.000/0000-00'}
                      {...register('percentual.corretores.' + index + (corretor.tipoPessoa === 'PF' ? '.cpf' : '.cnpj'), {
                        required: "Campo obrigatório",
                        maxLength: corretor.tipoPessoa === 'PF' ? '14' : '18',
                        validate: (value) => {
                          if (corretor.tipoPessoa === 'PJ') {
                            if (!validarCNPJ(value)) return "CNPJ/MEI inválido";
                          } else {
                            if (!validarCPF(value)) return "CPF inválido";
                          }
                        },
                        onChange: (e) => handleChangeText(e.target.value, index, (corretor.tipoPessoa === 'PF' ? 'cpf' : 'cnpj'), 'corretores'),
                      })}
                      width={160}
                      marginLeft={5}
                      style={
                        watch('percentual.corretores.' + index + '.tipoPessoa') === 'PF'
                          ? corretor.cpf ? inputGreen : errors.percentual?.corretores?.[index]?.cpf ? inputError : inputDefault
                          : corretor.cnpj ? inputGreen : errors.percentual?.corretores?.[index]?.cnpj ? inputError : inputDefault
                      }
                      validationMessage={
                        watch('percentual.corretores.' + index + '.tipoPessoa') === 'PF'
                          ? errors.percentual?.corretores?.[index]?.cpf && errors.percentual.corretores[index].cpf.message
                          : errors.percentual?.corretores?.[index]?.cnpj && errors.percentual.corretores[index].cnpj.message
                      }
                    />
                  </div>
                </div>

                {/* INPUTS PORCENTAGEM DESCONTO VALOR */}
                <div className="flex">
                  <div className='flex'>
                    <TextInputField
                      label="Porcentagem da comissão*"
                      className='w200 pr25'
                      type='number'
                      style={errors.percentual?.corretor?.[index]?.porcentagemComissao ? inputError : corretor.porcentagemComissao ? inputGreen : inputDefault}
                      {...register('percentual.corretores.' + index + '.porcentagemComissao', {
                        required: errorTextInput,
                        //validate: value => limitValidate('corretores') || porcentagemInvalida,
                        onChange: (e) => handleChangeText(e.target.value, index, 'porcentagemComissao', 'corretores')

                      })}
                      validationMessage={errors.percentual?.corretores?.[index]?.porcentagemComissao && errors.percentual.corretores[index].porcentagemComissao.message}
                    />
                    <span className='sobrepor mr10'>%</span>
                  </div>
                  <div className='flex w120'>
                    <TextInputField
                      label="Desconto"
                      //value={corretor.desconto}
                      placeholder="R$"
                      {...register('percentual.corretores.' + index + '.desconto', {
                        onChange: (e) => handleChangeText(e.target.value, index, 'desconto', 'corretores'),
                        setValueAs: e => e.replace(/[R$]+/g, '')
                      })}
                      style={corretor.desconto ? inputGreen : errors.percentual?.corretores?.[index]?.desconto ? inputError : inputDefault}
                      marginLeft={20}
                    />
                  </div>

                  <div className='flex w120'>
                    <TextInputField
                      label="Valor em R$"
                      disabled={false}
                      //value={((valorComissaoVendedores * Number(watch('percentual.corretores.' + index + '.porcentagemComissao'))) / 100 - removeR$(watch('percentual.corretores.' + index + '.desconto'))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                      {...register('percentual.corretores.' + index + '.valorReal', {
                        onChange: (e) => handleChangeText(e.target.value, index, 'valorReal', 'corretores'),
                        required: errorTextInput,
                      })}
                      validationMessage={errors.percentual?.corretores?.[index]?.valorReal && errors.percentual.corretores[index].valorReal.message}
                      //style={errors.percentual?.corretores?.[index]?.valorReal ? inputError : percentual.corretores.valorReal ? inputGreen : inputDefault}
                      style={watch('percentual.corretores.' + index + '.valorReal') ? inputGreen : errors.franquia?.corretores?.[index]?.valorReal ? inputError : inputDefault}
                      marginLeft={20}
                    />
                  </div>
                  {index !== 0 &&
                    <Button
                      type='button'
                      appearance='primary'
                      intent='danger'
                      className='ml20 mt26'
                      onClick={(e) => removeCorretor(e, 'corretores', index)}
                    >
                      Excluir
                    </Button>
                  }
                </div>

                {/* LINHA DE DADOS BANCARIOS */}
                <div className='flex'>
                  <div className="line-radius radius-select mr10">
                    <span className=''>Dados bancários:</span>
                    <RadioGroup
                      label=""
                      size={16}
                      defaultValue={watch('percentual.corretores.' + index + '.dadosBancarios')}
                      options={dadosBancarios}
                      className="radius"
                      onChange={(e) => setValue('percentual.corretores.' + index + '.dadosBancarios', e.target.value)}
                    />
                  </div>

                  {/* CASO PIX */}
                  <div className='w392' hidden={watch('percentual.corretores.' + index + '.dadosBancarios') !== 'pix'}>
                    <TextInputField
                      label="Chave Pix*"
                      type="text"
                      placeholder="CPF, celular ou e-mail"
                      {...register('percentual.corretores.' + index + '.chavePix', {
                        required: watch('percentual.corretores.' + index + '.dadosBancarios') === 'pix'
                      })}
                      validationMessage={errors?.percentual?.corretores?.[index]?.chavePix && errorTextInput}
                      style={watch('percentual.corretores.' + index + '.chavePix') ? inputGreen : errors?.percentual?.corretores?.[index]?.chavePix ? inputError : inputDefault}
                    />
                  </div>

                  {/* CASO BANCO */}
                  <div hidden={watch('percentual.corretores.' + index + '.dadosBancarios') !== 'banco'}>
                    <div className='flex'>
                      <SelectField
                        hidden={watch('percentual.corretores.' + index + '.dadosBancarios') !== 'banco'}
                        label="Banco*"
                        marginRight={10}
                        minWidth={120}
                        className={(
                          watch('percentual.corretores.' + index + '.banco_id') ? 'inputGreenSelect bgWhite' :
                            errors?.franquia?.corretores?.[index]?.banco_id ? 'inputRedSelect bgWhite' : 'bgWhite'
                        )}
                        value={watch('percentual.corretores.' + index + '.banco_id')}
                        onChange={(e) => handleSelect(e, 'percentual', 'corretores', index)}
                      >
                        <option value="" hidden selected >Selecione</option>
                        {listBank.map((bank) =>
                          <option value={bank.id} > {bank.nome}</option>
                        )}
                      </SelectField>

                      <TextInputField
                        label="Agência*"
                        type="text"
                        placeholder="1234"
                        marginRight={10}
                        width={88}
                        {...register('percentual.corretores.' + index + '.agencia', {
                          required: watch('percentual.corretores.' + index + '.dadosBancarios') === 'banco'
                        })}
                        validationMessage={errors?.percentual?.corretores?.[index]?.agencia && errorTextInput}
                        style={watch('percentual.corretores.' + index + '.agencia') ? inputGreen : errors?.percentual?.corretores?.[index]?.agencia ? inputError : inputDefault}
                      />

                      <TextInputField
                        label="Conta*"
                        type="text"
                        width={124}
                        placeholder="21463-5"
                        {...register('percentual.corretores.' + index + '.conta', {
                          required: watch('percentual.corretores.' + index + '.dadosBancarios') === 'banco'
                        })}
                        validationMessage={errors?.percentual?.corretores?.[index]?.conta && errorTextInput}
                        style={watch('percentual.corretores.' + index + '.conta') ? inputGreen : errors?.percentual?.corretores?.[index]?.conta ? inputError : inputDefault}
                      />
                    </div>
                  </div>
                </div>

              </div>

            ))}

            <Button type='button' onClick={() => addCorretor('corretores')}>+  Adicionar vendedor</Button>
          </Pane>

          {/* INPUTS OPCIONISTAS */}
          <Pane>
            <p className='fw600 mt40'>Opcionistas</p>
            <p className='mt20 fw400'>A soma da porcentagem de cada opcionista, deve ser no total do laudo.</p>
            <div className='mt15'>
              <spam className='fw600 mr10'>Tipo de laudo*</spam>
              {percentual.laudoType && <Badge color="blue">Total de {percentualOpcionistas(percentual.laudoType)}</Badge>}
              <div className="rowForm line-radius mb20">
                <RadioGroup
                  label=""
                  size={16}
                  value={percentual.laudoType}
                  options={laudoOptions}
                  onChange={(e) => handleLaudoType(e)}
                  className="radius"
                />
              </div>
              {percentual.opcionistas?.map((opcionista, index) => (

                <div className="border-box mb10">
                  {/* RADIO TIPO PESSOA */}
                  <div>
                    <div className="rowForm line-radius radius-select">
                      <RadioGroup
                        label=""
                        size={16}
                        defaultValue={opcionista.tipoPessoa}
                        options={optionsTipoPessoa}
                        className="radius mb10"
                        onChange={(e) => [setValue('percentual.opcionistas.' + index + '.tipoPessoa', e.target.value),
                        (
                          opcionista.tipoPessoa === 'PF'
                            ? setValue('percentual.opcionistas.' + index + '.cpf', opcionista.cpf)
                            : setValue('percentual.opcionistas.' + index + '.cnpj', opcionista.cnpj)
                        ), handleTipoPessoa(index, 'opcionistas', e.target.value)
                        ]}
                      />
                      <input
                        type="hidden"
                        className="hidden-comissao"
                        // value={totalRadiusComissao}
                        {...register('percentual.opcionistas.' + index + '.tipoPessoa', {
                          required: true,
                        })}
                      />
                      {errors.optionsTipoPessoa &&
                        <div className="error-genero">
                          <span> <ErrorIcon /> {errorTextInput} </span>
                        </div>
                      }
                    </div>
                  </div>

                  {/* INPUTS NOME E CPF */}
                  <div className='flex'>
                    {
                      opcionista.tipoPessoa === 'PF' ?

                        <Pane className='w250 mr20'>
                          <Autocomplete
                            onChange={e => handleChangeText(e, index, 'nome', 'opcionistas')}
                            items={listOpcionistas.map((opcionista) => opcionista.nome)}
                            allowOtherValues={true}
                            selectedItem={opcionista.nome}
                          >
                            {props => {
                              const { getInputProps, getRef, inputValue, openMenu } = props
                              return (
                                <TextInputField
                                  label={"Nome completo do opcionista*"}
                                  placeholder={"Nome completo do opcionista*"}
                                  value={inputValue}
                                  ref={getRef}
                                  validationMessage={errors.percentual?.opcionistas?.[index]?.nome && errors.percentual.opcionistas[index].nome.message}
                                  style={opcionista.nome ? inputGreen : errors.percentual?.opcionistas?.[index]?.nome ? inputError : inputDefault}
                                  {...getInputProps({
                                    onClick: () => {
                                      openMenu()
                                    }
                                  })}
                                />
                              )
                            }}
                          </Autocomplete>

                          <input
                            {...register('percentual.opcionistas.' + index + '.nome', {
                              required: errorTextInput,
                            })}
                            hidden
                          />
                        </Pane>
                        :
                        <div className='w250 mr20'>
                          <TextInputField
                            label="Nome empresarial*"
                            type="text"
                            placeholder='Nome empresarial*'
                            {...register('percentual.opcionistas.' + index + '.nomeEmpresarial', {
                              required: 'Campo obrigatório',
                            })}
                            validationMessage={errors.percentual?.opcionistas?.[index]?.nomeEmpresarial && errors.percentual.opcionistas[index].nomeEmpresarial.message}
                            style={opcionista.nomeEmpresarial ? inputGreen : errors.percentual?.opcionistas?.[index]?.nomeEmpresarial ? inputError : inputDefault}
                            onChange={(e) => [percentual.opcionistas[index].nomeEmpresarial = e.target.value, setPercentual({ ...percentual })]}
                          />
                        </div>
                    }


                    <div className='flex'>
                      <TextInputField
                        label={opcionista.tipoPessoa === 'PF' ? "CPF*" : 'CNPJ/MEI*'}
                        type='text'
                        maxLength={opcionista.tipoPessoa === 'PF' ? '14' : '18'}
                        placeholder={opcionista.tipoPessoa === 'PF' ? '000.000.000-00' : '00.000.000/0000-00'}
                        {...register('percentual.opcionistas.' + index + (opcionista.tipoPessoa === 'PF' ? '.cpf' : '.cnpj'), {
                          required: "Campo obrigatório",
                          maxLength: opcionista.tipoPessoa === 'PF' ? '14' : '18',
                          validate: (value) => {
                            if (opcionista.tipoPessoa === 'PJ') {
                              if (!validarCNPJ(value)) return "CNPJ/MEI inválido";
                            } else {
                              if (!validarCPF(value)) return "CPF inválido";
                            }
                          },
                          onChange: (e) => handleChangeText(e.target.value, index, (opcionista.tipoPessoa === 'PF' ? 'cpf' : 'cnpj'), 'opcionistas'),
                        })}
                        width={160}
                        marginLeft={5}
                        style={
                          watch('percentual.opcionistas.' + index + '.tipoPessoa') === 'PF'
                            ? opcionista.cpf ? inputGreen : errors.percentual?.opcionistas?.[index]?.cpf ? inputError : inputDefault
                            : opcionista.cnpj ? inputGreen : errors.percentual?.opcionistas?.[index]?.cnpj ? inputError : inputDefault
                        }
                        validationMessage={
                          watch('percentual.opcionistas.' + index + '.tipoPessoa') === 'PF'
                            ? errors.percentual?.opcionistas?.[index]?.cpf && errors.percentual.opcionistas[index].cpf.message
                            : errors.percentual?.opcionistas?.[index]?.cnpj && errors.percentual.opcionistas[index].cnpj.message
                        }
                      />
                    </div>
                  </div>

                  {/* INPUTS PORCENTAGEM DESCONTO VALOR */}
                  <div className="flex">
                    <div className='flex'>
                      <TextInputField
                        label="Porcentagem da comissão*"
                        className='w200 pr25'
                        type='number'
                        style={errors.percentual?.opcionistas?.[index]?.porcentagemComissao ? inputError : opcionista.porcentagemComissao ? inputGreen : inputDefault}
                        {...register('percentual.opcionistas.' + index + '.porcentagemComissao', {
                          required: errorTextInput,
                          //validate: value => limitValidate('opcionistas') || porcentagemInvalida,
                          onChange: (e) => handleChangeText(e.target.value, index, 'porcentagemComissao', 'opcionistas')

                        })}
                        validationMessage={errors.percentual?.opcionistas?.[index]?.porcentagemComissao && errors.percentual.opcionistas[index].porcentagemComissao.message}
                      />
                      <span className='sobrepor mr10'>%</span>
                    </div>
                    <div className='flex w120'>
                      <TextInputField
                        label="Desconto"
                        //value={opcionista.desconto}
                        placeholder="R$"
                        {...register('percentual.opcionistas.' + index + '.desconto', {
                          onChange: (e) => handleChangeText(e.target.value, index, 'desconto', 'opcionistas'),
                          setValueAs: e => e.replace(/[R$]+/g, '')
                        })}
                        style={opcionista.desconto ? inputGreen : errors.percentual?.opcionistas?.[index]?.desconto ? inputError : inputDefault}
                        marginLeft={20}
                      />
                    </div>

                    <div className='flex w120'>
                      <TextInputField
                        label="Valor em R$"
                        disabled={false}
                        //value={((valorComissaoOpicionistas * Number(watch('percentual.opcionistas.' + index + '.porcentagemComissao'))) / 100 - removeR$(watch('percentual.opcionistas.' + index + '.desconto'))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        {...register('percentual.opcionistas.' + index + '.valorReal', {
                          onChange: (e) => handleChangeText(e.target.value, index, 'valorReal', 'opcionistas'),
                          required: errorTextInput,
                        })}
                        validationMessage={errors.percentual?.opcionistas?.[index]?.valorReal && errors.percentual.opcionistas[index].valorReal.message}
                        //style={errors.percentual?.opcionistas?.[index]?.valorReal ? inputError : percentual.opcionistas.valorReal ? inputGreen : inputDefault}
                        style={watch('percentual.opcionistas.' + index + '.valorReal') ? inputGreen : errors.franquia?.opcionistas?.[index]?.valorReal ? inputError : inputDefault}
                        marginLeft={20}
                      />
                    </div>
                    {index !== 0 &&
                      <Button
                        type='button'
                        appearance='primary'
                        intent='danger'
                        className='ml20 mt26'
                        onClick={(e) => removeCorretor(e, 'opcionistas', index)}
                      >
                        Excluir
                      </Button>
                    }
                  </div>

                  {/* LINHA DE DADOS BANCARIOS */}
                  <div className='flex'>
                    <div className="line-radius radius-select mr10">
                      <span className=''>Dados bancários:</span>
                      <RadioGroup
                        label=""
                        size={16}
                        defaultValue={watch('percentual.opcionistas.' + index + '.dadosBancarios')}
                        options={dadosBancarios}
                        className="radius"
                        onChange={(e) => setValue('percentual.opcionistas.' + index + '.dadosBancarios', e.target.value)}
                      />
                    </div>

                    {/* CASO PIX */}
                    <div className='w392' hidden={watch('percentual.opcionistas.' + index + '.dadosBancarios') !== 'pix'}>
                      <TextInputField
                        label="Chave Pix*"
                        type="text"
                        placeholder="CPF, celular ou e-mail"
                        {...register('percentual.opcionistas.' + index + '.chavePix', {
                          required: watch('percentual.opcionistas.' + index + '.dadosBancarios') === 'pix'
                        })}
                        validationMessage={errors?.percentual?.opcionistas?.[index]?.chavePix && errorTextInput}
                        style={watch('percentual.opcionistas.' + index + '.chavePix') ? inputGreen : errors?.percentual?.opcionistas?.[index]?.chavePix ? inputError : inputDefault}
                      />
                    </div>

                    {/* CASO BANCO */}
                    <div hidden={watch('percentual.opcionistas.' + index + '.dadosBancarios') !== 'banco'}>
                      <div className='flex'>
                        <SelectField
                          hidden={watch('percentual.opcionistas.' + index + '.dadosBancarios') !== 'banco'}
                          label="Banco*"
                          marginRight={10}
                          minWidth={120}
                          className={(
                            watch('percentual.opcionistas.' + index + '.banco_id') ? 'inputGreenSelect bgWhite' :
                              errors?.franquia?.opcionistas?.[index]?.banco_id ? 'inputRedSelect bgWhite' : 'bgWhite'
                          )}
                          value={watch('percentual.opcionistas.' + index + '.banco_id')}
                          onChange={(e) => handleSelect(e, 'percentual', 'opcionistas', index)}

                        >
                          <option value="" hidden selected >Selecione</option>
                          {listBank.map((bank) =>
                            <option value={bank.id} > {bank.nome}</option>
                          )}
                        </SelectField>

                        <TextInputField
                          label="Agência*"
                          type="text"
                          placeholder="1234"
                          marginRight={10}
                          width={88}
                          {...register('percentual.opcionistas.' + index + '.agencia', {
                            required: watch('percentual.opcionistas.' + index + '.dadosBancarios') === 'banco'
                          })}
                          validationMessage={errors?.percentual?.opcionistas?.[index]?.agencia && errorTextInput}
                          style={watch('percentual.opcionistas.' + index + '.agencia') ? inputGreen : errors?.percentual?.opcionistas?.[index]?.agencia ? inputError : inputDefault}
                        />

                        <TextInputField
                          label="Conta*"
                          type="text"
                          width={124}
                          placeholder="21463-5"
                          {...register('percentual.opcionistas.' + index + '.conta', {
                            required: watch('percentual.opcionistas.' + index + '.dadosBancarios') === 'banco'
                          })}
                          validationMessage={errors?.percentual?.opcionistas?.[index]?.conta && errorTextInput}
                          style={watch('percentual.opcionistas.' + index + '.conta') ? inputGreen : errors?.percentual?.opcionistas?.[index]?.conta ? inputError : inputDefault}
                        />
                      </div>
                    </div>
                  </div>

                </div>

              ))}
              <Button type='button' onClick={() => addCorretor('opcionistas')}>+  Adicionar opcionista</Button>
            </div>
          </Pane>

          {/* DIRETOR COMERCIAL */}
          {franquia.empresa.some((empresa) => empresa.nomeEmpresarial.includes("DNA Imóveis")) &&
            <Pane className='mt29'>
              <div>
                <span className='fw600'>Diretor Comercial</span>

                <div className="border-box mt16">
                  <div className='flex'>
                    <div className='w250 mr20'>
                      <TextInputField
                        label="Nome"
                        type="text"
                        placeholder='Nome diretor comercial*'
                        style={watch('percentual.diretor_comercial.nome') ? inputGreen : errors.percentual?.diretor_comercial?.nome ? inputError : inputDefault}
                        {...register('percentual.diretor_comercial.nome', {
                          // required: errorTextInput,
                        })}
                      />
                    </div>
                    <TextInputField
                      marginRight={20}
                      label="CNPJ"
                      className='pr25'
                      value={watch('percentual.diretor_comercial.cnpj')}
                      style={watch('percentual.diretor_comercial.cnpj') ? inputGreen : errors.percentual?.diretor_comercial?.cnpj ? inputError : inputDefault}
                      {...register('percentual.diretor_comercial.cnpj', {
                        setValueAs: e => cnpjMask(e)
                      })}
                    />


                    <TextInputField
                      label="CRECI"
                      className='pr25'
                      style={watch('percentual.diretor_comercial.creci') ? inputGreen : errors.percentual?.diretor_comercial?.creci ? inputError : inputDefault}
                      {...register('percentual.diretor_comercial.creci', {

                      })}
                    />

                  </div>
                  <div className='flex'>
                    <div className='flex w120 mr20'>
                      <TextInputField
                        label="Porcentagem"
                        className='pr25'
                        type='number'
                        style={watch('percentual.diretor_comercial.porcentagemComissao') ? inputGreen : errors.percentual?.diretor_comercial?.porcentagemComissao ? inputError : inputDefault}
                        {...register('percentual.diretor_comercial.porcentagemComissao', {
                          // required: errorTextInput,
                          onChange: () => handleDCValorPorcentagem('percent'),
                          onBlur: () => calcularTotalEmpresa()
                        })}
                      />
                      <span className='sobrepor mr10'>%</span>
                    </div>

                    <div className='flex w120'>
                      <TextInputField
                        label="Desconto"
                        placeholder='R$'
                        value={watch('percentual.diretor_comercial.desconto')}
                        style={watch('percentual.diretor_comercial.desconto') ? inputGreen : errors.percentual?.diretor_comercial?.desconto ? inputError : inputDefault}
                        {...register('percentual.diretor_comercial.desconto', {
                          setValueAs: e => formatoMoeda(e),
                          onChange: () => handleDCValorPorcentagem('percent'),
                          // onChange: (e) => setValue('percentual.diretor_comercial.desconto', e.target.value)
                        })}
                      />
                    </div>

                    <div className='flex w120'>
                      <TextInputField
                        marginLeft={20}
                        label="Valor"
                        placeholder='R$'
                        value={watch('percentual.diretor_comercial.valorReal')}
                        style={watch('percentual.diretor_comercial.valorReal') ? inputGreen : errors.percentual?.diretor_comercial?.valorReal ? inputError : inputDefault}
                        {...register('percentual.diretor_comercial.valorReal', {
                          setValueAs: e => formatoMoeda(e),
                          onChange: () => handleDCValorPorcentagem('valor'),
                          onBlur: () => calcularTotalEmpresa()
                        })}
                      />
                    </div>
                  </div>

                  {/* LINHA DE DADOS BANCARIOS */}
                  <div className='flex'>
                    <div className="line-radius radius-select mr10">
                      <span className=''>Dados bancários:</span>
                      <RadioGroup
                        label=""
                        size={16}
                        defaultValue={watch('percentual.diretor_comercial.dadosBancarios')}
                        options={dadosBancarios}
                        className="radius"
                        onChange={(e) => setValue('percentual.diretor_comercial.dadosBancarios', e.target.value)}
                      />
                    </div>

                    {/* CASO PIX */}
                    <div className='w392' hidden={watch('percentual.diretor_comercial.dadosBancarios') !== 'pix'}>
                      <TextInputField
                        label="Chave Pix*"
                        type="text"
                        placeholder="CPF, celular ou e-mail"
                        {...register('percentual.diretor_comercial.chavePix', {
                          required: watch('percentual.diretor_comercial.dadosBancarios') === 'banco' ? false : 'Campo obrigtório',
                        })}
                        validationMessage={errors?.percentual?.diretor_comercial?.chavePix && errorTextInput}
                        style={watch('percentual.diretor_comercial.chavePix') ? inputGreen : errors?.percentual?.diretor_comercial?.chavePix ? inputError : inputDefault}
                      />
                    </div>

                    {/* CASO BANCO */}
                    <div hidden={watch('percentual.diretor_comercial.dadosBancarios') !== 'banco'}>
                      <div className='flex'>
                        <SelectField
                          hidden={watch('percentual.diretor_comercial.dadosBancarios') !== 'banco'}
                          label="Banco*"
                          marginRight={10}
                          minWidth={120}
                          className={(
                            watch('percentual.diretor_comercial.banco_id') ? 'inputGreenSelect bgWhite' :
                              errors?.percentual?.diretor_comercial?.banco_id ? 'inputRedSelect bgWhite' : 'bgWhite'
                          )}
                          value={watch('percentual.diretor_comercial.banco_id')}
                          onChange={(e) => handleSelect(e, 'percentual', 'diretor_comercial')}
                        >
                          <option value="" hidden selected >Selecione</option>
                          {listBank.map((bank) =>
                            <option value={bank.id} > {bank.nome}</option>
                          )}
                        </SelectField>

                        <TextInputField
                          label="Agência*"
                          type="text"
                          placeholder="1234"
                          marginRight={10}
                          width={88}
                          {...register('percentual.diretor_comercial.agencia', {
                            required: watch('percentual.diretor_comercial.dadosBancarios') === 'pix' ? false : 'Campo obrigtório',
                          })}
                          validationMessage={errors?.percentual?.diretor_comercial?.agencia && errorTextInput}
                          style={watch('percentual.diretor_comercial.agencia') ? inputGreen : errors?.percentual?.diretor_comercial?.agencia ? inputError : inputDefault}
                        />

                        <TextInputField
                          label="Conta*"
                          type="text"
                          width={124}
                          placeholder="21463-5"
                          {...register('percentual.diretor_comercial.conta', {
                            required: watch('percentual.diretor_comercial.dadosBancarios') === 'pix' ? false : 'Campo obrigtório',
                          })}
                          validationMessage={errors?.percentual?.diretor_comercial?.agencia && errorTextInput}
                          style={watch('percentual.diretor_comercial.conta') ? inputGreen : errors?.percentual?.diretor_comercial?.conta ? inputError : inputDefault}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Pane>
          }

          {/* FRANQUIA - EMPRESA */}
          <Pane className='mt40'>
            <span className='fw600'>Dados da empresa</span>

            {franquia.empresa[0]
              ? franquia.empresa.map((empresa, index) => (
                <div className="border-box mt16">
                  <div className='mr20 flex'>
                    <div className='w250 mr16'>

                      <Pane className='w250 mr20'>
                        <Autocomplete
                          onChange={e => handleAutoComplete(e, 'empresa', index)}
                          //onChange={e => handleChangeText(e, index, 'nome', 'empresa')}
                          items={[...new Set(listEmpresas.map((lojas) => lojas.nome_empresarial))]}
                          selectedItem={watch('franquia.empresa.' + index + '.nomeEmpresarial')}
                        >
                          {props => {
                            const { getInputProps, getRef, inputValue, openMenu } = props
                            return (
                              <TextInputField
                                label={"Nome empresarial*"}
                                placeholder={"Nome empresarial*"}
                                value={inputValue}
                                ref={getRef}
                                validationMessage={errors?.franquia?.empresa?.[index]?.nomeEmpresarial && errorTextInput}
                                style={watch('franquia.empresa.' + index + '.nomeEmpresarial') ? inputGreen : errors?.franquia?.empresa?.[index]?.nomeEmpresarial ? inputError : inputDefault}
                                {...getInputProps({
                                  onClick: () => {
                                    openMenu()
                                  }
                                })}
                              />
                            )
                          }}
                        </Autocomplete>

                        <input
                          {...register('franquia.empresa.' + index + '.nomeEmpresarial', {
                            //required: watch('radioFranquiaRepasse') === 'true',
                            required: true
                          })}
                          hidden
                        />
                      </Pane>
                    </div>

                    <TextInputField
                      label="CNPJ*"
                      type="text"
                      marginRight={16}
                      placeholder='---'
                      value={watch('franquia.empresa.' + index + '.cnpj')}
                      {...register('franquia.empresa.' + index + '.cnpj', {
                        required: 'Campo obrigatório',
                        setValueAs: e => cnpjMask(e),
                        // required: watch('radioFranquiaRepasse') === 'true',
                        // onChange: e => handleChangeText(e.target.value, null, 'cnpj', 'franquia')
                      })}
                      // validationMessage={errors?.franquia?.repasseFranquia?.cnpj && errors.franquia.repasseFranquia.cnpj.message}
                      style={watch('franquia.empresa.' + index + '.cnpj') ? inputGreen : errors?.franquia?.empresa?.[index]?.cnpj ? inputError : inputDefault}
                      disabled={false}
                      validationMessage={errors.franquia?.empresa?.[index]?.cnpj && errors.franquia?.empresa?.[index]?.cnpj.message}
                    />

                    <TextInputField
                      label="CRECI*"
                      type="text"
                      placeholder='---'
                      {...register('franquia.empresa.' + index + '.creci', { required: 'Campo obrigatório', })}
                      // style={watch('franquia.repasseFranquia.creci') ? inputGreen : inputDefault}
                      style={watch('franquia.empresa.' + index + '.creci') ? inputGreen : errors?.franquia?.empresa?.[index]?.creci ? inputError : inputDefault}
                      disabled={false}
                      validationMessage={errors.franquia?.empresa?.[index]?.creci && errors.franquia?.empresa?.[index]?.creci.message}
                    />
                  </div>
                  {/*</div>*/}

                  <div className='flex'>
                    <div className='flex w134'>
                      <TextInputField
                        label="Porcentagem*"
                        className='pr25'
                        type='number'
                        defaultValue={franquia.empresa[index].porcentagem}
                        //defaultValue={porcentagemEmpresaComFranquia}
                        //defaultValue={((Number(comissaoLiquida.replace(",", ".")) * Number(porcentagemEmpresaComFranquia) / 100) - removeR$(watch('franquia.empresa.' + index + '.desconto'))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        {...register('franquia.empresa.' + index + '.porcentagem', {
                          onChange: (e) => handleChangeText(e.target.value, index, 'porcentagem', 'empresa'),
                          required: 'Campo obrigatório',
                          min: {
                            value: 0.01,
                            message: 'Somatório passou de 100%'
                          }
                        })}
                        style={errors.franquia?.empresa?.[index]?.porcentagem ? inputError : franquia?.empresa?.[index]?.porcentagem || watch('franquia.empresa.' + index + '.porcentagem') ? inputGreen : inputDefault}
                        disabled={false}
                        validationMessage={errors.franquia?.empresa?.[index]?.porcentagem && errors.franquia?.empresa?.[index]?.porcentagem.message}
                      />
                      <span className='sobrepor mr10'>%</span>
                    </div>
                    <div className='flex w120'>
                      <TextInputField
                        label="Desconto"
                        placeholder="R$"
                        {...register('franquia.empresa.' + index + '.desconto', {
                          onChange: (e) => handleChangeText(e.target.value, index, 'desconto', 'empresa'),
                          setValueAs: e => e.replace(/[R$]+/g, ''),
                          //required: 'Campo obrigatório',
                        })}
                        style={watch('franquia.empresa.' + index + '.desconto') ? inputGreen : errors.franquia?.empresa?.[index]?.desconto ? inputError : inputDefault}
                        marginLeft={20}
                        validationMessage={errors.franquia?.empresa?.[index]?.desconto && errors.franquia?.empresa?.[index]?.desconto.message}
                      />
                    </div>

                    <div className='flex w120'>
                      <TextInputField
                        label="Valor"
                        placeholder='R$'
                        disabled={false}
                        defaultValue={franquia.empresa[index].valor}
                        //value={valorEmpresa}
                        //defaultValue={((Number(comissaoLiquida.replace(",", ".")) * Number(porcentagemEmpresaComFranquia) / 100) - removeR$(watch('franquia.empresa.' + index + '.desconto'))).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                        {...register('franquia.empresa.' + index + '.valor', {
                          onChange: (e) => handleChangeText(e.target.value, index, 'valor', 'empresa'),
                          //setValueAs: e => e.replace(/[R$]+/g, ''),
                          required: 'Campo obrigatório',
                        })}
                        style={franquia?.empresa?.[index]?.valor || watch('franquia.empresa.' + index + '.valor') ? inputGreen : errors.franquia?.empresa?.[index]?.valor ? inputError : inputDefault}
                        marginLeft={20}
                        validationMessage={errors.franquia?.empresa?.[index]?.valor && errors.franquia?.empresa?.[index]?.valor.message}
                      />
                    </div>

                    {
                      index > 0 &&
                      <Button
                        type='button'
                        appearance='primary'
                        intent='danger'
                        className='ml20 mt26'
                        onClick={(e) => removeEmpresa(e, 'empresa', index)}
                      >
                        Excluir
                      </Button>
                    }
                  </div>

                  {/* LINHA DE DADOS BANCARIOS */}
                  <div className='flex'>
                    <div className="line-radius radius-select mr10">
                      <span className=''>Dados bancários:</span>
                      <RadioGroup
                        label=""
                        size={16}
                        defaultValue={watch('franquia.empresa.' + index + '.dadosBancarios')}
                        options={dadosBancarios}
                        className="radius"
                        onChange={(e) => setValue('franquia.empresa.' + index + '.dadosBancarios', e.target.value)}
                      />
                    </div>

                    {/* CASO PIX */}
                    <div className='w392' hidden={watch('franquia.empresa.' + index + '.dadosBancarios') !== 'pix'}>
                      <TextInputField
                        label="Chave Pix*"
                        type="text"
                        placeholder="CPF, celular ou e-mail"
                        {...register('franquia.empresa.' + index + '.chavePix', {
                          //required: watch('radioFranquiaRepasse') === 'true' && watch('franquia.empresa.' + index + '.dadosBancarios') === 'pix',
                          //required: watch('franquia.empresa.' + index + '.dadosBancarios') === 'pix',
                          required: watch('franquia.empresa.' + index + '.dadosBancarios') === 'banco' ? false : 'Campo obrigtório',
                        })}
                        validationMessage={errors?.franquia?.empresa?.[index]?.chavePix && errorTextInput}
                        style={watch('franquia.empresa.' + index + '.chavePix') ? inputGreen : errors?.franquia?.empresa?.[index]?.chavePix ? inputError : inputDefault}
                      />
                    </div>

                    {/* CASO BANCO */}
                    <div hidden={watch('franquia.empresa.' + index + '.dadosBancarios') !== 'banco'}>
                      <div className='flex'>
                        <SelectField
                          hidden={watch('franquia.empresa.' + index + '.dadosBancarios') !== 'banco'}
                          label="Banco*"
                          marginRight={10}
                          minWidth={120}
                          className={(
                            watch('franquia.empresa.' + index + '.banco_id') ? 'inputGreenSelect bgWhite' :
                              errors?.franquia?.empresa?.[index]?.banco_id ? 'inputRedSelect bgWhite' : 'bgWhite'
                          )}
                          value={watch('franquia.empresa.' + index + '.banco_id')}
                          onChange={(e) => handleSelect(e, 'franquia', 'empresa', index)}
                        >
                          <option value="" hidden selected >Selecione</option>
                          {listBank.map((bank) =>
                            <option value={bank.id} > {bank.nome}</option>
                          )}
                        </SelectField>

                        <TextInputField
                          label="Agência*"
                          type="text"
                          placeholder="1234"
                          marginRight={10}
                          width={88}
                          {...register('franquia.empresa.' + index + '.agencia', {
                            //required: watch('radioFranquiaRepasse') === 'true' && watch('franquia.empresa.' + index + '.dadosBancarios') === 'banco',
                            //required: watch('franquia.empresa.' + index + '.dadosBancarios') === 'banco',
                            required: watch('franquia.empresa.' + index + '.dadosBancarios') === 'pix' ? false : 'Campo obrigtório',
                          })}
                          validationMessage={errors?.franquia?.empresa?.[index]?.agencia && errorTextInput}
                          style={watch('franquia.empresa.' + index + '.agencia') ? inputGreen : errors?.franquia?.empresa?.[index]?.agencia ? inputError : inputDefault}
                        />

                        <TextInputField
                          label="Conta*"
                          type="text"
                          width={124}
                          placeholder="21463-5"
                          {...register('franquia.empresa.' + index + '.conta', {
                            //required: watch('radioFranquiaRepasse') === 'true' && watch('franquia.empresa.' + index + '.dadosBancarios') === 'banco',
                            //required: watch('franquia.empresa.' + index + '.dadosBancarios') === 'banco',
                            required: watch('franquia.empresa.' + index + '.dadosBancarios') === 'pix' ? false : 'Campo obrigtório',
                          })}
                          validationMessage={errors?.franquia?.empresa?.[index]?.agencia && errorTextInput}
                          style={watch('franquia.empresa.' + index + '.conta') ? inputGreen : errors?.franquia?.empresa?.[index]?.conta ? inputError : inputDefault}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
              : <div className="mt16"></div>
            }

            <Button type='button' onClick={() => addEmpresa('empresa')}>
              + Adicionar empresa
            </Button>
          </Pane>

        </Pane>
      }

      <div className="row-recibo" style={{ marginTop: '35px' }}>
        <div className="subtitle">
          <EditIcon className="icon-subtitle mr10" />
          Observações
        </div>

        <Label htmlFor="textarea-2" marginBottom={12} display="block">
          Se atente às observações inseridas pelo Gerente com relação às comissões.
        </Label>

        <Textarea
          name="textarea-1"
          className="boletimdevenda-venda-observacao-1"
          id="recibo-observacao-1"
          placeholder="O Gerente não inseriu uma observação."
          height={110}
          {...register('observacoes', {
            required: false
          })}
          disabled
        />
      </div>

      <Pane display="flex" paddingBottom={20} marginTop={20}>
        <Pane flex={1}>
          {/*ESPAÇO PRA COLOCAR BUTTON ALINHADO*/}
        </Pane>

        <Pane>
          <Button
            type="submit"
            appearance="primary"
            marginLeft={15}
            onClick={handleSubmit(e => handleSalvar(e, 'salvar'), onError)}
            isLoading={loading}
            disabled={loading || planilha_antiga}
          >
            <TickIcon size={12} color="#fff" marginRight={10} /> Salvar correções
          </Button>
        </Pane>

        <Pane>
          <Button
            type="submit"
            appearance="primary"
            intent="success"
            marginLeft={15}
            //onClick={handleSubmit(e => handleVisualizarPlanilha(e))}
            onClick={handleSubmit(e => handleSalvar(e, 'gerar'), onError)}
            isLoading={loading}
            disabled={planilha_antiga || loading}
          >
            <ArrowRightIcon size={12} color="#fff" marginRight={10} /> Gerar planilha de comissão
          </Button>
        </Pane>
      </Pane>

      {/*<Pane className="row-msg-apoio">
        <TickCircleIcon color="success" marginRight={10} /> <span className="msg-plan-apoio">As alterações foram salvas. Para concluir, clique em Gerar Planilha de Comissão</span>
        </Pane>*/}

      <Pane className="atualizacao-comissao">
        {
          selectProcess.imovel.comissao?.data_atualizacao &&
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'center' }}>
            <TickCircleIcon color="success" marginRight={5} /> <span className='mr5'>Última atualização: </span> {selectProcess.imovel.comissao?.data_atualizacao}
          </div>
        }
      </Pane>

      <PlanilhaDeComissao processo={selectProcess} />

    </Pane >
  )
}