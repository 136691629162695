import React, { useState, useContext } from 'react';
import { Pane, Tablist, Tab, Card, LockIcon } from 'evergreen-ui';
import DirecaoContext from '../../../../context/DirecaoContext';
import TabVenda from './Tabs/Rascunho/Venda';
import FormPessoa from './Tabs/Rascunho/FormPessoa';

export default function Rascunho() {
    const {
        //sideId, sideProcesso,
        //selectedIndexEnviados, setSelectedIndexEnviados,
        dadosVenda,
        selectedIndexRascunho, setSelectedIndexRascunho,
        showTabsRascunho
    } = useContext(DirecaoContext);


    const [tabsRascunho] = useState(['Venda', 'Vendedor', 'Comprador']);

    const verificarTabs = async () => {
        if(dadosVenda.status_processo >= 1 && dadosVenda.status_processo <= 8){
            showTabsRascunho[1] = true;
            showTabsRascunho[2] = true;
        }
        else{
            if(dadosVenda.status_processo >= 10){
                showTabsRascunho[1] = true;
            }
            //if(dadosVenda.status_processo >= 11){
            if(dadosVenda.status_processo === 11 && dadosVenda.status_processo !== 24
                || (dadosVenda.status_processo >= 15 && dadosVenda.status_processo !== 24)
                || (dadosVenda.status_processo === 11 && dadosVenda.status_anterior_processo_id === 15)
                || (dadosVenda.status_processo === 15 && dadosVenda.status_anterior_processo_id === 11)
                //|| (dadosVenda.status_processo === 11 && dadosVenda.status_anterior_processo_id === 23)
                //|| (dadosVenda.status_processo >= 15 && dadosVenda.status_anterior_processo_id === 23)
            ){
                showTabsRascunho[2] = true;
            }
            else{
                showTabsRascunho[2] = false;
            }
        }
    }
    verificarTabs();

    return (
        <Pane>
            <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
                <Pane display="flex" padding={8}>
                    <Tablist>
                        {tabsRascunho.map((tabRascunho, index) => (
                            <Tab
                                key={tabRascunho}
                                isSelected={selectedIndexRascunho === index}
                                onSelect={() => setSelectedIndexRascunho(index)}
                                aria-controls={`panel-${index}`}
                                aria-selected={selectedIndexRascunho === index ? true : false}
                                //className='tab-enable'
                                className={
                                    showTabsRascunho[index] === true ? 'tab-enable' : 'tab-disable'
                                }
                                style={selectedIndexRascunho === index ? { boxShadow: '0 0 0 2px #d6e0ff' } : { boxShadow: 'none' }}
                            >
                                {tabRascunho} {showTabsRascunho[index] === true ? '' : <LockIcon size={12} marginLeft={10}/>}
                            </Tab>
                        ))}
                    </Tablist>
                </Pane>
            </Pane>
            <Pane flex="1" overflowY="scroll" background="tint1">
                {tabsRascunho.map((tabRascunho, index) => (
                    <Card
                        key={tabRascunho}
                        id={`panel-${tabRascunho}`}
                        role="tabpanel"
                        aria-labelledby={tabRascunho}
                        aria-hidden={index !== selectedIndexRascunho}
                        display={index === selectedIndexRascunho ? 'block' : 'none'}
                    >
                        {/*Id do processo - sideId*/}
                        {tabRascunho === 'Venda' && <TabVenda />}
                        {tabRascunho === 'Vendedor' && <FormPessoa type="Vendedor" />}
                        {tabRascunho === 'Comprador' && <FormPessoa type="Comprador" />}
                    </Card>
                ))}
            </Pane>
        </Pane>
    )
}