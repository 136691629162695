import '../../../index.css';
import React, { useState, useContext } from 'react';
import { Pane, Heading, Tablist, Tab, Card, SideSheet } from 'evergreen-ui';
import ApoioContext from '../../../context/ApoioContext';
import Comissao from './FormSideSheet/Comissao';
import Imovel from './FormSideSheet/Imovel';
import Gerente from './FormSideSheet/Gerente';
import Vendedores from './FormSideSheet/Vendedores';
import Compradores from './FormSideSheet/Compradores';
import Venda from './FormSideSheet/Venda';
import Historico from './FormSideSheet/Historico';
import { useEffect } from 'react';
import ComissaoFechada from './FormSideSheet/ComissaoFechada';

export default function SideSheetView({ refreshTable }) {
    const {
        isShownSide, setIsShownSide,
        selectProcess,
        selectedIndex, setSelectedIndex,
        idProcess,
    } = useContext(ApoioContext);    

    const endereco = selectProcess?.imovel?.logradouro + ', ' + selectProcess?.imovel?.numero;
    const [tabs] = useState(['Comissão', 'Venda', 'Imóvel', 'Gerente', 'Vendedores', 'Compradores', 'Histórico']);

    useEffect(() => {
        setSelectedIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[idProcess])

    const refreshProcessoSideSheet = async () => {     
        refreshTable();
    };

    return (
        <SideSheet
            isShown={isShownSide}
            onCloseComplete={() => setIsShownSide(false)}
            containerProps={{
                display: 'flex',
                flex: '1',
                flexDirection: 'column',
                className: 'side-sheet-box'
            }}
            preventBodyScrolling
            width={700}
        >
            <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
                <Pane padding={16} borderBottom="muted">
                    <Heading size={600}>
                        {endereco === 'undefined, undefined' ? 'Sem endereço...' : endereco}
                    </Heading>
                </Pane>
                <Pane display="flex" padding={8}>
                    <Tablist>
                        {tabs.map((tab, index) => (
                            <Tab
                                key={tab}
                                isSelected={selectedIndex === index}
                                onSelect={() => setSelectedIndex(index)}
                                aria-controls={`panel-${index}`}
                                aria-selected={selectedIndex === index ? true : false}
                                style={selectedIndex === index ? { boxShadow: '0 0 0 2px #d6e0ff' } : { boxShadow: 'none' }}
                            >
                                {tab}
                            </Tab>
                        ))}
                    </Tablist>
                </Pane>
            </Pane>
            <Pane flex="1" overflowY="scroll" background="tint1" padding={16}>
                {tabs.map((tab, index) => (
                    <Card
                        key={tab}
                        id={`panel-${tab}`}
                        role="tabpanel"
                        aria-labelledby={tab}
                        aria-hidden={index !== selectedIndex}
                        display={index === selectedIndex ? 'block' : 'none'}
                    >
                        {tab === 'Comissão' && index === selectedIndex && <Comissao refreshProcessoSideSheet={refreshProcessoSideSheet} />}
                        {tab === 'Venda' && index === selectedIndex && <Venda />}
                        {tab === 'Imóvel' && index === selectedIndex && <Imovel />}
                        {tab === 'Gerente' && index === selectedIndex && <Gerente />}
                        {tab === 'Vendedores' && index === selectedIndex && <Vendedores />}
                        {tab === 'Compradores' && index === selectedIndex && <Compradores />}
                        {tab === 'Histórico' && index === selectedIndex && <Historico />}
                    </Card >
                ))
                }
            </Pane >

        </SideSheet >
    )
}