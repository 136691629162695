import React, { useContext, useState } from 'react';
import { Dialog, Pane, TickCircleIcon, Button, toaster, ResetIcon } from 'evergreen-ui';
import Axios from 'axios';
import Serve from '../../../../app/serve';
import { useHistory } from 'react-router-dom';
import VendasContext from '../../../../context/VendasContext';
import Image from '../../../../images/Group_199.svg';
import Lottie from 'react-lottie';
import animationData from '../../../../images/Animations/recovery.json';

export default function DialogRestore({ recarregarVendas }) {
  const {
    isShowRestore,
    setIsShowRestore,
    processoIdRemove,
    itemsArquivados,
  } = useContext(VendasContext);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const history = useHistory();
  const usuario_id = localStorage.getItem('usuario_id');
  const token = localStorage.getItem('token');
  const app = Serve();
  const vendaRestaurar = itemsArquivados.filter(venda => venda.processo_id === processoIdRemove);

  //Animação
  const [loadingAnimation, setLoadingAnimation] = useState(false);

  async function restoreVenda(close) {
    setLoadingAnimation(true)
    setTimeout(() => {
      close();
      setTimeout(() => {
        setLoadingAnimation(false);
      }, "200")
    }, "4000")

    await Axios.post(app + 'restaurar_venda', {
      "usuario_id": usuario_id,
      "processo_id": processoIdRemove,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(res => {
      if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
        localStorage.clear();
        history.push('/');
      } else {
        recarregarVendas('arquivados');
        toaster.notify('Você restaurou a venda. Ela se encontra na lista de rascunhos.')
      }
    })
  };


  return (
    <Dialog
      isShown={isShowRestore}
      width={750}
      onCloseComplete={() => setIsShowRestore(false)}
      intent="danger"
      footer={
        loadingAnimation
          ? <div></div>
          :
          ({ close }) => (
            <Pane
              width={686}
              display="flex"
              alignItems="flex-end"
              justifyContent="space-between"
            >
              <Pane className='card'>
                <div className='cardTitle'>
                  {vendaRestaurar[0]?.bairro || ''} - {vendaRestaurar[0]?.uf || ''}
                  <TickCircleIcon color="green" />
                </div>
                <div className='cardText'>
                  {vendaRestaurar[0]?.logradouro || ''} - {vendaRestaurar[0]?.numero || ''}
                  {vendaRestaurar[0]?.complemento || ''}
                </div>
              </Pane>

              <Button appearance="primary" onClick={() => restoreVenda(close)}>
                <ResetIcon size={12} color="white" marginRight={5} /> Restaurar venda
            </Button>
            </Pane>
          )
      }
    >
      {loadingAnimation
        ?
        <Lottie
          options={defaultOptions}
          height={150}
          width={150}
        />
        :
        <>
          <Pane
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={-5}
            marginBottom={47}
          >
            <img src={Image} alt="Group_153" />
          </Pane>

          <div className="dialog-content dialog-content-info">

            <h1 style={{ marginBottom: "17px", fontWeight: "600" }}>
              Deseja restaurar esta venda?
            </h1>
            <p className='dialogSubTitle'>
              Quando restaurada, a venda volta para a sua lista de rascunhos e você <br />
              pode continuar o processo de onde parou.
            </p>
          </div>
        </>
      }
    </Dialog>
  )
};