import React, { useState, useEffect, useContext } from 'react';
import {
    Pane,
    Table,
    Button,
    ShareIcon,
    Badge,
    PlusIcon,
    Avatar,
    Spinner
} from 'evergreen-ui';
import Axios from 'axios';
import Serve from '../../../../app/serve';
import { useHistory } from 'react-router-dom';

//Context
import PosNegociacaoContext from '../../../../context/PosNegociacaoContext';

import '../../../../index.css';

export default function RecibosAdicionados() {

    const {
        itemAdicionado, setItemAdicionado,
        setIsShowSendBackOffice,
        setValidateFieldRgi,
        setValidateFieldCep,
        setValidateFieldDataAssinatura,
        setValidateFieldFormaPagamento,
        setValidateInputFile,
        setValidateCepMessage,
        setValidateAdress,
        setCheckedFgts,
        setCheckedAVista,
        setCheckedFinanciamento,
        setEstadoRecibo,
        dadosRecibo, setDadosRecibo,
        setIsShownFormRecibo,
        loading, setLoading,
        setAvista,
        setFinanciamento,
        setFgts
    } = useContext(PosNegociacaoContext)

    const history = useHistory();
    const [token] = useState(localStorage.getItem('token'));
    const usuario_id = localStorage.getItem('usuario_id');
    const nome_usuario = localStorage.getItem('nome_usuario');
    //const usuario_email = localStorage.getItem('usuario_email');

    const app = Serve();

    //Table
    //const [item, setItems] = useState([]);
    //const [historicoStatus, setHistoricoStatus] = useState([]);


    useEffect(() => {

        const exibirImoveis = async () => {            
            await Axios.post(app + 'retorno_processos_criados_gerente', {
                "usuario_id": usuario_id,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
                .then(res => {
                    //console.log("asdfad");
                    if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
                        localStorage.clear();
                        history.push('/');
                    } else {
                        console.log(res);
                        //console.log("erro sei la por que");
                        const processos = res.data.adicionados;
                        //console.log(processos);
                        setItemAdicionado(processos);

                        setLoading(true);
                    }

                })
        }

        exibirImoveis();
    }, [app, token])

    function openModal(e, recibo) {
        console.log('openModal1');
        setDadosRecibo(recibo);
        setIsShowSendBackOffice(true);
    }

    const formRecibo = async (e, recibo) => {

        console.log('Abrindo recibo');

        if (recibo !== undefined) {
            console.log(recibo);

            const arrayRecibo = {
                id: recibo.id,
                rgi: recibo.informacao.rgi,
                cep: recibo.cep,
                bairro: recibo.bairro,
                logradouro: recibo.logradouro,
                numero: recibo.numero,
                unidade: recibo.unidade,
                complemento: recibo.complemento,
                dataAssinatura: recibo.informacao.data_assinatura,
                formaPagamento: recibo.informacao.forma_pagamento,
                recibo: recibo.informacao.recibo,
                observacoes: recibo.informacao.observacao,
            }
            console.log(arrayRecibo);

            if (recibo !== []) {

                console.log(recibo.informacao);

                if (recibo.informacao.rgi !== null) {
                    setValidateFieldRgi(true);
                }
                else {
                    setValidateFieldRgi(false);
                }

                //////////////////////////////////////////////////////

                if (recibo.informacao.cep !== null) {
                    setValidateFieldCep(true);
                }
                else {
                    setValidateFieldCep(false);
                }

                //////////////////////////////////////////////////////

                if (recibo.informacao.dataAssinatura !== null) {
                    setValidateFieldDataAssinatura(true);

                }
                else {
                    setValidateFieldDataAssinatura(false);
                }

                //////////////////////////////////////////////////////

                if (recibo.informacao.recibo !== null) {
                    setValidateInputFile(true);
                }
                else {
                    setValidateInputFile(false);
                }

                //////////////////////////////////////////////////////

                if (recibo.informacao.forma_pagamento === '1') {
                    setCheckedAVista(true);
                    setAvista('1');
                    setValidateFieldFormaPagamento(true);
                }

                if (recibo.informacao.forma_pagamento === '2') {
                    setCheckedFinanciamento(true);
                    setFinanciamento('2');
                    setValidateFieldFormaPagamento(true);
                }

                if (recibo.informacao.forma_pagamento === '3') {
                    setCheckedFgts(true);
                    setFgts('3');
                    setValidateFieldFormaPagamento(true);
                }

                if (recibo.informacao.forma_pagamento === '2,3') {
                    setCheckedFinanciamento(true);
                    setCheckedFgts(true);
                    setFinanciamento('2');
                    setFgts('3');
                    setValidateFieldFormaPagamento(true);
                }

                setEstadoRecibo('editar');

            }
            else {
                setValidateFieldRgi(false);
                setValidateFieldCep(false);
                setValidateFieldDataAssinatura(false);
                setValidateFieldFormaPagamento(false);
                setValidateInputFile(false);
                setValidateCepMessage(true);
                setValidateAdress(false);

                setCheckedAVista(false);
                setCheckedFinanciamento(false);
                setCheckedFgts(false);
            }

            setDadosRecibo(arrayRecibo);
        }
        else {
            setDadosRecibo([]);
            setValidateFieldFormaPagamento(false);
            setEstadoRecibo('cadastrar');
        }

        setIsShownFormRecibo(true);
    }

    const showDocument = async (e, id_imovel) => {
        console.log(id_imovel);
        Axios.post(app + 'exibir_arquivo', {
            "id": id_imovel
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',
        })
            .then(res => {
                console.log(res);
                let type_arquivo = res.data.type;
                if (type_arquivo !== 'text/html') {
                    const file = new Blob(
                        [res.data],
                        { type: type_arquivo });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                } else {
                    console.log("não teve retorno satisfatorio");
                }
            })
        //window.open('/documento/recibo-de-sinal', '_blank');
    }

    return (
        <Pane>
            <h2 className="subtitle subtitle-table">
                Processos em aberto {
                    itemAdicionado.length > 1 &&
                    <Badge color="blue" marginLeft={10}>
                        {itemAdicionado.length}
                    </Badge>
                }
            </h2>
            <Button marginY={8} marginRight={12} appearance="primary" onClick={(e) => formRecibo(e) /*setIsShown(true)*/}>
                <PlusIcon
                    color="white"
                    style={{ width: 11, height: 11, marginRight: 10 }} /> Adicionar recibo
            </Button>

            <Table.Body className="table">
                <Table.Head>
                    {/* <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0} className="header-title">
                    </Table.TextCell> */}
                    <Table.TextCell flexBasis={300} flexShrink={0} flexGrow={0} className="header-title">
                        Endereço
                    </Table.TextCell>
                    <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="header-title">
                        Data de assinatura
                    </Table.TextCell>
                    <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="header-title">
                        Forma de pagamento
                    </Table.TextCell>
                    <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="header-title">
                        Arquivo do recibo
                    </Table.TextCell>
                </Table.Head>

                {
                    //Enquanto não carregar os dados exibe um Spinner
                    loading === false && <Spinner size={24} style={{ margin: 20 }} />
                }

                {
                    loading === true &&

                    <Table.Body>
                        {
                            itemAdicionado.length > 0
                                ?
                                <div>
                                    {itemAdicionado.map(recibo => <Table.Row key={recibo.id}>
                                        {/*Avatar*/}
                                        {/* {<Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0} className="content">
                                            <Avatar hashValue={nome_usuario} name={nome_usuario} size={40} marginRight={16} />
                                        </Table.TextCell>} */}

                                        <Table.TextCell flexBasis={300} flexShrink={0} flexGrow={0} className="content-endereco content-click" onClick={(e) => formRecibo(e, recibo)}>
                                            {recibo.logradouro + ', ' + recibo.numero + (recibo.unidade !== null ? " / " + recibo.unidade : "")}
                                        </Table.TextCell>

                                        <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="content-endereco">
                                            {recibo.informacao !== null ? recibo.informacao.data_assinatura : ''}
                                        </Table.TextCell>

                                        <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="content-endereco">
                                            {
                                                recibo.informacao !== null
                                                    ?
                                                    recibo.informacao.forma_pagamento === '2,3' ? 'Financiamento, FGTS' : recibo.informacao.forma_pagamento_nome

                                                    : ''}
                                        </Table.TextCell>

                                        <Table.TextCell
                                            flexBasis={180} flexShrink={0} flexGrow={0} className="content-endereco" onClick={(e) => showDocument(e, recibo.informacao.imovel_id)}>
                                            <div className="doc-name">{recibo.informacao.recibo}</div>
                                        </Table.TextCell>

                                        <Table.TextCell
                                            flexBasis={300} flexShrink={0} flexGrow={0}>
                                            <Button
                                                marginY={8}
                                                marginRight={12}
                                                appearance="primary"
                                                onClick={(e) => openModal(e, recibo)}
                                            >
                                                <ShareIcon
                                                    color="white"
                                                    style={{ width: 11, height: 11, marginRight: 10 }} /
                                                >
                                                Enviar para o Backoffice
                                            </Button>
                                        </Table.TextCell>
                                    </Table.Row>
                                    )}
                                </div>

                                :
                                // Se não tiver nenhum documento exibe esse bloco
                                <Table.Row key={1}>
                                    <div className="mensagem-processo">
                                        Você ainda não possui nenhum recibo de sinal. <a href onClick={(e) => formRecibo(e)} title="Para adicionar o primeiro, clique aqui.">Para adicionar o primeiro, clique aqui.</a>
                                    </div>
                                </Table.Row>
                        }
                    </Table.Body>
                }
            </Table.Body>
        </Pane>
    )
}