import React, {useContext, useState, useEffect} from 'react';
import { Pane, Badge, SmallTickIcon } from 'evergreen-ui';

//Context
import PosNegociacaoContext from '../../../../../../context/PosNegociacaoContext';

export default function Steps() {

    const {
        posicaoEspelhoVenda,
    } = useContext(PosNegociacaoContext);

    console.log(posicaoEspelhoVenda);

    const [stepImovel, setStepImovel] = useState('default')
    const [stepVendedor, setStepVendedor] = useState('default');
    const [stepComprador, setStepComprador] = useState('default');
    const [stepComissao, setStepComissao] = useState('default');

    useEffect(() => {
        const posicaoStep = async () => {
            if (posicaoEspelhoVenda === 1) {
                setStepImovel('active');
                setStepVendedor('default');
                setStepComprador('default');
                setStepComissao('default');
            }
            else if (posicaoEspelhoVenda === 2) {
                setStepImovel('default');
                setStepVendedor('active');
                setStepComprador('default');
                setStepComissao('default');
            }
            else if (posicaoEspelhoVenda === 3) {
                setStepImovel('default');
                setStepVendedor('default');
                setStepComprador('active');
                setStepComissao('default');
            }
            else {
                setStepImovel('default');
                setStepVendedor('default');
                setStepComprador('default');
                setStepComissao('active');
            }
        }
        posicaoStep();    
    }, [posicaoEspelhoVenda]);
    
    return (
        <Pane>
            <div className="steps">
                <div className="step-item">
                    <Badge
                        className={posicaoEspelhoVenda > 1 ? 'badge-check' : 'badge-active'}
                    >
                        {posicaoEspelhoVenda > 1 ? <SmallTickIcon className="step-check" /> : 1}
                    </Badge>
                    <span className={stepImovel}>Imóvel</span>
                </div>

                <div className="step-item">
                    <Badge
                        className={posicaoEspelhoVenda === 2 ? "badge-active" : posicaoEspelhoVenda > 2 ? 'badge-check' : 'badge-default'}
                    >
                        {posicaoEspelhoVenda > 2 ? <SmallTickIcon className="step-check" /> : 2}
                    </Badge>
                    <span className={stepVendedor}>Vendedor</span>
                </div>

                <div className="step-item">
                    <Badge
                        className={posicaoEspelhoVenda === 3 ? "badge-active" : posicaoEspelhoVenda > 3 ? 'badge-check' : 'badge-default'}
                    >
                        {posicaoEspelhoVenda > 3 ? <SmallTickIcon className="step-check" /> : 3}
                    </Badge>
                    <span className={stepComprador}>Comprador</span>
                </div>

                <div className="step-item">
                    <Badge
                        className={posicaoEspelhoVenda === 4 ? "badge-active" : posicaoEspelhoVenda > 4 ? 'badge-check' : 'badge-default'}
                    >
                        {posicaoEspelhoVenda > 4 ? <SmallTickIcon className="step-check" /> : 4}
                    </Badge>
                    <span className={stepComissao}>Comissão</span>
                </div>
            </div>
        </Pane>
    )
}