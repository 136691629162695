import Axios from 'axios';
import { toaster } from 'evergreen-ui';
import Serve from '../app/serve';

async function getHaveLaudemio(logradouro, numero) {
  const token = localStorage.getItem('token');
  const app = Serve();
  let lojas = [];

  await Axios.post(
    app + "verifica_laudemio",
    {
      logradouro,
      numero,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((res) => {
      if (res.data) toaster.notify(res?.data?.dados_imovel_laudemio);
    })
    .catch((error) => {
      console.log(error);
    });


  return lojas;
};

export default getHaveLaudemio;