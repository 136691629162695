import React, { useState, useContext, useEffect } from 'react';
import {
    Pane,
    Table,
    Button,
    Badge,
    InfoSignIcon,
    Spinner,
    Avatar,
    Combobox,
    Select,
    Pagination,
    FilterIcon,
    Tooltip,
} from 'evergreen-ui';
import Axios from 'axios';
import Serve from '../../../../app/serve';
import { useHistory } from 'react-router-dom';

import Lottie from 'react-lottie';
//import animationData from '../../../../images/Animations/loadingDocs.json';

//Context
import VendasContext from '../../../../context/VendasContext';

//Component
import SideSheetRecibo from '../SideSheetView';
import ShowDocument from '../../../../app/showDocument';
import '../../../../index.css';

export default function VendasEnviadas() {

    const {
        itemEnviado,
        setDadosReciboEnviado,
        setDadosVenda,
        setIsShownSide,
        listEnderecos,
        loading, setLoading,
        setSelectedIndexEnviados,
        filtro, quantFiltroEnviados, pageNumberEnviados, setPageNumberEnviados,
        totalProcessosFiltradosEnviados, setTotalProcessosFiltradosEnviados,
        totalPaginasEntregues, setTotalPaginasEntregues,
        totalItensEntregues, setItemEnviado,
        listaGerente, listaResponsaveis, listaLojas, listaStatusProcesso,
        defaultOptions
    } = useContext(VendasContext)

    const history = useHistory();
    const usuario_id = localStorage.getItem('usuario_id');
    const processo_id = localStorage.getItem('processo_id');
    const token = localStorage.getItem('token');
    const app = Serve();


    const handleSideSheet = (e, recibo) => {
        setDadosVenda(recibo);
        setDadosReciboEnviado(recibo);
        setIsShownSide(true);
    }

    const acessoTab = async (tab) => {
        setSelectedIndexEnviados(tab);
    }

    //Filtro
    const handleFilter = async (page, temFiltro) => {
        setLoading(false);
        localStorage.setItem('page_number', page);
        setPageNumberEnviados(page);
        quantidadeFiltros();

        await Axios.post(app + 'retorno_processos_painel_gerentes', {
            usuario_id: !filtro.gerente_entregue ? usuario_id : filtro.gerente_entregue.id,
            page: page,
            responsavel_id: filtro.responsavel,
            filtro: filtro.limpar_filtro_entregue,
            tipo_processos: 'entregues',
            loja_id: filtro.loja_entregue,
            status_id: filtro.status,
            logradouro: filtro.endereco_entregue ? filtro.endereco_entregue.endereco : "",
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {
            if (res?.data?.status && (res?.data?.status === 498 || res?.data?.status === 401)) {
                localStorage.clear();
                history.push('/');
            } else {
                //const itensAdicionados = res.data.adicionados;
                const itensEnviados = res.data.processos.data;
                let totalPaginas = res.data.total_pagina;
                //const itemsArquivados = res.data.arquivados;
                //setItemAdicionado(itensAdicionados);
                setItemEnviado(itensEnviados);
                setTotalPaginasEntregues(totalPaginas);
                //setItemsArquivados(itemsArquivados);
                setLoading(true);
                //const processos = res.data.processos.data;
                setTotalProcessosFiltradosEnviados(res.data.total_processos_filtrados);
            }
        })
        //}
    };

    // Paginação
    function listItemsPerPage(pageNumberAdicionados) {
        handleFilter(pageNumberAdicionados);
    };

    // Filtros
    useEffect(() => {
        quantidadeFiltros();
    });

    const quantidadeFiltros = async () => {
        let filtrosSelecionados = 0;
        if (filtro.endereco_entregue !== '') {
            filtrosSelecionados++;
        }
        if (filtro.responsavel !== '') {
            filtrosSelecionados++;
        }

        if (filtro.gerente_entregue !== '') {
            filtrosSelecionados++;
        }

        if (filtro.loja_entregue !== '') {
            filtrosSelecionados++;
        }

        if (filtro.status !== '') {
            filtrosSelecionados++;
        }
        quantFiltroEnviados[0] = filtrosSelecionados;
    };

    const handleSelect = (e) => {
        quantidadeFiltros();
        setPageNumberEnviados(1);
        const value = e.target.value;
        const name = e.target.name;

        filtro[name] = value;
        localStorage.setItem('filtro_' + name, value);
        handleFilter(1, 'filtro');
    };

    // Filtro de Gerente
    const handleCombobox = (e, campo) => {
        if (e) {
            localStorage.setItem('filtro_' + campo, JSON.stringify(e));
            filtro[campo] = e;
            handleFilter(1, 'filtro');
        }
    };

    const handleClearFilter = async (e) => {
        setLoading(false);
        setPageNumberEnviados(1);
        filtro.endereco_entregue = "";
        localStorage.removeItem('filtro_endereco_entregue');

        filtro.gerente_entregue = "";
        localStorage.removeItem('filtro_gerente_entregue');

        filtro.responsavel = "";
        localStorage.removeItem('filtro_responsavel');

        filtro.limpar_filtro_entregue = '';
        localStorage.removeItem('filtro_limpar_filtro_entregue');

        filtro.loja_entregue = "";
        localStorage.removeItem('filtro_loja_entregue');

        filtro.status = "";
        localStorage.removeItem('filtro_status');

        handleFilter(1, 'filtro');
        setTotalProcessosFiltradosEnviados(0);
    };

    return (
        <Pane>
            <React.Fragment>
                <Pane>
                    <div className='flex space-between pb10'>
                        <div className="flex gap20 end">
                            <span>Filtrar por: </span>
                            <div>
                                <Combobox
                                    openOnFocus
                                    name='endereco_entregue'
                                    items={listEnderecos.entregues}
                                    selectedItem={filtro.endereco_entregue}
                                    itemToString={item => (item ? item.endereco : '')}
                                    placeholder="🔍︎ Endereço"
                                    onChange={(e) => handleCombobox(e, 'endereco_entregue')}
                                    width={250}
                                    id="filtro-endereco"
                                    className="filtro-select color-select-filter"
                                />
                            </div>
                            <div>                                
                                <Combobox
                                    openOnFocus
                                    name='gerente_entregue'
                                    items={listaGerente}
                                    selectedItem={filtro.gerente_entregue}
                                    itemToString={item => (item ? item.name : '')}
                                    placeholder="🔍︎ Gerente"
                                    onChange={(e) => handleCombobox(e, 'gerente_entregue')}
                                    id="filtro-gerente"
                                    className="filtro-select color-select-filter"
                                />
                            </div>
                            <div>
                                <Select
                                    label=""
                                    name='loja_entregue'
                                    value={filtro.loja_entregue}
                                    onChange={(e) => handleSelect(e)}
                                    id="filtro-loja"
                                    className={filtro.loja_entregue === "" ? "filtro-select bgWhite color-select-filter-hidden" : "filtro-select bgWhite color-select-filter"}
                                >
                                    <option value="" hidden selected >Loja</option>
                                    {listaLojas.map(valor =>
                                        <option key={valor.id} value={valor.id}>{valor.nome}</option>
                                    )}
                                </Select>
                            </div>
                            <div>
                                <Select
                                    label=""
                                    name='status'
                                    value={filtro.status}
                                    onChange={(e) => handleSelect(e)}
                                    id="filtro-status"
                                    className={filtro.status === "" ? "filtro-select bgWhite color-select-filter-hidden" : "filtro-select bgWhite color-select-filter"}
                                >
                                    <option value="" hidden selected >Status</option>
                                    {listaStatusProcesso[0].map(valor =>
                                        <option key={valor.id} value={valor.id}>{valor.status}</option>
                                    )}
                                </Select>
                            </div>
                            <div>
                                <Select
                                    label=""
                                    name='responsavel'
                                    value={filtro.responsavel}
                                    onChange={(e) => handleSelect(e)}
                                    id="filtro-responsavel"
                                    className={filtro.responsavel === 0 ? "filtro-select bgWhite color-select-filter-hidden" : "filtro-select bgWhite color-select-filter"}
                                >
                                    <option value="" selected hidden>Pós-venda</option>
                                    {listaResponsaveis[0].map(responsavel =>
                                        <option key={responsavel.id} value={responsavel.id}>{responsavel.name}</option>
                                    )}
                                </Select>
                            </div>
                        </div>
                        <div>
                            <Pagination
                                page={pageNumberEnviados}
                                totalPages={Number(totalPaginasEntregues)}
                                onPageChange={(e) => loading ? listItemsPerPage(e) : ''}
                                onNextPage={() => listItemsPerPage(pageNumberEnviados + 1)}
                                onPreviousPage={() => listItemsPerPage(pageNumberEnviados - 1)}
                            />
                        </div>
                    </div>

                    {quantFiltroEnviados[0] > 0 || localStorage.getItem('filtro_gerente_entregue') ?
                        <div className="filter-info">
                            <p>
                                <FilterIcon className="icon-filter" />
                                {quantFiltroEnviados[0]} {quantFiltroEnviados[0] === 1 ? 'filtro aplicado. ' : 'filtros aplicados. '}
                                {itemEnviado.length} / {totalProcessosFiltradosEnviados} {totalProcessosFiltradosEnviados === 1 ? 'processo visível' : 'processos visíveis'}.
                                <span onClick={(e) => handleClearFilter(e)}> Limpar filtro</span>
                            </p>
                        </div>

                        : ''
                    }
                </Pane>

                <Table.Body className="table" style={{ overflow: 'hidden' }}>
                    <Table.Head>
                        <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0} className="header-title">
                            Gerente
                        </Table.TextCell>
                        <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0} className="header-title">
                            Loja
                        </Table.TextCell>
                        <Table.TextCell flexBasis={300} flexShrink={0} flexGrow={0} className="header-title">
                            Endereço
                        </Table.TextCell>
                        <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} className="header-title">
                            Data de assinatura
                        </Table.TextCell>
                        <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="header-title">
                            Forma de pagamento
                        </Table.TextCell>
                        <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="header-title">
                            Arquivo do recibo
                        </Table.TextCell>
                        <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="header-title">
                            Status
                        </Table.TextCell>
                        <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="header-title">
                            Pós-Venda
                        </Table.TextCell>
                        <Table.TextCell flexBasis={130} flexShrink={0} flexGrow={0} className="header-title">
                        </Table.TextCell>
                    </Table.Head>

                    {
                        //Enquanto não carregar os dados exibe um Spinner
                        /* loading === false && <Spinner size={24} style={{ margin: 20 }} /> */
                        loading === false &&
                        <div className='backgroundLoadingDocs'>
                            <Lottie
                                options={defaultOptions}
                                height={200}
                                width={400}
                            />
                        </div>
                    }

                    {
                        loading === true &&

                        <Table.Body style={{ backgroundColor: 'white' }}>
                            {
                                itemEnviado.length > 0
                                    ?
                                    <div>
                                        {itemEnviado.map(recibo => <Table.Row key={recibo.id}>
                                            {/*Avatar*/}
                                            <Table.TextCell flexBasis={80} flexShrink={0} flexGrow={0} className="content">
                                                <Avatar hashValue={recibo.nome_criador_processo} name={recibo.nome_criador_processo} size={40} marginRight={10} />
                                            </Table.TextCell>

                                            <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0} className="content">
                                                {recibo.loja !== null ? recibo.loja.nome : 'Sem loja'}
                                            </Table.TextCell>

                                            <Table.TextCell flexBasis={300} flexShrink={0} flexGrow={0} className="content-endereco content-click" onClick={(e) => handleSideSheet(e, recibo, acessoTab(0))}>
                                                {recibo.logradouro + ', ' + recibo.numero + (recibo.unidade !== null ? " / " + recibo.unidade : "")}
                                            </Table.TextCell>

                                            <Table.TextCell flexBasis={150} flexShrink={0} flexGrow={0} className="content-endereco">
                                                {recibo.informacao !== null ? recibo.informacao.data_assinatura : ''}
                                            </Table.TextCell>

                                            <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} className="content-endereco">
                                                {
                                                    recibo.informacao !== null
                                                        ?
                                                        recibo.informacao.forma_pagamento === '2,3' ? 'Financiamento, FGTS' : recibo.informacao.forma_pagamento_nome

                                                        : ''}
                                            </Table.TextCell>

                                            <Table.TextCell
                                                flexBasis={180} flexShrink={0} flexGrow={0} className="content-endereco" onClick={(e) => ShowDocument(recibo.informacao.imovel_id, "recibo", token)}>
                                                <div className="doc-name">{recibo.informacao.recibo}</div>
                                            </Table.TextCell>

                                            <Table.TextCell flexBasis={180} flexShrink={0} flexGrow={0} >
                                                <div className="content-tag">
                                                    <span className="circleIcon"></span>
                                                    {listaStatusProcesso[0].find((status) => (
                                                        recibo.status_processo_nome === status.status
                                                    ))
                                                        ? recibo.status_processo_nome
                                                        : <Tooltip content={recibo.status_processo_nome}><span className='colorBlue'>Entrada</span></Tooltip>
                                                    }
                                                </div>
                                            </Table.TextCell>

                                            <Table.TextCell
                                                flexBasis={130} flexShrink={0} flexGrow={0} className="content-endereco"
                                            >
                                                {listaStatusProcesso[0].find((status) => (
                                                    recibo.status_processo_nome === status.status
                                                ))
                                                    ? recibo.nome_responsavel
                                                    : "Franqueado"
                                                }
                                            </Table.TextCell>

                                            <Table.TextCell
                                                flexBasis={300} flexShrink={0} flexGrow={0}>
                                                <Button
                                                    title="Ver detalhes"
                                                    marginY={8}
                                                    marginRight={0}
                                                    appearance="primary"
                                                    padding={0}
                                                    onClick={(e) => handleSideSheet(e, recibo, acessoTab(0))}
                                                >
                                                    <InfoSignIcon
                                                        color="white"
                                                        size={11}
                                                    />
                                                </Button>
                                            </Table.TextCell>
                                        </Table.Row>
                                        )}
                                    </div>

                                    :
                                    <Table.Row key={1}>
                                        <div className="mensagem-processo">
                                            Acompanhe por aqui todas as vendas entregues.
                                        </div>
                                    </Table.Row>
                            }
                        </Table.Body>
                    }
                </Table.Body>

                <SideSheetRecibo />
            </React.Fragment>
        </Pane>
    )
}