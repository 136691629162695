import Axios from 'axios';
import Serve from '../app/serve';

async function getGerenteConfirmarEscritura() {
  const usuario_id = localStorage.getItem('usuario_id');
  const token = localStorage.getItem('token');
  const app = Serve();
  let data;

  await Axios.get(app + 'gerente_confirmar_escritura', {
      headers: {
        Authorization: `Bearer ${token}`,
    }, params: {
      usuario_id: usuario_id,
    }
  }).then(res => {
    if (!res) {
      data = false;
    }
    if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
      data = false
    } else {
      data = res.data;    
    }
  })

  return data;
};

export default getGerenteConfirmarEscritura;