import Axios from 'axios';
import Serve from '../app/serve';
import { differenceInDays, parse, isSameMonth } from 'date-fns';

// API PARA ABASTECER TABELA DE PROCESSOS POR RESPONSAVEL EM COORDENADOR DE PÓSVENDA

async function getProcessos(month) {
  const token = localStorage.getItem('token');
  const app = Serve();
  let data;
  const filter = month || 0; // 0 SIGNIFICA SEM FILTRO
  const inputDateFormat = 'yyyy-MM-dd HH:mm:ss';
  
  
  const calcDays = (dataExpiracao) => {
    const startDate = parse(dataExpiracao, inputDateFormat, new Date());
    const dateNow = new Date();
    const result = differenceInDays(startDate, dateNow);    
    return result;
  };

  const filterDates = (value) => {
    if(Number(filter) === 0) return true;
    const date = parse(value, inputDateFormat, new Date());
    const isMonth = isSameMonth(date, new Date(2023, filter - 1));    
    console.log(date);
    return isMonth;
  };

  await Axios.get(app + 'lista_processos_responsaveis', {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }).then(res => {
    if (!res) {
      data = false;
    }
    if (res.data.status && (res.data.status === 498 || res.data.status === 401)) {
      data = false
    } else {
      const responsaveis = res.data.map((process) => process.responsavel);
      data = responsaveis.filter((value, i) => responsaveis.indexOf(value) === i).map((responsavel) => ({
        responsavel: responsavel,
        id:  res.data.find((process) => process.responsavel === responsavel).responsavel_id,
        total: res.data.filter((process) => process.responsavel === responsavel && filterDates(process.data_entrada)).length,
        a_vista: res.data.filter((process) => process.forma_pagamento.includes("À vista") && process.responsavel === responsavel && filterDates(process.data_entrada)).length,
        financiado: res.data.filter((process) => process.forma_pagamento.includes("Financiado") && process.responsavel === responsavel && filterDates(process.data_entrada)).length,
        fgts: res.data.filter((process) => process.forma_pagamento.includes("FGTS") && process.responsavel === responsavel && filterDates(process.data_entrada)).length,
        consorcio: res.data.filter((process) => process.forma_pagamento.includes("Consórcio") && process.responsavel === responsavel && filterDates(process.data_entrada)).length,
        em_dia: res.data.filter((process) => calcDays(process.data_expiracao_status) > 3 && process.responsavel === responsavel && filterDates(process.data_entrada)).length,
        alerta: res.data.filter((process) => calcDays(process.data_expiracao_status) <= 3 && calcDays(process.data_expiracao_status) >= 0 && process.responsavel === responsavel && filterDates(process.data_entrada)).length,
        atrasado: res.data.filter((process) => calcDays(process.data_expiracao_status) < 0 && process.responsavel === responsavel && filterDates(process.data_entrada)).length,
        sem_prazo: res.data.filter((process) => !process.data_expiracao_status && process.responsavel === responsavel && filterDates(process.data_entrada)),
        sem_pagamento: res.data.filter((process) => !process.forma_pagamento && process.responsavel === responsavel && filterDates(process.data_entrada)),
        total_processos: res.data.length,
      }));
    }
  })

  return data;
};

export default getProcessos;