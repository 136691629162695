import React, { useContext }  from 'react';
import { Pane, Table, SmallTickIcon, DocumentIcon, LinkIcon } from 'evergreen-ui';
import './index.css';
import PosNegociacaoContext from '../../../../context/PosNegociacaoContext';

export default function Historico() {

    const {
        dadosReciboEnviado
    } = useContext(PosNegociacaoContext);

    console.log(dadosReciboEnviado.historico);

    //console.log(historicoStatus);

    const capitalize = str => {
        if (typeof str !== 'string') {
            return '';
        }
        return str.charAt(0).toUpperCase() + str.substr(1);
    }

    return (
        <Pane>
            {dadosReciboEnviado.historico.map(historico => 
            <Table.Row className="row-historico" style={{height: 'auto'}}>
                <Table.TextCell className="historicoStatus">
                    <div className="fontStatus">
                        {historico.status !== null
                            ?
                            <>
                                <SmallTickIcon className="iconTick"  />
                                    <span className="boldStatus">{historico.status}</span> - {historico.data_historico}
                            </>

                            :
                            <>
                                <DocumentIcon className="iconDocument" />
                                <span className="boldStatus">{capitalize(historico.identifica_documento) + ' > '} {historico.nome} </span> - {historico.data_historico}
                            </>
                        }
                    </div>
                    <Table.TextCell className="mensagemStyle">
                            {historico.mensagem === null ? '' : <span className="mensagem">{historico.mensagem}</span>}
                            {historico.link === null
                                ? ''
                                : <a href={historico.link} target="_blank" rel="noreferrer" title={historico.nome}>{historico.link} <LinkIcon className="iconLink" width={17} /></a>
                            }
                    </Table.TextCell>
                </Table.TextCell>
                </Table.Row>
            )}
        </Pane>
    )
}