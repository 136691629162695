import Axios from 'axios';
import Serve from '../app/serve';

async function getEndereco() {
  const token = localStorage.getItem('token');
  const app = Serve();
  let data;

  await Axios.get(app + 'logradouro_processos', {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  }).then(res => {
    if (res?.data.status && (res.data.status === 498 || res.data.status === 401)) {
      localStorage.clear();
    } else {
      const logradouro = res.data.map(processo => processo.logradouro);
      data = [...new Set(logradouro)];
    }
  })

  return data;
};

export default getEndereco;