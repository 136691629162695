import React, { useState, useContext } from 'react';
import { Pane, Table } from 'evergreen-ui';
import DirecaoContext from '../../../../context/DirecaoContext';
import ShowDocument from '../../../../app/showDocument';

// MSG QUANDO CAMPO VAZIO
const msgCampo = '----';

function ColTable(props) {
    return (
        <Table.TextCell maxWidth={props.width}>
            {props.title && <div className={`mb7 color101840 `}>{props.title}</div>}

            <span className='fw400 color696F8C'>
                {props.subtitle || msgCampo}
            </span>
        </Table.TextCell>
    )
};

export default function ReciboDeSinal() {
    const [token] = useState(localStorage.getItem('token'));
    const {
        dadosVenda,
    } = useContext(DirecaoContext);

    return (
        <div className="detadelhes-sidesheet">
            <Pane>
                <React.Fragment>
                    <h2 className="subtitle subtitle-table">Recibo de Sinal</h2>
                </React.Fragment>

                <Table.Row>
                    <ColTable title={"Data de Assinatura"} subtitle={dadosVenda.informacao?.data_assinatura} />
                </Table.Row>

                <Table.Row>
                    <ColTable
                        title={"Arquivo do Recibo de Sinal"}
                        subtitle={
                            <span className="link-ref" onClick={(e) => ShowDocument(dadosVenda.id, "recibo", token)} style={{ cursor: 'pointer', color: '#3366FF' }}>{dadosVenda.informacao?.recibo}</span>
                        }
                    />
                </Table.Row>
            </Pane>
        </div>
    )
}