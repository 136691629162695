import React, { useState } from 'react';
import Iframe from 'react-iframe'
import { Document, Page } from 'react-pdf';

import Axios from 'axios';
import Serve from '../../app/serve';
import { useHistory } from 'react-router-dom';

export default function ReciboDeSinal() {
    const app = Serve();
    const history = useHistory();

    const [token] = useState(localStorage.getItem('token'));
    const usuario_id = localStorage.getItem('usuario_id');
    const nome_usuario = localStorage.getItem('nome_usuario');
    const usuario_email = localStorage.getItem('usuario_email');

    const [file, setFile] = useState([]);


    Axios.post(app + 'exibir_arquivo', {
        "id": 50
    }, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
    })
        .then(res => {
            console.log(res);
            let type_arquivo = res.data.type;
            /* if (res.data.status && res.data.status === (401 || 498)) {
                localStorage.clear();
                history.push('/');
            } else { */
            /* let file = res.data;
            console.log("file", file);
            setFile(file); */
            /* 
             */
            /* let imovel = res.data.imovel;
            console.log(imovel);  */
            //const url = window.URL.createObjectURL(new Blob([file]));

            const file = new Blob(
                [res.data],
                { type: type_arquivo });

            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);

            /* const link = document.createElement('a');
            //link.href = url;
            link.setAttribute('href', 'data:"application/pdf;' + file);
            link.setAttribute('download', 'file.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();    
            document.body.removeChild(link); */

            //}
        })

    return (
        <div>
            <h1>Recibo de Sinal</h1>
            {/* <img src={file}/> */}
            {/* <Document file={'data:application/pdf;base64,'+ file }>
                <Page pageNumber={1} />
                </Document> */}

            {/* <Document
      file={{data: file}}
      options={{ workerSrc: "/pdf.worker.js" }}
    >
    </Document> */}
            {/* <ReactPDF
                file={{
                    data: file
                }}
            /> */}
            {/* {<iframe src={file} /> */}


            {/* <Iframe url={file}
                width="450px"
                height="450px"
                id="myId"
                className="myClassname"
                display="initial"
                position="relative"/> */}
        </div>

    )
}