import React, {useContext, useState} from 'react'
import { CornerDialog } from 'evergreen-ui'
import VendasContext from '../../../../context/VendasContext'

export default function CornerDialogFeedbackEscritura() {

    const {
        isShowFeedBackEscritura, setIsShowFeedbackEscritura
    } = useContext(VendasContext);

    return (
        <CornerDialog
            title="Já recebemos o seu feedback!"
            isShown={isShowFeedBackEscritura}
            onCloseComplete={() => setIsShowFeedbackEscritura(false)}
            hasCancel={false}
            confirmLabel="Ok"
        >
            Obrigada por nos dar seu feedback das Escrituras. Agora, você poderá acessar seu painel de processos normalmente.
        </CornerDialog>
    )
}
