import React, {useContext} from 'react'
import {Pane, CornerDialog, Button} from 'evergreen-ui'
import ApoioContext from '../../../../context/ApoioContext';

export default function ConfirmaEnvioPlanilha() {

    const {
        isShownConfirmaPlanilha, setIsShownConfirmaPlanilha
    } = useContext(ApoioContext);

    return (
        <Pane>
            <CornerDialog
                title="Planilha de Comissão enviada!"
                isShown={isShownConfirmaPlanilha}
                onCloseComplete={() => setIsShownConfirmaPlanilha(false)}
                cancelLabel={false}
                confirmLabel={'Ok'}
                hasFooter={false}
            >
                Parabéns, a planilha de comissão já foi enviada para a Equipe de Apoio à venda. :)

                <div style={{marginTop: '15px', display: 'flex', justifyContent: 'flex-end'}}>
                    <Button marginRight={16} appearance="primary" intent="none" onClick={() => setIsShownConfirmaPlanilha(false)}>Ok</Button>
                </div>
            </CornerDialog>
        </Pane>
    )
}
