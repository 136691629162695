import React, { useState, useEffect } from 'react';
import { Pane, Dialog, Badge, Checkbox } from 'evergreen-ui';
import { useHistory } from 'react-router-dom';
// import Image from '../../../../../../images/Group_153.png';
import Image from '../../../images/Group_153.png';
import { changelog } from './changelog.json'

import HelloImage from '../../../images/hello.svg';

export default function ChangeLog() {
  const history = useHistory();
  const perfil = localStorage.getItem("perfil_login");

  const revisao = localStorage.getItem("Revisão");
  const [acceptChangeLog, setAcceptChangeLog] = useState(true);
  const [showChangeLog, setShowChangeLog] = useState(
    localStorage.getItem("Revisão")
      ? false
      : localStorage.getItem('change_log') === 'true' ? false : true
  );

  const handleAccept = (e) => {
    setAcceptChangeLog(e.target.checked);
  };

  const handleClosseChangeLog = (e) => {
    setShowChangeLog(false);
    acceptChangeLog === true ? localStorage.setItem('change_log', 'true') : localStorage.setItem('change_log', 'false');
  };

  const posicaoPerfil = (e) => {
    switch (e) {
      case 'Gerente':
        return 0
      case 'Backoffice':
        return 1
      case 'Gerente Geral':
        return 2
      case 'Diretor Comercial':
        return 3
      case 'Núcleo':
        return 4
      case 'Apoio':
        return 5
      case 'Financeiro':
        return 6
      case 'Coordenadora de Pós-Negociação':
        return 7
      default:
        return 8
    }
  };

  const versao = changelog.versao[posicaoPerfil(perfil)];

  useEffect(() => {
    if (perfil === null) {
      history.push('/');
    }
    function dialogChangeLog() {

      const acceptStorage = localStorage.getItem('change_log');
      const versaoStorage = localStorage.getItem('versao');

      acceptStorage === 'true' || acceptStorage === null ? setAcceptChangeLog(true) : setAcceptChangeLog(false);

      if (revisao) {
        setShowChangeLog(false);
        return;
      }

      if (versaoStorage === null) {
        console.log('Inserindo versão pela primeira vez');
        localStorage.setItem('versao', versao);

        if (acceptStorage === null) {
          localStorage.setItem('change_log', '');
        }

        setShowChangeLog(true);

        console.log('Estado versão: ' + versao);
        console.log('Versão Storage: ' + versaoStorage);
      }

      else if (versao == versaoStorage) {
        console.log('Versão atualizada');

        if (acceptStorage === 'true') {
          setShowChangeLog(false);
        }
        else {
          setShowChangeLog(true);
        }

        console.log('Estado versão: ' + versao);
        console.log('Versão Storage: ' + versaoStorage);
      }

      else if (versao !== versaoStorage) {
        console.log('Atualizando a versão');
        localStorage.setItem('versao', versao);

        if (acceptStorage === 'true') {
          setShowChangeLog(true);
        }
        else {
          setShowChangeLog(true);
        }

        console.log('Estado versão: ' + versao);
        console.log('Versão Storage: ' + versaoStorage);
      }

      else {
        console.log('error versão');
        localStorage.setItem('versao', versao);
      }

    }
    dialogChangeLog();

  }, []);

  return (
    <Pane>
      <Dialog
        isShown={showChangeLog}
        title=""
        onCloseComplete={(e) => handleClosseChangeLog(e)}
        hasFooter={false}
        shouldCloseOnOverlayClick={false}
        width={808}
        containerProps={{
          className: 'form-dialog-props change-log-props'
        }}
      >{perfil &&
        <div className="form-dialog change-log">
          <div className="">
            {/* -------------------------------------------------- CHANGELOG VERSÃO ATUAL -------------------------------------------------- */}
            <h4 className="title">{versao} - O que há de novo</h4>
            <p className="data">{changelog.data[posicaoPerfil(perfil)]}</p>

            <img src={Image} alt="Group_153" />

            <div className="row" style={changelog[perfil][0].Novo.length === 0 ? { display: 'none' } : {}}>
              <Badge className="badge" color="green">Novo</Badge>
              <ul>
                {changelog[perfil][0].Novo.map((novo, index) => (
                  <li key={index}>{novo}</li>
                ))}
              </ul>
            </div>

            <div className="row" style={changelog[perfil][0].Melhorias.length === 0 ? { display: 'none' } : {}}>
              <Badge className="badge" color="yellow">Melhorias</Badge>
              <ul>
                {changelog[perfil][0].Melhorias.map((melhorias, index) => (
                  <li key={index}>{melhorias}</li>
                ))}
              </ul>
            </div>

            <div className="row" style={changelog[perfil][0].Bugs.length === 0 ? { display: 'none' } : {}} >
              <Badge className="badge" color="red">Correções</Badge>
              <ul>
                {changelog[perfil][0].Bugs.map((bugs, index) => (
                  <li key={index}>{bugs}</li>
                ))}
              </ul>
            </div>

            {/* -------------------------------------------------- CHANGELOG VERSÃO ANTERIOR -------------------------------------------------- */}
            <div style={(changelog[perfil][1].Novo.length === 0 && changelog[perfil][1].Melhorias.length === 0 && changelog[perfil][1].Bugs.length === 0) ? { display: 'none' } : {}}>
              <h4 className="title" style={{ fontSize: '20px' }}>{changelog.oldVersion[posicaoPerfil(perfil)]}</h4>

              <p className="data" style={{ marginBottom: '15px' }}>{changelog.oldData[posicaoPerfil(perfil)]}</p>

              <div className="row" style={changelog[perfil][1].Novo.length === 0 ? { display: 'none' } : {}}>
                <Badge className="badge" color="green">Novo</Badge>
                <ul>
                  {changelog[perfil][1].Novo.map((novo, index) => (
                    <li key={index}>{novo}</li>
                  ))}
                </ul>
              </div>

              <div className="row" style={changelog[perfil][1].Melhorias.length === 0 ? { display: 'none' } : {}}>
                <Badge className="badge" color="yellow">Melhorias</Badge>
                <ul>
                  {changelog[perfil][1].Melhorias.map((melhorias, index) => (
                    <li key={index}>{melhorias}</li>
                  ))}
                </ul>
              </div>

              <div className="row" style={changelog[perfil][1].Bugs.length === 0 ? { display: 'none' } : {}} >
                <Badge className="badge" color="red">Correções</Badge>
                <ul>
                  {changelog[perfil][1].Bugs.map((bugs, index) => (
                    <li key={index}>{bugs}</li>
                  ))}
                </ul>
              </div>
            </div>

          </div>
          <div className="line-checkbox">
            <Checkbox
              label="Não exibir novamente, até a próxima atualização."
              checked={acceptChangeLog}
              onChange={e => handleAccept(e)}
              className="checkbox"
            />
          </div>
        </div>
        }
      </Dialog>
    </Pane>
  )
}