import React, {useContext} from 'react';
import { CornerDialog } from 'evergreen-ui';

import PosNegociacaoContext from '../../../../context/PosNegociacaoContext';

export default function FeedBack() {

    const {
        isShowFeedBack, setIsShowFeedBack,
    } = useContext(PosNegociacaoContext);

    return(
        <CornerDialog
            title="Recibo enviado!"
            isShown={isShowFeedBack}
            onCloseComplete={() => setIsShowFeedBack(false)}
            hasFooter={false}
        >
            O recibo foi enviado para o backoffice : )<br/>
            Ele não poderá mais ser editado.
        </CornerDialog>
    )
}